import React, { useContext, useEffect, useState } from 'react'

import { Flex, SimpleGrid } from '@chakra-ui/react'
import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { FieldLine } from '../../FieldLine'
import { FieldsList } from '../../List/FieldsList'

export function Installment({ _id, discount, gracePeriod, dir, fine, _pmtId }) {
    const { receiver } = useContext(SocketContext)
    const [dues, setDues] = useState([])

    const [payment, setPayment] = useState({
        total: 0,
    })

    const [customer, setCustomer] = useState({
        firstName: '',
        lastName: '',
    })

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
    })

    useEffect(() => {
        receiver.on('crud:customer:find:ok', (response) => {
            setCustomer(response)
        })

        receiver.on('crud:user:find:ok', (response) => {
            setUser(response)
        })

        receiver.on('crud:sale:find:ok', (response) => {
            receiver.emit('crud:customer:find', { _id: response._custId })
            receiver.emit('crud:user:find', { _id: response._usrId })
        })

        receiver.on('crud:payment:find:ok', (response) => {
            receiver.emit('crud:sale:find', { _id: response._slId })
            setPayment(response)
        })

        receiver.on('crud:installment_due:find:ok', (response) => {
            setDues(response)
        })

        receiver.emit('crud:installment_due:find', { _instlId: _id })
        receiver.emit('crud:payment:find', { _id: _pmtId })

        return () => {
            receiver.off('crud:installment_due:find:ok')
            receiver.off('crud:payment:find:ok')
            receiver.off('crud:sale:find:ok')
            receiver.off('crud:customer:find:ok')
            receiver.off('crud:user:find:ok')
        }
    }, [])

    return (
        <>
            <FieldLine
                title="Cliente"
                value={`${customer.firstName} ${customer.lastName}`}
            />
            <FieldLine
                title="Vendedor"
                value={`${user.firstName} ${user.lastName}`}
            />
            <FieldLine title="Total" value={payment.total} />
            <FieldLine title="Desconto" value={`${discount}`} />
            <FieldLine title="Periodo de Carência" value={`${gracePeriod}`} />
            <FieldLine title="DIR" value={`${dir}`} />
            <FieldLine title="Fine" value={`${fine}`} />

            <SimpleGrid w="100%" minChildWidth="280px" spacing={4} p={2}>
                {dues.map((due) => (
                    <Flex
                        key={due._id}
                        position="relative"
                        w="100%"
                        minH="150px"
                        boxShadow="base"
                        borderRadius="2xl"
                        p={4}
                    >
                        <FieldsList data={due} crudName="installment_due" />
                    </Flex>
                ))}
            </SimpleGrid>
        </>
    )
}
