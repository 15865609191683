import React from 'react'

import { Flex } from '@chakra-ui/react'
import { Breadcrumb } from '../../components/Breadcrumb'
import { PaymentBox } from '../../components/FinishSale/PaymentBox'
import { SaleDetails } from '../../components/FinishSale/SaleDetails'
import { CertificateModal } from '../../components/FinishSale/CertificateModal'

export function FinishSale({ location }) {
    const { customer } = location.state

    return (
        <Flex w="100%" p={4} gap={4} h="max-content" direction="column">
            <Breadcrumb page="finish-sale" />
            <SaleDetails customer={customer} />
            <PaymentBox customer={customer} />
            <CertificateModal />
        </Flex>
    )
}
