import React from 'react'

import { FieldLine } from '../../FieldLine'

export function Cancellation({ timestamp }) {
    return (
        <FieldLine
            title="Data"
            value={`${new Intl.DateTimeFormat('pt-BR').format(timestamp)}`}
        />
    )
}
