import React from 'react'

import { Flex, Text } from '@chakra-ui/react'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { TopTenCard } from '../TopTenCard'
import noImage from '../../../assets/no-image.svg'

import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'

import './styles.css'

SwiperCore.use(Navigation)

const cards = [
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 1',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 2',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 3',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 4',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 5',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 6',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1111111111111',
        code: 'Cód. 7',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 8',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 9',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
    {
        img: noImage,
        title: 'Produto 1',
        code: 'Cód. 10',
        refer: 'Ref. ABCDEF',
        amount: 123.0,
        type: 'à vista',
    },
]

export function TopTenGoods() {
    return (
        <Flex
            direction="column"
            backgroundColor="white"
            borderRadius="2xl"
            gap={2}
            p={4}
            w="100%"
        >
            <Text fontSize="2xl">Produtos mais vendidos do mês</Text>

            <Flex w="100%" align="center">
                <Swiper
                    slidesPerView="auto"
                    spaceBetween={16}
                    modules={[Navigation]}
                    navigation
                >
                    {cards.map((card) => (
                        <SwiperSlide key={card.code}>
                            <TopTenCard
                                img={card.img}
                                title={card.title}
                                code={card.code}
                                refer={card.refer}
                                amount={card.amount}
                                type={card.type}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Flex>
        </Flex>
    )
}
