import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
    styles: {
        global: {
            '#root': {
                '--background-white': '#E9E9E9',
                '--background': '#ff9a3f',

                '--white': '#ffffff',

                '--orange': '#f97e3e',
                '--orange-light': '#ff9a3f',
            },
            body: {
                bg: '#E9E9E9',
                display: 'flex',
                justifyContent: 'center',
                WebkitFontSmoothing: 'antialiased',
            },
            '*': {
                margin: 0,
                marginInlineEnd: 0,
                webkitMarginEnd: 0,
                marginInlineStart: 0,
                webkitMarginStart: 0,

                boxSizing: 'border-box',

                msOverflowStyle: 'none',
            },
            button: {
                cursor: 'pointer',
                boxShadow: 'none',
            },
            'body, input, textarea, button': {
                fontFamily: 'Poppins, sans-serif',
                fontWeight: '400',
            },
            'h1, h2, h3, h4, h5, h6, strong': {
                fontFamily: 'Poppins, sans-serif !important',
                fontWeight: '600',
            },
            '.version': {
                position: 'absolute',
                bottom: '5px',
                left: '5px',
            },
        },
    },
})
