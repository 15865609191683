import React, { useContext, useState } from 'react'

import { Flex } from '@chakra-ui/react'
import { FilterResults } from '../../../components/Search/FilterResults'
import { SearchBar } from '../../../components/Search/SearchBar'
import { SocketContext } from '../../../contexts/socket/SocketContext'

export function Content() {
    const [search, searchSet] = useState('')
    const [filteredBy, filteredBySet] = useState('')
    const { receiver } = useContext(SocketContext)

    const onFilterByCode = () => {
        const parsedFilters = {
            reference: search || '',
        }

        receiver.emit('good:find:by:reference', parsedFilters)
    }

    return (
        <Flex
            flex="1"
            direction="column"
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            gap={4}
            h="max-content"
        >
            <SearchBar
                label="Código do produto"
                placeholder="Código"
                value={search}
                valueSet={searchSet}
                filteredBySet={filteredBySet}
                onFilter={onFilterByCode}
                qrCode
            />

            <Flex direction={{ base: 'column', xl: 'row' }} gap={4} mt={4}>
                <FilterResults filteredBy={filteredBy} />
            </Flex>
        </Flex>
    )
}
