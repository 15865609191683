import React, { useContext } from 'react'
import { Button, Center, Divider, Flex } from '@chakra-ui/react'
import { FilterContext } from '../../../../contexts/filter/FilterContext'
import { Categories } from '../Categories'
import { SocketContext } from '../../../../contexts/socket/SocketContext'

export function Filters({ onClose }) {
    const { receiver } = useContext(SocketContext)
    const { filters } = useContext(FilterContext)

    const onFilterByAttribute = () => {
        const selectedAttributes = {
            attributes: Object.entries(filters).map(([id, filter]) => id),
        }

        receiver.emit('good:find:by:attribute', selectedAttributes)
    }

    return (
        <Flex
            backgroundColor="whiteAlpha.900"
            minW="186px"
            borderRadius="2xl"
            direction="column"
            height="max-content"
            p={[0, 0, 4]}
        >
            <Categories />
            <Button
                mt={4}
                colorScheme="orange"
                onClick={() => {
                    if (onClose) {
                        onClose()
                    }
                    onFilterByAttribute()
                }}
            >
                Filtrar
            </Button>
        </Flex>
    )
}
