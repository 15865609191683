import React, { useEffect, useContext, useState } from 'react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'

import { FieldLine } from '../../FieldLine'

export function GoodVariation({ _gdId, reference }) {
    const { receiver } = useContext(SocketContext)

    const [good, goodSet] = useState({
        name: '',
        description: '',
    })

    useEffect(() => {
        receiver.on('crud:good:find:ok', (response) => {
            if (_gdId === response._id) {
                goodSet(response)
            }
        })

        receiver.emit('crud:good:find', { _id: _gdId })

        return () => {
            receiver.off('crud:good:find:ok')
        }
    }, [])

    return (
        <>
            <FieldLine title="Referência" value={`${reference}`} />
            <FieldLine title="Nome do produto" value={`${good.name}`} />
            <FieldLine title="Descrição" value={`${good.description}`} />
        </>
    )
}
