import React from 'react'
import { useDisclosure, VStack } from '@chakra-ui/react'
import { Breadcrumb } from '../../components/Breadcrumb'
import { ClientBox } from '../../components/CustomerSelect/ClientBox'
import { ClientModal } from '../../components/CustomerSelect/ClientModal'

export function CustomerSelect() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <VStack flex="1" p={4} spacing={4}>
            <Breadcrumb page="customer-select" />
            <ClientBox onOpen={onOpen} />
            <ClientModal isOpen={isOpen} onClose={onClose} />
        </VStack>
    )
}
