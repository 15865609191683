import React, { useContext, useEffect, useState } from 'react'
import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Select } from '../../../Form/Select'

export function CustomBehaviorGood({ data, setCustomOnSubmit }) {
    const { receiver } = useContext(SocketContext)

    const [selectedAttrKeys, setSelectedAttrKeys] = useState([])
    const [attributeKeys, setAttributeKeys] = useState([])

    /* effect to update lists of attributes and good attributes */
    useEffect(() => {
        /* load attribute keys */
        receiver.on('crud:attribute_key:find:ok', (response) => {
            setAttributeKeys(response.result)
            setSelectedAttrKeys(
                data._akIds.flatMap((akId) => {
                    const result = response.result.find((ak) => ak._id === akId)

                    if (result) {
                        return {
                            label: result.name,
                            value: akId,
                        }
                    }

                    return []
                })
            )
        })

        /* attribute finder by good id */
        receiver.emit('crud:attribute_key:find', {
            page: 0,
            size: Number.MAX_VALUE,
        })

        return () => {
            receiver.off('crud:attribute_key:find:ok')
        }
    }, [])

    const updateGoodToInsertKeys = (formData) => {
        receiver.emit('crud:good:update', {
            _id: data._id,
            ...formData,
            _akIds: selectedAttrKeys.map((attrKey) => attrKey.value),
        })
    }

    const customSubmit = (formData) => {
        updateGoodToInsertKeys(formData)

        return true
    }

    /* effect to update customSubmit */
    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })
    }, [selectedAttrKeys])

    const handleChange = (selectedOption) => {
        setSelectedAttrKeys(selectedOption)
    }

    return (
        <Select
            multiple
            label="Atributos"
            options={attributeKeys}
            onChange={handleChange}
            value={selectedAttrKeys}
        />
    )
}
