import React, { useContext, useEffect, useState } from 'react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'

import { FieldLine } from '../../FieldLine'

export function Sale({ timestamp, _custId, _brId, _usrId }) {
    const { receiver } = useContext(SocketContext)

    const [branch, setBranch] = useState({
        name: '',
    })

    const [customer, setCustomer] = useState({
        firstName: '',
        lastName: '',
    })

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
    })

    useEffect(() => {
        receiver.on('crud:branch:find:ok', (response) => {
            if (_brId === response._id) {
                setBranch(response)
            }
        })
        receiver.on('crud:customer:find:ok', (response) => {
            if (_custId === response._id) {
                setCustomer(response)
            }
        })
        receiver.on('crud:user:find:ok', (response) => {
            if (_usrId === response._id) {
                setUser(response)
            }
        })

        receiver.emit('crud:branch:find', { _id: _brId })
        receiver.emit('crud:customer:find', { _id: _custId })
        receiver.emit('crud:user:find', { _id: _usrId })

        return () => {
            receiver.off('crud:branch:find:ok')
            receiver.off('crud:customer:find:ok')
            receiver.off('crud:user:find:ok')
        }
    }, [])

    return (
        <>
            <FieldLine title="Filial" value={branch.name} />
            <FieldLine
                title="Cliente"
                value={`${customer.firstName} ${customer.lastName}`}
            />
            <FieldLine
                title="Vendedor"
                value={`${user.firstName} ${user.lastName}`}
            />
            <FieldLine
                title="Data"
                value={`${new Intl.DateTimeFormat('pt-BR').format(timestamp)}`}
            />
        </>
    )
}
