import React from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { PaymentMethods } from './PaymentMethods'

export function PaymentBox({ customer }) {
    return (
        <Flex
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            backgroundColor="whiteAlpha.900"
            p={4}
            borderRadius="2xl"
            direction="column"
            gap={4}
            position="relative"
        >
            <Text fontSize="2xl" w="100%" color="orange.500">
                Forma de pagamento
            </Text>
            <Divider />
            <PaymentMethods customer={customer} />
        </Flex>
    )
}
