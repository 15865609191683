import React, { useContext, useEffect, useState } from 'react'

import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import {
    Center,
    Flex,
    IconButton,
    InputRightElement,
    SimpleGrid,
    Spinner,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'

import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Input } from '../../../Form/Input'

export function CustomBehaviorAttribute({
    setCustomOnSubmit,
    setCustomValidateFields,
}) {
    const { receiver, user } = useContext(SocketContext)

    const toast = useToast()

    const [loading, setLoading] = useState(false)
    const [loadingInterval, SetLoadingInterval] = useState(null)

    /* array of items in db */
    const [attributeValuesToInsert, setAttributeValuesToInsert] = useState([])

    /* object with errors _id: { message: errorMessage } */
    const [avErrors, setAvErrors] = useState({})

    const insertAttributes = async (_akId) => {
        /* insert new attributes */
        attributeValuesToInsert.forEach((attr) => {
            if (attr.name) {
                receiver.emit('crud:attribute_value:insert', {
                    name: attr.name,
                    _coId: attr._coId,
                    _akId,
                })
            }
        })
    }

    const customSubmit = (newAttribute) => {
        setLoading(true)

        if (attributeValuesToInsert.length > 0) {
            insertAttributes(newAttribute._id)
        }

        SetLoadingInterval((oldInterval) => {
            clearInterval(oldInterval)
            return setInterval(() => {
                setLoading(false)
            }, 500)
        })

        return false
    }

    const customValidateFields = async () => {
        /* schema to validate name */
        const schema = yup
            .string()
            .min(1)
            .typeError('Campo precisa ser um texto')
            .required('Campo obrigatório')

        /* object of errors */
        const newErrors = { ...avErrors }

        const isValid = await attributeValuesToInsert.reduce(
            async (acc, attr) => {
                let newAcc = await acc

                try {
                    /* if not valid, set a new error for this field */
                    await schema.validate(attr.name)
                    /* remove the error message */
                    delete newErrors[attr._id]
                } catch (e) {
                    newAcc = false
                    /* show the first error message */
                    newErrors[attr._id] = { message: e?.errors[0] }
                }

                return newAcc
            },
            Promise.resolve(true)
        )

        setAvErrors(newErrors)

        if (attributeValuesToInsert.length === 0) {
            toast({
                title: 'Valores',
                description:
                    'É necessário ter no mínimo um valor para o atributo',
                position: 'top',
                status: 'warning',
                isClosable: true,
            })

            return false
        }

        return isValid
    }

    /* effect to update customSubmit */
    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })
        setCustomValidateFields(() => {
            return customValidateFields
        })
    }, [attributeValuesToInsert, avErrors])

    /* first effect, to create socket listeners */
    useEffect(() => {
        receiver.on('crud:attribute_value:insert:ok', (response) => {
            /* feedback to user */
            SetLoadingInterval((oldInterval) => {
                clearInterval(oldInterval)
                return setInterval(() => {
                    setLoading(false)
                }, 200)
            })
        })

        /* remove the listeners */
        return () => {
            receiver.off('crud:attribute_value:insert:ok')
        }
    }, [])

    const addAttributeValue = () => {
        const newAttributeValue = {
            _id: uuidv4(), // the id is discarted before insert, but is a must to list render
            name: '',
            _coId: user._coId,
        }

        setAttributeValuesToInsert([
            ...attributeValuesToInsert,
            newAttributeValue,
        ])
    }

    const handleRemoveNewAttribute = (_id) => {
        setAttributeValuesToInsert([
            ...attributeValuesToInsert.filter((attr) => attr._id !== _id),
        ])
    }

    return loading ? (
        <VStack w="100%" align="flex-start">
            <Text>Valores</Text>
            <Center w="100%">
                <Spinner />
            </Center>
        </VStack>
    ) : (
        <VStack w="100%" spacing={4}>
            <Flex justify="space-between" w="100%">
                <Text>Valores</Text>
                <IconButton
                    icon={<AddIcon />}
                    colorScheme="orange"
                    onClick={addAttributeValue}
                    type="button"
                    size="lg"
                />
            </Flex>
            <SimpleGrid columns={[1, 1, 2]} w="100%" spacingX={4} spacingY={2}>
                {attributeValuesToInsert.map(({ name, _id }) => (
                    <Flex flexDirection="row" key={_id}>
                        <Input
                            name={_id}
                            error={avErrors[_id]}
                            label="Valor"
                            type="text"
                            value={name}
                            onChange={(e) => {
                                setAttributeValuesToInsert([
                                    ...attributeValuesToInsert.map((attr) => {
                                        const newAttr = { ...attr }
                                        if (attr._id === _id) {
                                            newAttr.name = e.target.value
                                        }
                                        return newAttr
                                    }),
                                ])
                            }}
                            addon={
                                <InputRightElement h="100%">
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        colorScheme="blackAlpha"
                                        right="4px"
                                        onClick={() =>
                                            handleRemoveNewAttribute(_id)
                                        }
                                    />
                                </InputRightElement>
                            }
                        />
                    </Flex>
                ))}
            </SimpleGrid>
        </VStack>
    )
}
