import * as yup from 'yup'

export const userSchema = yup.object().shape({
    address: yup.object().shape({
        street: yup.string().typeError('Campo precisa ser um texto').nullable(),
        district: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .nullable(),
        additionalInformation: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .nullable(),
        number: yup.string().typeError('Campo precisa ser um texto').nullable(),
        zipCode: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .nullable(),
        city: yup.string().nullable(),
    }),
    contact: yup.object().shape({
        email: yup
            .string()
            .email()
            .typeError('Campo precisa ser um texto')
            .nullable(),
        phoneNumber: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .required('Campo obrigatório'),
    }),
    firstName: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    lastName: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    username: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    password: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    dob: yup.date().typeError('Campo precisa ser uma data válida').nullable(),
    icNumber: yup.number().typeError('Campo precisa ser numérico').nullable(),
    gender: yup.string().typeError('Campo precisa ser um texto').nullable(),
    role: yup.string().typeError('Campo precisa ser um texto').nullable(),
    enabled: yup.boolean(),
})

const customerSchema = yup.object().shape({
    address: yup.object().shape({
        street: yup.string().typeError('Campo precisa ser um texto').nullable(),
        district: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .nullable(),
        additionalInformation: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .nullable(),
        number: yup.string().typeError('Campo precisa ser um texto').nullable(),
        zipCode: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .nullable(),
        city: yup.object().shape({
            name: yup
                .string()
                .typeError('Campo precisa ser um texto')
                .nullable(),
            code: yup
                .string()
                .typeError('Campo precisa ser um texto')
                .nullable(),
        }),
        state: yup.object().shape({
            name: yup
                .string()
                .typeError('Campo precisa ser um texto')
                .nullable(),
            code: yup
                .string()
                .typeError('Campo precisa ser um texto')
                .nullable(),
        }),
        country: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .nullable(),
    }),
    contact: yup.object().shape({
        email: yup
            .string()
            .email()
            .typeError('Campo precisa ser um texto')
            .nullable(),
        phoneNumber: yup
            .string()
            .typeError('Campo precisa ser um texto')
            .required('Campo obrigatório'),
    }),
    firstName: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    lastName: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    dob: yup.date().typeError('Campo precisa ser uma data válida').nullable(),
    icNumber: yup.number().typeError('Campo precisa ser numérico').nullable(),
    gender: yup.string().typeError('Campo precisa ser um texto').nullable(),
})

export const brandSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const branchSchema = yup.object().shape({
    address: yup
        .object()
        .shape({
            street: yup
                .string()
                .typeError('Campo precisa ser um texto')
                .required('Campo obrigatório'),
            district: yup
                .string()
                .typeError('Campo precisa ser um texto')
                .required('Campo obrigatório'),
            additionalInformation: yup
                .string()
                .typeError('Campo precisa ser um texto')
                .required('Campo obrigatório'),
            number: yup
                .number()
                .typeError('Campo precisa ser numérico')
                .required('Campo obrigatório'),
            zipCode: yup
                .string()
                .typeError('Campo precisa ser um texto')
                .required('Campo obrigatório'),
        })
        .required(),
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    ein: yup
        .number()
        .typeError('Campo precisa ser numérico')
        .required('Campo obrigatório'),
    enabled: yup.boolean(),
})

export const categorySchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const attributeSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const goodSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    description: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const paymentSchema = yup.object().shape({
    cash: yup
        .number()
        .typeError('Campo precisa ser um número')
        .required('Campo Obrigatório'),
    discount: yup
        .number()
        .typeError('Campo precisa ser um número')
        .required('Campo Obrigatório'),
    pt: yup
        .number()
        .typeError('Campo precisa ser um número')
        .required('Campo Obrigatório'),
    tax: yup
        .number()
        .typeError('Campo precisa ser um número')
        .required('Campo Obrigatório'),
    total: yup
        .number()
        .typeError('Campo precisa ser um número')
        .required('Campo Obrigatório'),
    _slId: yup.string().required('Campo obrigatorio'),
})

export const installmentSchema = yup.object().shape({
    discount: yup.number().typeError('Campo precisa ser numérico').required(),
    gracePeriod: yup
        .number()
        .typeError('Campo precisa ser numérico')
        .required(),
    dir: yup.number().typeError('Campo precisa ser numérico').required(),
    fine: yup.number().typeError('Campo precisa ser numérico').required(),
})

export const saleSchema = yup.object().shape({
    timestamp: yup
        .date()
        .typeError('Campo precisa ser uma data válida')
        .required('Campo obrigatório'),
    _brId: yup.string().typeError('Campo precisa ser um texto').required(),
    _custId: yup.string().typeError('Campo precisa ser um texto').required(),
    _usrId: yup.string().typeError('Campo precisa ser um texto').required(),
})

export const expenseSchema = yup.object().shape({
    date: yup
        .date()
        .typeError('Campo precisa ser uma data válida')
        .required('Campo obrigatório'),
    value: yup
        .number()
        .typeError('Campo precisa ser um número')
        .required('Campo obrigatório'),
})

export const expenseTypeSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const exchangeReasonSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const exchangeSchema = yup.object().shape({
    value: yup
        .number()
        .typeError('Campo precisa ser numérico')
        .required('Campo obrigatório'),
})

export const refundReasonSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const refundSchema = yup.object().shape({
    value: yup
        .number()
        .typeError('Campo precisa ser um número')
        .required('Campo obrigatório'),
})

export const cancellationSchema = yup.object().shape({
    date: yup
        .date()
        .typeError('Campo precisa ser uma data válida')
        .required('Campo obrigatório'),
})

export const cancellationReasonSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const supplierSchema = yup.object().shape({
    name: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
})

export const installmentDueSchema = yup.object().shape({
    date: yup
        .date()
        .typeError('Campo precisa ser uma data válida')
        .required('Campo obrigatório'),
})

export const goodVariationSchema = yup.object().shape({
    reference: yup
        .string()
        .typeError('Campo precisa ser um texto')
        .required('Campo obrigatório'),
    price: yup
        .object()
        .shape({
            cost: yup
                .number()
                .typeError('Campo precisa ser numérico')
                .required('Campo obrigatório'),
            retail: yup
                .number()
                .typeError('Campo precisa ser numérico')
                .required('Campo obrigatório'),
        })
        .required(),
    saleTest: yup.boolean(),
    nfe: yup.object().shape({
        code: yup.string().typeError('Campo precisa ser um texto'),
        cfop: yup.number().typeError('Campo precisa ser numérico'),
        ncm: yup.string().typeError('Campo precisa ser um texto'),
        codeGTIN: yup.string().typeError('Campo precisa ser um texto'),
        cest: yup.string().typeError('Campo precisa ser um texto'),
        description: yup.string().typeError('Campo precisa ser um texto'),
        tax: yup.object().shape({
            totalTax: yup.number().typeError('Campo precisa ser numérico'),
            icms: yup.object().shape({
                cst: yup.string().typeError('Campo precisa ser um texto'),
                csosn: yup.string().typeError('Campo precisa ser um texto'),
                origin: yup.string().typeError('Campo precisa ser um texto'),
            }),
            pis: yup.object().shape({
                cst: yup.string().typeError('Campo precisa ser um texto'),
            }),
            cofins: yup.object().shape({
                cst: yup.string().typeError('Campo precisa ser um texto'),
            }),
        }),
    }),
})

export const formSchemas = {
    user: userSchema,
    customer: customerSchema,
    branch: branchSchema,
    brand: brandSchema,
    category: categorySchema,
    attribute_key: attributeSchema,
    good: goodSchema,
    good_variation: goodVariationSchema,
    payment: paymentSchema,
    installment: installmentSchema,
    installment_due: installmentDueSchema,
    sale: saleSchema,
    expense_type: expenseSchema,
    expense: expenseTypeSchema,
    exchange_reason: exchangeReasonSchema,
    exchange: exchangeSchema,
    refund_reason: refundReasonSchema,
    refund: refundSchema,
    cancellation_reason: cancellationReasonSchema,
    cancellation: cancellationSchema,
    supplier: supplierSchema,
}
