export const roles = {
    0: 'Admin',
    1: 'Dono',
    2: 'Gerente',
    3: 'Vendedor',
}

export const genders = {
    0: 'Masculino',
    1: 'Feminino',
    2: 'Não especificado',
}

export const paymentTypes = {
    0: 'Transferência bancária',
    1: 'Dinheiro',
    2: 'Cartão de débito',
    3: 'Cartão de crédito',
    4: 'Crediário',
    5: 'Troca',
}
