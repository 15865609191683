import React, { useContext, useEffect, useState } from 'react'
import { Divider, Text } from '@chakra-ui/react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'

import { FieldLine } from '../../FieldLine'

export function Good({ name, description, _brId, _catId, _akIds }) {
    const { receiver } = useContext(SocketContext)

    const [goodAttributeKeys, setGoodAttributeKeys] = useState([])

    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])

    /* effect to update lists of attributes and good attributes */
    useEffect(() => {
        /* load attribute keys */
        receiver.on('crud:attribute_key:find:ok', (response) => {
            setGoodAttributeKeys(response)
        })

        /* load brands */
        receiver.on('crud:brand:find:ok', (response) => {
            setBrands(response.result)
        })

        /* load categories */
        receiver.on('crud:category:find:ok', (response) => {
            setCategories(response.result)
        })

        /* attribute keys finder by list of ak ids from good */
        receiver.emit('crud:attribute_key:find', { _akIds })

        receiver.emit('crud:brand:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:category:find', { page: 0, size: Number.MAX_VALUE })

        return () => {
            receiver.off('crud:attribute_key:find:ok')
            receiver.off('crud:brand:find:ok')
            receiver.off('crud:category:find:ok')
        }
    }, [])

    return (
        <>
            <Text fontSize="2xl">Informações</Text>
            <FieldLine title="Nome" value={`${name}`} />
            <FieldLine title="Descrição" value={`${description}`} />

            <FieldLine
                title="Marca"
                value={brands.find((br) => br._id === _brId)?.name}
            />

            <FieldLine
                title="Categoria"
                value={categories.find((cat) => cat._id === _catId)?.name}
            />

            <Divider />
            <Text fontSize="2xl">Atributos</Text>
            {goodAttributeKeys.map((attrKey) => (
                <Text key={attrKey._id}>{attrKey.name}</Text>
            ))}
        </>
    )
}
