import React, { useContext } from 'react'
import { Flex, Spinner, Stack } from '@chakra-ui/react'
import { Carousel } from './Carousel'
import { Options } from './Options'
import { Details } from './Details'
import { GoodVariationAttributes } from './Attributes'
import { GoodVariationContext } from '../../../contexts/goodVariation/GoodVariationContext'

export function GoodVariationBanner() {
    const { loading } = useContext(GoodVariationContext)

    if (loading) {
        return (
            <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                direction="column"
                gap={4}
            >
                <Spinner />
            </Flex>
        )
    }

    return (
        <Flex
            w="100%"
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="column"
            gap={4}
        >
            <Details />

            <Stack
                w="100%"
                maxW={['100%', '100%', '723px', '933px', '1128px']}
                marginX="auto"
                spacing={4}
                direction={['column', 'column', 'column', 'row']}
            >
                <Stack
                    borderRadius="2xl"
                    justify="space-between"
                    backgroundColor="whiteAlpha.900"
                    p={4}
                    direction={['column', 'column', 'row']}
                    flex={1}
                >
                    {/* <Options /> */}
                    <Flex flex={1} justify="center">
                        <Carousel />
                    </Flex>
                </Stack>
                <GoodVariationAttributes />
            </Stack>
        </Flex>
    )
}
