import { Flex } from '@chakra-ui/react'
import React from 'react'
import { Breadcrumb } from '../../components/Breadcrumb'
import { GoodVariationBanner } from '../../components/GoodVariation/Banner'
import { GoodVariationDescription } from '../../components/GoodVariation/Description'
import { GoodVariationContextProvider } from '../../contexts/goodVariation/GoodVariationContext'

export function GoodVariation({ location }) {
    const { good } = location.state

    return (
        <Flex height="max-content" p={4} gap={4} direction="column" w="100%">
            <Flex w="100%">
                <Breadcrumb page="good" />
            </Flex>
            <GoodVariationContextProvider good={good}>
                <GoodVariationBanner />
                <GoodVariationDescription />
            </GoodVariationContextProvider>
        </Flex>
    )
}
