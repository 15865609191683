import React, { useContext } from 'react'
import { Stack, useBreakpointValue } from '@chakra-ui/react'

import { CartContext } from '../../../contexts/cart/CartContext'
import { Empty } from './Empty'
import { Basket } from './Basket'
import { Total } from './Total'

export function Sales() {
    const { cart } = useContext(CartContext)
    const isMobileUser = useBreakpointValue({
        base: true,
        lg: false,
    })

    return cart.length === 0 ? (
        <Empty />
    ) : (
        <Stack
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            spacing={8}
            align="flex-start"
            direction={isMobileUser ? 'column' : 'row'}
            paddingBottom={8}
        >
            <Basket />
            <Total isMobileUser={isMobileUser} />
        </Stack>
    )
}
