import React, {
    useState,
    createContext,
    useContext,
    useMemo,
    useEffect,
} from 'react'

import { v4 as uuidv4 } from 'uuid'

import noImage from '../../assets/no-image.svg'

import { SocketContext } from '../socket/SocketContext'

export const GoodVariationContext = createContext({})

export function GoodVariationContextProvider({ children, good }) {
    const { receiver } = useContext(SocketContext)

    const [goodVariation, goodVariationSet] = useState({})
    const [attributes, attributesSet] = useState([])
    const [category, categorySet] = useState([])

    const [loading, loadingSet] = useState(true)

    const selectedAttrs = useMemo(() => {
        return attributes.reduce((acc, { attrKey, attrValues }) => {
            const newAcc = [...acc]
            newAcc.push({
                attrKey,
                attrValue: attrValues.find((attrValue) => attrValue.selected),
            })
            return newAcc
        }, [])
    }, [attributes])

    const refreshAttributesAndGood = (actualAttr) => {
        receiver.emit('good:get:details', {
            gdId: good._id,
            selectedAttrs,
            actualAttr,
        })
    }

    useEffect(() => {
        receiver.on('good:get:details:ok', (response) => {
            if (response.goodVariation.pictures.length === 0) {
                response.goodVariation.pictures.push({
                    path: noImage,
                    _id: uuidv4(),
                })
            }

            goodVariationSet(response.goodVariation)
            categorySet(response.category)
            attributesSet(response.attributes)

            setTimeout(() => {
                loadingSet(false)
            }, 300)
        })

        receiver.emit('good:get:details', {
            gdId: good._id,
            selectedAttrs: [],
            actualAttr: {
                key: null,
                value: null,
            },
        })

        return () => {
            receiver.off('good:get:details:ok')
        }
    }, [receiver, good._id])

    const value = useMemo(
        () => ({
            good,
            goodVariation,
            selectedAttrs,
            attributes,
            category,
            loading,
            goodVariationSet,
            attributesSet,
            categorySet,
            refreshAttributesAndGood,
        }),
        [good, goodVariation, selectedAttrs, attributes, category, loading]
    )

    return (
        <GoodVariationContext.Provider value={value}>
            {children}
        </GoodVariationContext.Provider>
    )
}
