import React, { useContext } from 'react'
import { Heading, Text, VStack } from '@chakra-ui/react'
import { CartContext } from '../../../../contexts/cart/CartContext'
import { Card } from './Card'

export function Basket() {
    const { cart } = useContext(CartContext)

    return (
        <VStack width="100%" justify="flex-start" align="flex-start">
            <Text fontSize="2xl" color="orange.400">
                Minha cesta
            </Text>
            <VStack spacing={4} w="100%">
                {cart.map((product) => (
                    <Card key={product._saleId} product={product} />
                ))}
            </VStack>
        </VStack>
    )
}
