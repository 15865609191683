import React, { useEffect, useContext, useState } from 'react'

import { Flex } from '@chakra-ui/react'
import { Breadcrumb } from '../../components/Breadcrumb'
import { WorkspaceOptions } from '../../components/Workspace/Options'

import { SocketContext } from '../../contexts/socket/SocketContext'

export function Workspace({ path, menus }) {
    const { plan } = useContext(SocketContext)

    return (
        <Flex height="max-content" p={4} gap={4} direction="column" w="100%">
            <Breadcrumb page={path} />
            <WorkspaceOptions options={menus} disableNfe={plan !== 3} />
        </Flex>
    )
}
