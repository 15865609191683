import React, { useEffect } from 'react'

import { Divider, Text } from '@chakra-ui/react'

import { Input } from '../../../Form/Input'
import { CustomBehaviorGoodVariation } from '../CustomBehaviors/GoodVariation'

export function GoodVariation({
    errors,
    setValue,
    register,
    setCustomOnSubmit,
    setCustomValidateFields,
}) {
    useEffect(() => {
        setValue('nfe.cfop', 0, { shouldDirty: true })
        setValue('nfe.tax.totalTax', 0, { shouldDirty: true })
        setValue('saleTest', false, { shouldDirty: true })
    }, [])

    return (
        <>
            <Text fontSize="2xl">Informações</Text>

            <Input
                name="reference"
                error={errors.reference}
                label="Referência"
                type="text"
                {...register('reference')}
            />
            <Input
                name="price.cost"
                error={errors.price?.cost}
                label="Custo"
                type="number"
                step="0.01"
                {...register('price.cost')}
            />
            <Input
                name="price.retail"
                error={errors.price?.retail}
                label="Preço de venda"
                type="number"
                step="0.01"
                {...register('price.retail')}
            />

            <Divider />

            <CustomBehaviorGoodVariation
                setCustomOnSubmit={setCustomOnSubmit}
                setCustomValidateFields={setCustomValidateFields}
                setValue={setValue}
                register={register}
            />
        </>
    )
}
