import React from 'react'

import { Divider, Flex, Image, Stack, Text } from '@chakra-ui/react'

import { Link, useHistory } from 'react-router-dom'

export function GoodCard({ good }) {
    const navigate = useHistory()

    return (
        <Flex
            backgroundColor="whiteAlpha.900"
            p={4}
            borderRadius="2xl"
            direction="column"
            cursor="pointer"
            h="460px"
            w={['100%', '100%', '354px', '300px']}
            onClick={() => navigate.push({ pathname: 'good', state: { good } })}
            gap={4}
        >
            <Text fontSize="2xl" fontWeight="bold">
                {good.name}
            </Text>

            <Divider />

            <Flex h="100%" overflow="hidden">
                <Image
                    src={good.pictures[0].path}
                    alt={good.pictures[0]._id}
                    objectFit="contain"
                    w="270px"
                    h="auto"
                    alignSelf="center"
                />
            </Flex>

            <Divider />

            <Text fontSize="1xl" fontWeight="bold">
                {good.description}
            </Text>

            <Text fontSize="small">{good.reference}</Text>

            <Text fontSize="2xl" fontWeight="bold">
                {`${good.priceFormatted.min.retail} ~ ${good.priceFormatted.max.retail}`}
            </Text>
        </Flex>
    )
}
