import React from 'react'

import { Flex, Icon, SimpleGrid, Text } from '@chakra-ui/react'

import { Icon as IconSemantic } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export function WorkspaceOptions({ options, disableNfe }) {
    return (
        <Flex
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
        >
            <SimpleGrid w="100%" minChildWidth="180px" spacing={[4, 8]}>
                {options.map(({ name, path, color, icon, disabled }) => {
                    return disabled ||
                        (path === 'workspace-nfe' && disableNfe) ? (
                        <Option
                            name={name}
                            color={color}
                            icon={icon}
                            disabled
                            key={path}
                        />
                    ) : (
                        <Link key={path} to={`/${path}`}>
                            <Option name={name} color={color} icon={icon} />
                        </Link>
                    )
                })}
            </SimpleGrid>
        </Flex>
    )
}

function Option({ name, color, icon, disabled }) {
    return (
        <Flex
            direction="column"
            justifyContent="space-between"
            background={color}
            height="150px"
            borderRadius="2xl"
            p={4}
            opacity={disabled && 0.4}
            cursor={disabled && 'not-allowed'}
            transition="0.5s filter"
            _hover={{
                filter: 'brightness(0.85)',
            }}
        >
            <Text fontSize="2xl" color="white">
                {name}
            </Text>
            <Icon
                as={IconSemantic}
                name={icon}
                size="big"
                alignSelf="end"
                color="white"
            />
        </Flex>
    )
}
