import React, { useContext, useEffect, useState } from 'react'

import { Grid, Card, Image, Input, Button, Icon } from 'semantic-ui-react'
import { Heading, Text, useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import logo from '../../assets/logo.png'

import { Container, Box } from './styles'
import { api } from '../../services/api'
import { SocketContext } from '../../contexts/socket/SocketContext'

export function Login() {
    const { bearer, setBearer, receiver, initSocket } =
        useContext(SocketContext)

    const toast = useToast()
    const navigate = useHistory()

    const [passwordHide, setPasswordHide] = useState(true)
    const [username, setUsername] = useState(undefined)
    const [password, setPassword] = useState(undefined)

    useEffect(() => {
        if (bearer && receiver) {
            navigate.replace('/main')
        }
    }, [bearer, receiver])

    const isFieldsValid = () => {
        if (
            username === undefined ||
            username === '' ||
            password === undefined ||
            password === ''
        ) {
            toast({
                title: 'Atenção',
                description: 'Impossível continuar sem um usuário e/ou senha',
                status: 'warning',
                position: 'top-right',
                isClosable: false,
            })

            return false
        }

        return true
    }

    const handleLogin = async () => {
        if (isFieldsValid()) {
            try {
                const res = await api.post('/api/auth/socket', {
                    username,
                    password,
                })

                toast.closeAll()
                setBearer(res.data)
                initSocket(res.data.token)
            } catch (e) {
                if (e?.message?.includes('Network Error')) {
                    navigate.replace('/down')
                } else {
                    toast({
                        title: 'Erro',
                        description: e.message.includes('403')
                            ? 'Credenciais inválidas'
                            : e.message,
                        status: 'error',
                        position: 'top-right',
                        isClosable: false,
                    })
                }
            }
        }
    }

    const passwordHide_ = () => {
        setPasswordHide(!passwordHide)
    }

    const cardRender = () => {
        return (
            <Card>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div
                                style={{
                                    paddingTop: '40px',
                                    paddingBottom: '20px',
                                }}
                            >
                                <Image src={logo} size="small" centered />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Heading as="h1" size="xl" textAlign="center">
                                Bem-vindo
                            </Heading>
                            <Text
                                color="gray.500"
                                fontWeight="bold"
                                textAlign="center"
                            >
                                Quem está usando o sistema?
                            </Text>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div style={{ paddingLeft: '20px' }}>
                                <Input
                                    icon="user"
                                    iconPosition="left"
                                    placeholder="Usuário"
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    style={{ width: '250px' }}
                                />
                            </div>
                            <div
                                style={{
                                    paddingTop: '10px',
                                    paddingLeft: '20px',
                                }}
                            >
                                <Input
                                    type={passwordHide ? 'password' : ''}
                                    icon="key"
                                    iconPosition="left"
                                    placeholder="Senha"
                                    action={
                                        <Button
                                            icon={
                                                passwordHide ? 'hide' : 'unhide'
                                            }
                                            onClick={passwordHide_}
                                        />
                                    }
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    style={{ width: '212px' }}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <div
                                style={{
                                    paddingRight: '17px',
                                    float: 'right',
                                    paddingBottom: '20px',
                                }}
                            >
                                <Button
                                    animated
                                    color="orange"
                                    size="large"
                                    onClick={handleLogin}
                                >
                                    <Button.Content visible>
                                        Entrar
                                    </Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="arrow right" />
                                    </Button.Content>
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card>
        )
    }

    return (
        <Container>
            <Box>
                <div className="wave -one" />
                <div className="wave -two" />
                <div className="wave -three" />
            </Box>
            {cardRender()}
            <Text color="#ffffff" textAlign="center" className="version">
                versão: 1.0, build: 0
            </Text>
        </Container>
    )
}
