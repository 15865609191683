import React, { useContext, useEffect, useState } from 'react'

import * as yup from 'yup'

import { Checkbox, Text, VStack } from '@chakra-ui/react'
import { Input } from '../../../../Form/Input'
import { SocketContext } from '../../../../../contexts/socket/SocketContext'

export function Quantity({ setCustomOnSubmit, setCustomValidateFields }) {
    const { receiver } = useContext(SocketContext)

    const [branches, setBranches] = useState([])
    const [quantities, setQuantities] = useState({})
    const [errors, setErrors] = useState({})

    useEffect(() => {
        receiver.on('crud:branch:find:ok', (response) => {
            setBranches(response.result)
            setQuantities(
                response.result.reduce((acc, branch) => {
                    acc[branch._id] = 0
                    return acc
                }, {})
            )
        })

        receiver.emit('crud:branch:find', { page: 0, size: Number.MAX_VALUE })

        return () => {
            receiver.off('crud:branch:find:ok')
        }
    }, [])

    const insertQuantities = (formData) => {
        branches.forEach((branch) => {
            receiver.emit('crud:good_location:insert', {
                quantity: quantities[branch._id] ? quantities[branch._id] : 0,
                _coId: branch._coId,
                _brId: branch._id,
                _gdvId: formData?._id,
            })
        })
    }

    const customSubmit = (formData) => {
        insertQuantities(formData)

        return false
    }

    const customValidateFields = async () => {
        /* schema to validate name */
        const schema = yup
            .number()
            .min(0)
            .typeError('Digite um valor maior ou igual a 0')
            .required('Campo obrigatório')

        /* object of errors */
        const newErrors = { ...errors }

        const isValid = await Object.entries(quantities).reduce(
            async (acc, [branchId, quantity]) => {
                let newAcc = await acc

                try {
                    /* if not valid, set a new error for this field */
                    await schema.validate(quantity)
                    /* remove the error message */
                    delete newErrors[branchId]
                } catch (e) {
                    newAcc = false
                    /* show the first error message */
                    newErrors[branchId] = { message: e?.errors[0] }
                }

                return newAcc
            },
            Promise.resolve(true)
        )

        setErrors(newErrors)

        return isValid
    }

    /* effect to update customSubmit */
    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })

        setCustomValidateFields(() => {
            return customValidateFields
        })
    }, [quantities, errors])

    const handleChange = (e, branchId) => {
        const newQuantities = { ...quantities }
        const value = e.target.value === '' ? 0 : e.target.value

        newQuantities[branchId] = parseInt(value, 10)
        setQuantities(newQuantities)
    }

    return (
        <VStack spacing={4} w="100%" alignItems="flex-start" px={2}>
            <Text fontSize="2xl">Quantidade</Text>
            {branches.map((branch) => (
                <Input
                    key={branch?._id}
                    label={branch?.name}
                    error={errors[branch?._id]}
                    type="number"
                    defaultValue={0}
                    onChange={(e) => handleChange(e, branch?._id)}
                />
            ))}
        </VStack>
    )
}
