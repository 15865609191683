import React from 'react'

import { Flex, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

export function MenuCard({ route, color, name }) {
    const navigate = useHistory()

    return (
        <Flex
            minW="150px"
            width="100%"
            h="150px"
            p={4}
            borderRadius="2xl"
            background={color}
            transition="opacity 0.5s ease"
            _hover={{
                opacity: 0.7,
            }}
            onClick={() => navigate.push(`/${route}`, { path: route })}
        >
            <Text color="white" fontSize="2xl">
                {name}
            </Text>
        </Flex>
    )
}
