import React from 'react'

import { Flex, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { Icon as IconSemantic } from 'semantic-ui-react'

import { InvoicesDownloadModal } from './InvoicesDownloadModal'

export function InvoicesDownload({ branch }) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Flex
                as="button"
                onClick={onOpen}
                direction="column"
                justifyContent="space-between"
                background="linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(339, 78%, 43%, 1) 100%)"
                height="150px"
                borderRadius="2xl"
                p={4}
                maxW={['unset', 'unset', '300px']}
            >
                <Text fontSize="2xl" color="white">
                    Notas fiscais
                </Text>
                <Icon
                    as={IconSemantic}
                    name="file alternate"
                    size="big"
                    alignSelf="end"
                    color="white"
                />
            </Flex>

            <InvoicesDownloadModal
                isOpen={isOpen}
                onClose={onClose}
                branchId={branch}
            />
        </>
    )
}
