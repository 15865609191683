import React from 'react'

import { Button, Flex } from '@chakra-ui/react'

export function Footer({ isSubmitting }) {
    return (
        <Flex w="100%" justify="flex-end">
            <Button colorScheme="orange" isLoading={isSubmitting} type="submit">
                Confirmar
            </Button>
        </Flex>
    )
}
