import React from 'react'
import { Flex, Select } from '@chakra-ui/react'

const options = [1, 6, 12, 18, 48, 90]

export function NumberOfItemsSelector({ size, sizeSet, pageSet }) {
    return (
        <Flex>
            <Select
                value={size}
                onChange={(e) => {
                    sizeSet(e.target.value)
                    pageSet(1)
                }}
                colorScheme="yellow"
            >
                {options.map((opt) => (
                    <option key={opt} value={opt}>
                        {opt}
                    </option>
                ))}
            </Select>
        </Flex>
    )
}
