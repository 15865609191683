export const options = [
    {
        name: 'Categoria',
        path: '/search-by-category',
        color: `linear-gradient(0deg, rgb(255, 140, 0), #f08930)`,
    },
    {
        name: 'Atributo',
        path: '/search-by-attribute',
        color: `linear-gradient(0deg, rgb(255, 140, 0), #d35434)`,
    },
    {
        name: 'Nome',
        path: '/search-by-name',
        color: `linear-gradient(0deg, rgb(255, 140, 0), #fa6b19)`,
    },
    {
        name: 'Código',
        path: '/search-by-code',
        color: `linear-gradient(0deg, rgb(255, 140, 0), #fd6e1c)`,
    },
]
