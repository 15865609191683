import React, { useContext } from 'react'
import { Box, Button, Flex, Stack, Text, useToast } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import { CartContext } from '../../../../contexts/cart/CartContext'
import { GoodVariationContext } from '../../../../contexts/goodVariation/GoodVariationContext'

export function Details() {
    const toast = useToast()

    const { addGoodToCart } = useContext(CartContext)
    const { good, goodVariation, selectedAttrs } =
        useContext(GoodVariationContext)

    const handleAddToCart = () => {
        const goodWithAttributes = { ...goodVariation }

        goodWithAttributes._saleId = uuidv4()
        goodWithAttributes.attributes = selectedAttrs
        goodWithAttributes.addedIn = new Intl.DateTimeFormat('pt-BR', {
            dateStyle: 'short',
            timeStyle: 'short',
        }).format(new Date())

        addGoodToCart(goodWithAttributes)

        toast({
            title: 'Inclusão',
            description: `Item ${goodWithAttributes.reference} adicionado a cesta com sucesso`,
            position: 'top',
            status: 'success',
            isClosable: true,
        })
    }

    return (
        <Flex
            w="100%"
            backgroundColor="whiteAlpha.900"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            justify="flex-end"
            gap={4}
            p={4}
            borderRadius="2xl"
            direction={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'center']}
        >
            <Text
                justifyContent="center"
                fontSize="3xl"
                fontWeight="bold"
                w="100%"
            >
                {`${good.name} - ${goodVariation.reference}`}
            </Text>

            <Stack
                direction={['column', 'row']}
                spacing={8}
                w="100%"
                justify={['space-between', 'space-between', 'flex-end']}
            >
                <Box>
                    <Text fontSize="2xl" fontWeight="bold" color="green">
                        {goodVariation.priceFormatted.retail}
                    </Text>

                    <Text fontSize={20}>
                        Em estoque:{' '}
                        <Text as="span" color="blue">
                            {goodVariation.qty}
                        </Text>
                    </Text>
                </Box>

                <Button
                    alignSelf="end"
                    colorScheme="telegram"
                    minH="60px"
                    onClick={handleAddToCart}
                    p={4}
                >
                    <Text fontWeight="bold" fontSize="large">
                        Adicionar a cesta
                    </Text>
                </Button>
            </Stack>
        </Flex>
    )
}
