import React, { useContext, useMemo, useState } from 'react'

import {
    Collapse,
    Flex,
    Text,
    Button,
    HStack,
    VStack,
    Divider,
} from '@chakra-ui/react'

import { SaleContext } from '../../../../../../contexts/sale/SaleContext'
import { Input } from '../../../../../Form/Input'
import { SocketContext } from '../../../../../../contexts/socket/SocketContext'

export function Debit({
    isOpen,
    customer,
    isSubmitSuccessful,
    waiting,
    isSubmitSuccessfulSet,
    waitingSet,
    pdfBase64,
    branchHasUpdatedCertificate,
    finishSale,
}) {
    const { user } = useContext(SocketContext)
    const { sale, branch, isSaleTest } = useContext(SaleContext)

    const [discount, discountSet] = useState(0)

    const total = useMemo(() => {
        return sale.products.reduce(
            (acc, cur) => acc + Number.parseFloat(cur.price.retail),
            0
        )
    }, [sale.products])

    const onSubmit = (e) => {
        e.preventDefault()

        isSubmitSuccessfulSet(false)
        waitingSet(true)

        const data = {
            customer,
            user,
            branch,
            saleItems: sale.products.map((product) => ({
                ...product,
                price: {
                    retail: parseFloat(product?.price?.retail),
                    cost: parseFloat(product?.price?.cost),
                },
                saleTest: !!product.saleTest,
                nfe: {
                    ...product.nfe,
                    unitAmount: parseFloat(product?.price?.retail || 0),
                    quantity: 1,
                },
            })),
            payment: {
                pt: 2,
                tax: 0,
                total: sale.total,
                discount: e.target.discount.value,
                cash: 0,
            },
            saleTest: sale.products.reduce(
                (acc, product) => acc || !!product.saleTest,
                false
            ),
        }

        finishSale(data)
    }

    return (
        <Collapse in={isOpen} animateOpacity>
            <Flex
                as="form"
                direction="column"
                justify="center"
                align="center"
                onSubmit={onSubmit}
                gap={4}
            >
                <Input
                    label="Desconto %"
                    name="discount"
                    type="number"
                    step="1"
                    min={0}
                    max={100}
                    value={discount}
                    onChange={(e) => {
                        discountSet(Number.parseInt(e.target.value || 0, 10))
                    }}
                />

                <Text
                    fontSize="medium"
                    color="green.500"
                    w="100%"
                    textAlign="end"
                >
                    Preço final da venda:{' '}
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(total - (total * discount) / 100)}
                </Text>

                <Button
                    colorScheme="orange"
                    isLoading={waiting}
                    type="submit"
                    alignSelf="end"
                    isDisabled={!branchHasUpdatedCertificate && !isSaleTest}
                >
                    Confirmar
                </Button>

                {isSubmitSuccessful && (
                    <Text textAlign="center" color="green.500">
                        Venda realizada com sucesso!
                    </Text>
                )}
                {isSaleTest && isSubmitSuccessful && !!pdfBase64 && (
                    <a
                        download={`sale-${new Date().getTime()}.pdf`}
                        href={pdfBase64}
                    >
                        Baixar cupom fiscal como pdf
                    </a>
                )}
            </Flex>
        </Collapse>
    )
}
