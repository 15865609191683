import React, { useState, useContext } from 'react'

import { Text, useBreakpointValue } from '@chakra-ui/react'
import { SearchBar } from '../../../Search/SearchBar'
import { searchByFields } from './searchByFields'

import { SocketContext } from '../../../../contexts/socket/SocketContext'

export function SearchBarCrudList({ value, valueSet, crudName, size }) {
    const { receiver } = useContext(SocketContext)
    const isMobileUser = useBreakpointValue({
        base: true,
        lg: false,
    })

    const buildQuery = () => {
        const query = []

        searchByFields[crudName].keys.forEach((key) => {
            if (key !== '_id')
                query.push({
                    [key]: {
                        $regex: `.*${value}.*`,
                        $options: 'i',
                    },
                })
        })

        if (query.length > 0) {
            return {
                $or: query,
            }
        }

        return null
    }

    const onFilter = () => {
        if (
            searchByFields[crudName].keys.some((key) => key === '_id') &&
            value.length === 24
        ) {
            receiver.emit(`crud:${crudName}:find`, {
                _id: value,
            })
        } else {
            receiver.emit(`crud:${crudName}:find`, {
                page: 0,
                size: parseInt(size, 10),
                query: buildQuery(),
            })
        }
    }

    return (
        <>
            <SearchBar
                isMobileUser={isMobileUser}
                label={searchByFields[crudName].label}
                placeholder={searchByFields[crudName].placeholder}
                value={value}
                valueSet={valueSet}
                onFilter={onFilter}
                qrCode={false}
            />

            {!!value && <Text>Filtrado por {value}</Text>}
        </>
    )
}
