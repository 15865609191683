import React, {
    createContext,
    useEffect,
    useState,
    useContext,
    useMemo,
} from 'react'

import { v4 } from 'uuid'
import { SocketContext } from '../socket/SocketContext'

import noImage from '../../assets/no-image.svg'

export const FilterContext = createContext({})

export function FilterContextProvider({ children }) {
    const { receiver } = useContext(SocketContext)

    const [filters, filtersSet] = useState({})
    const [filtersOptions, filtersOptionsSet] = useState({})
    const [results, resultsSet] = useState({})

    const [loading, loadingSet] = useState(true)

    const initialRequests = () => {
        receiver.emit('good:get:category:and:attribute')
    }

    const addSocketListeners = () => {
        receiver.on('good:get:category:and:attribute:ok', (response) => {
            filtersOptionsSet(response)
            receiver.emit('good:find:by:category', { categories: [] })
        })

        receiver.on('good:find:by:category:ok', (response) => {
            receiver.emit('good:get:picture', response)
        })

        receiver.on('good:find:by:attribute:ok', (response) => {
            receiver.emit('good:get:picture', response)
        })

        receiver.on('good:find:by:name:ok', (response) => {
            receiver.emit('good:get:picture', response)
        })

        receiver.on('good:find:by:reference:ok', (response) => {
            receiver.emit('good:get:picture', response)
        })

        receiver.on('good:get:picture:ok', (response) => {
            const newResponse = Object.entries(response).reduce(
                (acc, [id, good]) => {
                    const newAcc = { ...acc }
                    const newGood = { ...good }

                    if (newGood.pictures.length === 0) {
                        newGood.pictures.push({
                            _id: v4(),
                            path: noImage,
                        })
                    }

                    newAcc[id] = newGood

                    return newAcc
                },
                {}
            )

            resultsSet(newResponse)

            setTimeout(() => {
                loadingSet(false)
            }, 500)
        })
    }

    useEffect(() => {
        initialRequests()
        addSocketListeners()

        return () => {
            receiver.off('good:get:category:and:attribute:ok')
            receiver.off('good:find:by:category:ok')
            receiver.off('good:find:by:attribute:ok')
            receiver.off('good:find:by:reference:ok')
            receiver.off('good:find:by:code:ok')
            receiver.off('good:get:picture:ok')
        }
    }, [receiver])

    const value = useMemo(
        () => ({
            filters,
            filtersSet,
            filtersOptions,
            results,
            loading,
        }),
        [filters, filtersOptions, results, loading]
    )

    return (
        <FilterContext.Provider value={value}>
            {children}
        </FilterContext.Provider>
    )
}
