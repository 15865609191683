import React, { useContext } from 'react'
import {
    Flex,
    Image,
    Text,
    useBreakpointValue,
    Icon as ChakraIcon,
    Divider,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react'
import { Icon } from 'semantic-ui-react'
import { SaleContext } from '../../../../contexts/sale/SaleContext'
import { SocketContext } from '../../../../contexts/socket/SocketContext'

export function SaleCard() {
    const { user } = useContext(SocketContext)
    const { sale, removeGoodFromSale, alterGoodPrice } = useContext(SaleContext)

    const isMobileUser = useBreakpointValue({
        base: true,
        sm: false,
    })

    return (
        <Flex
            background="white"
            flexDirection="column"
            borderRadius={20}
            gap={8}
        >
            {sale.products.map((product) => (
                <Flex
                    key={product._saleId}
                    background="whiteAlpha.900"
                    borderRadius="2xl"
                    direction={isMobileUser ? 'column' : 'row'}
                    position="relative"
                    width="100%"
                    boxShadow="base"
                    p={4}
                    gap={4}
                >
                    <Image
                        maxW="200px"
                        maxH="200px"
                        h="auto"
                        w="auto"
                        src={product.pictures[0].path}
                        alt={product.pictures[0]._id}
                        alignSelf="center"
                    />
                    <Flex direction="column" width="100%">
                        <Flex
                            width="100%"
                            justifyContent="space-between"
                            gap={8}
                        >
                            <Text
                                fontSize="medium"
                                fontWeight="bold"
                                wordBreak="break-all"
                            >
                                {product.name}
                            </Text>

                            <ChakraIcon
                                aria-label="remove-product-from-sale"
                                name="trash"
                                cursor="pointer"
                                size="large"
                                as={Icon}
                                onClick={() => {
                                    removeGoodFromSale(product)
                                }}
                            />
                        </Flex>

                        <Text fontSize="small"> Ref: {product.reference} </Text>

                        {product.attributes.map(({ attrKey, attrValue }) => (
                            <Text key={`${attrKey?._id}-${attrValue?._id}`}>
                                {attrKey?.name}:{' '}
                                <Text as="span">{attrValue?.name}</Text>
                            </Text>
                        ))}

                        <Divider h={2} />

                        <Text fontSize="medium">
                            Vendido por:{' '}
                            <Text
                                fontSize="medium"
                                as="span"
                                color="orange.500"
                            >
                                {user.firstName}
                            </Text>
                        </Text>

                        <Flex
                            gap={4}
                            marginTop={isMobileUser ? 12 : 'auto'}
                            alignItems="center"
                        >
                            <Text
                                fontSize="medium"
                                w="100%"
                                textAlign="end"
                                flex="1"
                            >
                                {'Preço: '}
                            </Text>
                            <NumberInput
                                value={product.price.retail}
                                onChange={(value) => {
                                    alterGoodPrice(value || 0, product)
                                }}
                                precision={2}
                                w="140px"
                                min={0}
                            >
                                <NumberInputField
                                    fontSize="medium"
                                    textAlign="right"
                                />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Flex>
                    </Flex>
                </Flex>
            ))}
        </Flex>
    )
}
