import React from 'react'

import { Avatar } from '@chakra-ui/react'

import { FieldLine } from '../../FieldLine'

export function Customer({
    firstName,
    lastName,
    dob,
    icNumber,
    base64,
    contact,
}) {
    return (
        <>
            <Avatar src={base64} size="lg" name={`${firstName} ${lastName}`} />
            <FieldLine title="Nome" value={`${firstName} ${lastName}`} />
            <FieldLine title="Data de Nascimento" value={dob} />
            <FieldLine title="CPF" value={icNumber} />
            <FieldLine title="Número" value={contact?.phoneNumber || ''} />
        </>
    )
}
