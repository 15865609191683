import React from 'react'

import { Center, Divider, Flex } from '@chakra-ui/react'

import { Content } from './Content'
import { Footer } from './Footer'

export function Card({ product }) {
    return (
        <Flex
            background="whiteAlpha.900"
            borderRadius="2xl"
            p={4}
            direction="column"
            position="relative"
            width="100%"
        >
            <Content product={product} />

            <Center h={8}>
                <Divider />
            </Center>

            <Footer product={product} />
        </Flex>
    )
}
