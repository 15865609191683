import React, { useContext } from 'react'
import { Button, HStack } from '@chakra-ui/react'
import { FilterContext } from '../../../../contexts/filter/FilterContext'
import { SocketContext } from '../../../../contexts/socket/SocketContext'

export function SelectedFilters({ variation }) {
    const { receiver } = useContext(SocketContext)
    const { filters, filtersSet } = useContext(FilterContext)

    const onFilter = (newFilters) => {
        if (variation === 'category') {
            const selectedCategories = {
                categories: Object.entries(newFilters).map(
                    ([id, filter]) => id
                ),
            }

            receiver.emit('good:find:by:category', selectedCategories)
        } else {
            const selectedAttributes = {
                attributes: Object.entries(newFilters).map(
                    ([id, filter]) => id
                ),
            }

            receiver.emit('good:find:by:attribute', selectedAttributes)
        }
    }

    const removeFilter = (id) => {
        const newFilters = { ...filters }
        delete newFilters[id]
        filtersSet(newFilters)
        onFilter(newFilters)
    }

    return (
        <HStack
            mb={[Object.keys(filters).length > 0 && 4]}
            maxW="calc(100% - 110px)"
        >
            {Object.entries(filters).map(([id, filter]) => (
                <Button
                    colorScheme="orange"
                    onClick={() => removeFilter(id)}
                    key={id}
                >
                    {filter.name}
                </Button>
            ))}
        </HStack>
    )
}
