import React from 'react'

import { Flex } from '@chakra-ui/react'
import { Breadcrumb } from '../../components/Breadcrumb'
import { SearchOptions } from '../../components/Search/SearchOptions'

export function Search() {
    return (
        <Flex flex={1} p={4} gap={4} height="max-content" direction="column">
            <Breadcrumb page="search" />
            <SearchOptions />
        </Flex>
    )
}
