import React, { useContext, useMemo } from 'react'

import { Flex } from '@chakra-ui/react'

import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'

import { CartContextProvider } from './contexts/cart/CartContext'
import { SaleContextProvider } from './contexts/sale/SaleContext'
import { SocketContext } from './contexts/socket/SocketContext'

import { privateRoutes, privateRoutesWorkers, publicRoutes } from './routes'
import { Header } from './components/Header'
import { Footer } from './components/Footer'

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                        }}
                    />
                )
            }
        />
    )
}

export function Router() {
    const { bearer, receiver } = useContext(SocketContext)

    const isAuthenticated = useMemo(() => {
        return bearer && receiver
    }, [bearer, receiver])

    return (
        <Flex
            align="center"
            width="100vw"
            height="100vh"
            direction="column"
            background="whiteAlpha.300"
            overflow="hidden"
        >
            <BrowserRouter>
                <Switch>
                    {Object.entries(publicRoutes).map(
                        ([key, { Component }]) => (
                            <Route
                                key={key}
                                path={`/${key}`}
                                component={Component}
                            />
                        )
                    )}

                    <CartContextProvider>
                        <SaleContextProvider>
                            <Header />
                            <Flex
                                marginBottom={['50px', '50px', '0px']}
                                flex={1}
                                width="100%"
                                height="100%"
                                justify="center"
                                overflowY="auto"
                                overflowX="hidden"
                            >
                                {bearer?.user?.role === '3'
                                    ? Object.entries(privateRoutesWorkers).map(
                                          ([key, { Component }]) => (
                                              <PrivateRoute
                                                  key={key}
                                                  path={`/${key}`}
                                                  component={Component}
                                                  isAuthenticated={
                                                      isAuthenticated
                                                  }
                                              />
                                          )
                                      )
                                    : Object.entries(privateRoutes).map(
                                          ([key, { Component }]) => (
                                              <PrivateRoute
                                                  key={key}
                                                  path={`/${key}`}
                                                  component={Component}
                                                  isAuthenticated={
                                                      isAuthenticated
                                                  }
                                              />
                                          )
                                      )}
                            </Flex>
                            <Footer />
                        </SaleContextProvider>
                    </CartContextProvider>
                </Switch>
            </BrowserRouter>
        </Flex>
    )
}
