import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useToast } from '@chakra-ui/react'
import { differenceInDays, isBefore } from 'date-fns'
import { SocketContext } from '../../../../contexts/socket/SocketContext'

export const useReportDownload = (branchId, initialDate, endDate) => {
    const toast = useToast()
    const { receiver } = useContext(SocketContext)

    const [errors, setErrors] = useState({
        initialDate: false,
        endDate: false,
    })

    const [isLoading, setIsLoading] = useState(false)
    const [isReportReady, setIsReportReady] = useState(false)

    const [sales, setSales] = useState(null)

    useEffect(() => {
        if (sales) {
            setIsLoading(false)
            setIsReportReady(true)
        }
    }, [sales])

    useEffect(() => {
        receiver.on('sale:report:sinthetic:ok', (res) => {
            setSales(res || [])
        })

        receiver.on('sale:report:sinthetic:error', (res) => {
            toast({
                title: 'Erro',
                description: `Erro: ${res.errors?.[0]?.message}`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
            setIsLoading(false)
            setIsReportReady(false)
        })

        receiver.on('event:rejected', (res) => {
            toast({
                title: 'Erro',
                description: `Erro: Evento rejeitado`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
            setIsLoading(false)
            setIsReportReady(false)
        })

        return () => {
            receiver.off('sale:report:sinthetic:ok')
            receiver.off('sale:report:sinthetic:error')
            receiver.off('event:rejected')
        }
    }, [])

    const isInputValid = useCallback(() => {
        let isValid = true

        if (isBefore(new Date(endDate), new Date(initialDate))) {
            isValid = false

            toast({
                title: 'Erro no formulário',
                description: 'Data de inicio deve ser anterior a data final',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        }

        if (differenceInDays(new Date(endDate), new Date(initialDate)) > 60) {
            isValid = false

            toast({
                title: 'Erro no formulário',
                description: 'Período de tempo deve ser menor que 60 dias',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        }

        setErrors({
            initialDate: !isValid,
            endDate: !isValid,
        })

        return isValid
    }, [initialDate, endDate])

    const downloadReportSinthetic = useCallback(() => {
        setIsReportReady(false)
        setIsLoading(true)

        if (isInputValid(initialDate, endDate)) {
            receiver.emit('sale:report:sinthetic', {
                initialDate,
                endDate,
                branchId,
            })
        } else {
            toast({
                title: 'Erro no formulário',
                description: 'Verifique os campos destacados',
                position: 'top',
                status: 'error',
                isClosable: true,
            })

            setIsLoading(false)
            setIsReportReady(false)
        }
    }, [isInputValid, initialDate, endDate, branchId])

    const values = useMemo(
        () => ({
            downloadReportSinthetic,
            isLoading,
            isReportReady,
            errors,
            sales,
        }),
        [downloadReportSinthetic, isLoading, isReportReady, errors, sales]
    )

    return values
}
