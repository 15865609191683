import React, { useContext, useEffect } from 'react'

import { Flex, SimpleGrid, Skeleton } from '@chakra-ui/react'

import { FilterContext } from '../../../../contexts/filter/FilterContext'

import { GoodCard } from './GoodCard'
import { Empty } from './Empty'

export function ListOfResults() {
    const { results, loading } = useContext(FilterContext)

    if (loading) {
        return (
            <SimpleGrid
                columns={[1, 2, 3]}
                gap={4}
                minChildWidth="300px"
                align="flex-start"
            >
                {[0, 1, 2, 3, 4, 5].map((id) => (
                    <Skeleton
                        key={id}
                        startColor="blackAlpha.100"
                        endColor="blackAlpha.300"
                        height="500px"
                        borderRadius="2xl"
                    />
                ))}
            </SimpleGrid>
        )
    }

    return (
        <Flex gap={4} align="flex-start" flexWrap="wrap">
            {Object.entries(results).length === 0 ? (
                <Empty />
            ) : (
                Object.entries(results).map(([id, good]) => (
                    <GoodCard key={id} good={good} />
                ))
            )}
        </Flex>
    )
}
