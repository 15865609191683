import React, { useContext, useEffect, useState } from 'react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'

import { FieldLine } from '../../FieldLine'

export function Attribute({ name, _id }) {
    const { receiver } = useContext(SocketContext)

    const [attributes, setAttributes] = useState([])

    useEffect(() => {
        receiver.on('crud:attribute_value:find:ok', (response) => {
            /* remove the listener to not conflict to another components */
            receiver.off('crud:attribute_value:find:ok')

            setAttributes(response)
        })

        receiver.emit('crud:attribute_value:find', { _akId: _id })

        return () => {
            receiver.off('crud:attribute_value:find:ok')
        }
    }, [])

    return (
        <>
            <FieldLine title="Nome" value={`${name}`} />
            {attributes.map((attr) => (
                <FieldLine title="Valor" value={attr?.name} />
            ))}
        </>
    )
}
