import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Pagination } from 'semantic-ui-react'

export function Paginator({ page, pageSet, totalPages, nextPage }) {
    return (
        <Flex>
            <Pagination
                activePage={page}
                onPageChange={(e, { activePage }) => {
                    pageSet(activePage)
                    nextPage(activePage)
                }}
                totalPages={totalPages}
                boundaryRange={0}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
            />
        </Flex>
    )
}
