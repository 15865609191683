import React, { useContext, useEffect, useState } from 'react'

import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import {
    Center,
    Flex,
    IconButton,
    InputRightElement,
    SimpleGrid,
    Spinner,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'

import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Input } from '../../../Form/Input'

export function CustomBehaviorAttribute({
    data,
    setCustomOnSubmit,
    setCustomValidateFields,
}) {
    const { receiver } = useContext(SocketContext)

    const toast = useToast()

    const [loading, setLoading] = useState(true)
    const [loadingInterval, SetLoadingInterval] = useState(null)

    /* array of items in db */
    const [attributes, setAttributes] = useState([])
    /* 
        delete and insert items must be controlled by this component
        update items must be controlled by Crud/Edit/index.jsx component
    */
    const [attributesToDelete, setAttributesToDelete] = useState([])
    const [attributesToInsert, setAttributesToInsert] = useState([])
    const [attributesToUpdate, setAttributesToUpdate] = useState([])

    /* object with errors _id: { message: errorMessage } */
    const [avErrors, setAvErrors] = useState({})

    const deleteAttributes = () => {
        /* delete new attributes */
        attributesToDelete.forEach((_id) => {
            receiver.emit('crud:attribute_value:delete', { _id })
        })

        setAttributesToDelete([])
    }

    const insertAttributes = async () => {
        /* insert new attributes */
        attributesToInsert.forEach((attr) => {
            receiver.emit('crud:attribute_value:insert', {
                name: attr.name,
                _akId: attr._akId,
                _coId: attr._coId,
            })
        })
    }

    const updateAttributes = () => {
        /* update attributes */
        attributesToUpdate.forEach(({ _id, name, _akId, _coId }) => {
            receiver.emit('crud:attribute_value:update', {
                _id,
                name,
                _akId,
                _coId,
            })
        })
    }

    const customSubmit = (formData) => {
        if (attributesToDelete.length > 0) {
            deleteAttributes()
        }
        if (attributesToInsert.length > 0) {
            insertAttributes()
        }

        if (attributesToUpdate.length > 0) {
            updateAttributes()
        }

        return false
    }

    const customValidateFields = async () => {
        /* schema to validate name */
        const schema = yup
            .string()
            .typeError('Campo precisa ser um texto')
            .required('Campo obrigatório')

        /* object of errors */
        const newErrors = { ...avErrors }

        const isValid = await [
            ...attributesToInsert,
            ...attributesToUpdate,
        ].reduce(async (acc, attr) => {
            let newAcc = await acc

            try {
                /* if not valid, set a new error for this field */
                await schema.validate(attr.name)
                /* remove the error message */
                delete newErrors[attr._id]
            } catch (e) {
                newAcc = false
                /* show the first error message */
                newErrors[attr._id] = { message: e?.errors[0] }
            }

            return newAcc
        }, Promise.resolve(true))

        setAvErrors(newErrors)

        if (
            [
                ...attributes.filter(
                    (attr) =>
                        !attributesToDelete.some(
                            (attrDelId) => attrDelId === attr._id
                        )
                ),
                ...attributesToInsert,
            ].length === 0
        ) {
            toast({
                title: 'Valores',
                description:
                    'É necessário ter no mínimo um valor para o atributo',
                position: 'bottom',
                status: 'warning',
                isClosable: true,
            })

            return false
        }

        return isValid
    }

    /* effect to update customSubmit */
    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })
        setCustomValidateFields(() => {
            return customValidateFields
        })
    }, [
        attributes,
        attributesToUpdate,
        attributesToDelete,
        attributesToInsert,
        avErrors,
    ])

    /* first effect, to create socket listeners */
    useEffect(() => {
        setLoading(true)

        receiver.on('crud:attribute_value:update:ok', (response) => {})

        receiver.on('crud:attribute_value:delete:ok', (response) => {})

        receiver.on('crud:attribute_value:insert:ok', (response) => {})

        receiver.on('crud:attribute_value:find:ok', (response) => {
            /* remove the listener to not conflict to another components */
            receiver.off('crud:attribute_value:find:ok')

            setAttributes(response)

            /* feedback to user */
            SetLoadingInterval((oldInterval) => {
                clearInterval(oldInterval)
                return setInterval(() => {
                    setLoading(false)
                }, 500)
            })
        })

        receiver.emit('crud:attribute_value:find', { _akId: data._id })

        /* remove the listeners */
        return () => {
            receiver.off('crud:attribute_value:update:ok')
            receiver.off('crud:attribute_value:delete:ok')
            receiver.off('crud:attribute_value:insert:ok')
        }
    }, [])

    const addAttribute = () => {
        const newAttribute = {
            _id: uuidv4(), // the id is discarted before insert, but is a must to render
            name: '',
            _coId: data._coId,
            _akId: data._id,
        }

        setAttributesToInsert([...attributesToInsert, newAttribute])
    }

    const handleDeleteAttribute = (_id) => {
        setAttributesToDelete([...attributesToDelete, _id])
        setAttributes([...attributes.filter((attr) => attr._id !== _id)])
    }

    const handleRemoveNewAttribute = (_id) => {
        setAttributesToInsert(
            attributesToInsert.filter((attr) => attr._id !== _id)
        )
    }

    const handleChangeAttribute = (e, _id) => {
        if (attributesToUpdate.some((attr) => attr._id === _id)) {
            setAttributesToUpdate(
                attributesToUpdate.map((attr) => {
                    const newAttr = { ...attr }
                    if (attr._id === _id) {
                        newAttr.name = e.target.value
                    }
                    return newAttr
                })
            )
        } else {
            const newAttrToUpdate = {
                ...attributes.find((attr) => attr._id === _id),
            }
            newAttrToUpdate.name = e.target.value
            setAttributesToUpdate([...attributesToUpdate, newAttrToUpdate])
        }
    }

    return loading ? (
        <VStack w="100%" align="flex-start">
            <Text>Valores</Text>
            <Center w="100%">
                <Spinner />
            </Center>
        </VStack>
    ) : (
        <VStack w="100%" spacing={4}>
            <Flex justify="space-between" w="100%">
                <Text>Valores</Text>
                <IconButton
                    icon={<AddIcon />}
                    colorScheme="orange"
                    onClick={addAttribute}
                    type="button"
                    size="lg"
                />
            </Flex>
            <SimpleGrid columns={[1, 1, 2]} w="100%" spacingX={4} spacingY={2}>
                {attributes.map(({ _id, name }) => (
                    <Flex flexDirection="row" key={_id}>
                        <Input
                            name={_id}
                            error={avErrors[_id]}
                            label="Valor"
                            type="text"
                            defaultValue={name}
                            onChange={(e) => handleChangeAttribute(e, _id)}
                            addon={
                                <InputRightElement h="100%">
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        colorScheme="blackAlpha"
                                        size="lg"
                                        right="4px"
                                        onClick={() =>
                                            handleDeleteAttribute(_id)
                                        }
                                    />
                                </InputRightElement>
                            }
                        />
                    </Flex>
                ))}
                {attributesToInsert.map(({ name, _id }) => (
                    <Flex flexDirection="row" key={_id}>
                        <Input
                            name={_id}
                            error={avErrors[_id]}
                            label="Valor"
                            type="text"
                            value={name}
                            onChange={(e) => {
                                setAttributesToInsert([
                                    ...attributesToInsert.map((attr) => {
                                        const newAttr = { ...attr }
                                        if (attr._id === _id) {
                                            newAttr.name = e.target.value
                                        }
                                        return newAttr
                                    }),
                                ])
                            }}
                            addon={
                                <InputRightElement h="100%">
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        colorScheme="blackAlpha"
                                        right="4px"
                                        onClick={() =>
                                            handleRemoveNewAttribute(_id)
                                        }
                                    />
                                </InputRightElement>
                            }
                        />
                    </Flex>
                ))}
            </SimpleGrid>
        </VStack>
    )
}
