import React, { useContext, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Divider, Flex, useToast, VStack } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { Breadcrumb } from '../../../components/Breadcrumb'
import { CrudEditSwitch } from '../../../components/Crud/Edit/CrudEditSwitch'
import { Footer } from '../../../components/Crud/Edit/Footer'
import { SocketContext } from '../../../contexts/socket/SocketContext'
import { breadcrumbs } from '../../../components/Breadcrumb/breadcrumbs'
import { formSchemas } from '../../../utils/schemas'

export function CrudEdit({ location }) {
    const { data, crudName, path } = location.state

    const history = useHistory()
    const toast = useToast()

    const { receiver } = useContext(SocketContext)
    const [customOnSubmit, setCustomOnSubmit] = useState(null)
    const [customValidateFields, setCustomValidateFields] = useState(null)

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, dirtyFields },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(formSchemas[crudName]),
        defaultValues: Object.keys(formSchemas[crudName].fields).reduce(
            (acc, key) => {
                if (key === 'timestamp') {
                    acc[key] = new Intl.DateTimeFormat('pt-BR').format(
                        data[key]
                    )
                } else {
                    acc[key] = data[key]
                }
                return acc
            },
            {}
        ),
    })

    useEffect(() => {
        receiver.on(`crud:${crudName}:update:ok`, (response) => {
            toast({
                title: breadcrumbs[path]?.name,
                description: `${breadcrumbs[path]?.name} atualizado com sucesso`,
                position: 'top',
                status: 'success',
            })
            history.goBack()
        })

        return () => {
            receiver.off(`crud:${crudName}:update:ok`)
        }
    }, [])

    const traitProps = (formData) => {
        const newFormData = Object.entries(formData).reduce(
            (acc, [key, value]) => {
                if (dirtyFields[key]) {
                    acc[key] = value
                }
                return acc
            },
            {}
        )

        if (crudName === 'user' || crudName === 'customer') {
            if (newFormData.dob) {
                newFormData.dob = newFormData?.dob?.toISOString().substr(0, 10)
            }
        }

        return newFormData
    }

    const onSubmit = async (formData) => {
        /* filter the data to send just the modified fields */
        const changedData = traitProps(formData)

        /* 
            customValidateFields is a custom function whose can be modified 
            by childrens to make possible to validate different collections
        */
        if (customValidateFields && !(await customValidateFields())) {
            toast({
                title: breadcrumbs[path]?.name,
                description: 'Verifique os campos sinalizados',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
            return
        }

        /* 
            customOnSubmit is a custom function whose can be modified 
            by childrens to make possible to update different collections
        */
        if (customOnSubmit) {
            if (customOnSubmit(changedData)) {
                return
            }
        }

        receiver.emit(`crud:${crudName}:update`, {
            ...data,
            ...changedData,
        })
    }

    return (
        <Flex height="max-content" p={4} gap={4} direction="column" w="100%">
            <Breadcrumb page={path} />
            <Flex
                backgroundColor="white"
                w="100%"
                maxW={['100%', '100%', '723px', '933px', '1128px']}
                marginX="auto"
                borderRadius="2xl"
                p={4}
                direction="column"
                gap={4}
                as="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <VStack
                    spacing={4}
                    w="100%"
                    align="flex-start"
                    overflowY="scroll"
                    maxH={[
                        'calc(100vh - 260px)',
                        'calc(100vh - 260px)',
                        'calc(100vh - 206px)',
                    ]}
                >
                    <CrudEditSwitch
                        crudName={crudName}
                        data={data}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        setDefaultValues={reset}
                        setCustomOnSubmit={setCustomOnSubmit}
                        setCustomValidateFields={setCustomValidateFields}
                    />
                </VStack>
                <Divider />
                <Footer isSubmitting={isSubmitting} />
            </Flex>
        </Flex>
    )
}
