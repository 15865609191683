import React, { useMemo } from 'react'

import { collectionSwitch } from './Fields/collectionSwitch'

export function CrudEditSwitch({
    crudName,
    data,
    errors,
    register,
    setValue,
    setCustomOnSubmit,
    setCustomValidateFields,
    setDefaultValues,
}) {
    const Component = useMemo(() => collectionSwitch[crudName], [crudName])

    return (
        <Component
            data={data}
            errors={errors}
            register={register}
            setValue={setValue}
            setCustomOnSubmit={setCustomOnSubmit}
            setCustomValidateFields={setCustomValidateFields}
            setDefaultValues={setDefaultValues}
        />
    )
}
