import React, { useContext } from 'react'
import {
    Button,
    Center,
    Divider,
    Flex,
    HStack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { GoodVariationContext } from '../../../../contexts/goodVariation/GoodVariationContext'

export function GoodVariationAttributes() {
    const { attributes, category, refreshAttributesAndGood } =
        useContext(GoodVariationContext)

    const onAttributeChange = (actualAttr) => {
        refreshAttributesAndGood(actualAttr)
    }

    return (
        <Flex
            background="white"
            direction="column"
            borderRadius="2xl"
            marginX="auto"
            p={4}
        >
            <Text
                fontSize="3xl"
                width="100%"
                textAlign="start"
                borderBottom="1px"
                borderBottomColor="whiteAlpha.900"
            >
                Atributos
            </Text>

            <Center h={4}>
                <Divider />
            </Center>

            <Text
                fontSize="2xl"
                width="100%"
                textAlign="start"
                borderBottom="1px"
                borderBottomColor="whiteAlpha.900"
            >
                {category?.name}
            </Text>

            <VStack minW="240px" maxW="280px">
                {attributes.map(({ attrKey, attrValues }) => (
                    <VStack key={attrKey._id} alignItems="flex-start" w="100%">
                        <Text>{attrKey.name}</Text>
                        <HStack>
                            {attrValues.map((attrValue) => (
                                <Button
                                    key={attrValue._id}
                                    onClick={() =>
                                        onAttributeChange({
                                            key: attrKey._id,
                                            value: attrValue._id,
                                        })
                                    }
                                    colorScheme={
                                        attrValue.selected
                                            ? 'orange'
                                            : attrValue.isSelectable
                                            ? 'green'
                                            : 'gray'
                                    }
                                >
                                    {attrValue.name}
                                </Button>
                            ))}
                        </HStack>
                    </VStack>
                ))}
            </VStack>
        </Flex>
    )
}
