import React, { useEffect } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { SelectedFilters } from './SelectedFilters'
import { ListOfResults } from './ListOfResults'

export function FilterResults({ filteredBy, variation }) {
    return (
        <Flex w="100%" direction="column" marginTop={{ base: -4, xl: 0 }}>
            {filteredBy && (
                <Text fontSize="2xl">
                    Produtos resultantes da busca por{' '}
                    <Text as="span" color="orange">
                        {filteredBy}
                    </Text>
                </Text>
            )}

            {variation && <SelectedFilters variation={variation} />}
            <ListOfResults />
        </Flex>
    )
}
