export const goodOptions = [
    {
        name: 'Categorias',
        path: 'workspace-categories',
        props: { path: 'workspace-categories', crudName: 'category' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 1) 100%)`,
        icon: 'tags',
    },
    {
        name: 'Atributos',
        path: 'workspace-attributes',
        props: { path: 'workspace-attributes', crudName: 'attribute_keys' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 58%, 0.8) 100%)`,
        icon: 'tasks',
    },
    {
        name: 'Produtos',
        path: 'workspace-goods',
        props: { path: 'workspace-goods', crudName: 'good' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 58%, 0.8) 100%)`,
        icon: 'archive',
    },
    {
        name: 'Variações de produtos',
        path: 'workspace-good-variations',
        props: {
            path: 'workspace-good-variations',
            crudName: 'good_variation',
        },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 58%, 0.8) 100%)`,
        icon: 'archive',
    },
]

export const expenseOptions = [
    {
        name: 'Tipos',
        path: 'workspace-expense-types',
        props: { path: 'workspace-expense-types', crudName: 'expense_type' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 1) 100%)`,
        icon: 'tags',
    },
    {
        name: 'Despesas',
        path: 'workspace-expenses',
        props: { path: 'workspace-expenses', crudName: 'expense' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 0.6) 100%)`,
        icon: 'x',
    },
]

export const tradeOptions = [
    {
        name: 'Motivos',
        path: 'workspace-trade-reasons',
        props: { path: 'workspace-trade-reasons', crudName: 'exchange_reason' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 1) 100%)`,
        icon: 'question',
    },
    {
        name: 'Trocas',
        path: 'workspace-trades',
        props: { path: 'workspace-trades', crudName: 'exchange' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 0.6) 100%)`,
        icon: 'exchange',
    },
]

export const devolutionOptions = [
    {
        name: 'Motivos',
        path: 'workspace-devolution-reasons',
        props: {
            path: 'workspace-devolution-reasons',
            crudName: 'refund_reason',
        },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 1) 100%)`,
        icon: 'question',
    },
    {
        name: 'Devoluções',
        path: 'workspace-devolutions',
        props: { path: 'workspace-devolutions', crudName: 'refund' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 0.6) 100%)`,
        icon: 'exchange',
    },
]

export const cancelOptions = [
    {
        name: 'Motivos',
        path: 'workspace-sales-cancel-reasons',
        props: {
            path: 'workspace-sales-cancel-reasons',
            crudName: 'cancellation_reason',
        },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 1) 100%)`,
        icon: 'question',
    },
    {
        name: 'Cancelamentos',
        path: 'workspace-sales-cancels',
        props: { path: 'workspace-sales-cancels', crudName: 'cancellation' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 0.6) 100%)`,
        icon: 'exchange',
    },
]

export const options = [
    {
        name: 'Usuários',
        path: 'workspace-users',
        props: { path: 'workspace-users', crudName: 'user' },
        color: 'linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 67%, 1) 100%)',
        icon: 'user',
    },
    {
        name: 'Clientes',
        path: 'workspace-customers',
        props: { path: 'workspace-customers', crudName: 'customer' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 67%, 0.8) 100%)`,
        icon: 'user plus',
    },
    {
        name: 'Marcas dos produtos',
        path: 'workspace-brands',
        props: { path: 'workspace-brands', crudName: 'brand' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 67%, 0.6) 100%)`,
        icon: 'bookmark',
    },
    {
        name: 'Produto',
        path: 'workspace-good',
        props: { options: goodOptions, path: 'workspace-good' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 58%, 0.8) 100%)`,
        icon: 'archive',
    },
    {
        name: 'Pagamentos',
        path: 'workspace-payments',
        props: { path: 'workspace-payments', crudName: 'payment' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 58%, 0.6) 100%)`,
        icon: 'money bill alternate',
    },
    {
        name: 'Vendas',
        path: 'workspace-sales',
        props: { path: 'workspace-sales', crudName: 'sale' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(339, 78%, 43%, 1) 100%)`,
        icon: 'check',
    },
    {
        name: 'Nota Fiscal',
        path: 'workspace-nfe',
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(339, 78%, 43%, 1) 100%)`,
        icon: 'check',
    },
    {
        name: 'Crediario',
        path: 'workspace-installments',
        props: { path: 'workspace-installments', crudName: 'installment' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(0, 100%, 58%, 0.4) 100%)`,
        icon: 'calendar check',
        disabled: true,
    },
    {
        name: 'Contas a pagar',
        path: 'workspaceBillsToPay',
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 0.6) 100%)`,
        icon: 'clipboard list',
        disabled: true,
    },
    {
        name: 'Despesas',
        path: 'workspace-expense',
        props: { options: expenseOptions, path: 'workspace-expense' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(339, 78%, 43%, 0.8) 100%)`,
        icon: 'times',
        disabled: true,
    },
    {
        name: 'Troca de produto',
        path: 'workspace-trade',
        props: { options: tradeOptions, path: 'workspace-trade' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(339, 78%, 43%, 0.6) 100%)`,
        icon: 'exchange',
        disabled: true,
    },
    {
        name: 'Devolucao de dinheiro',
        path: 'workspace-money-devolution',
        props: {
            options: devolutionOptions,
            path: 'workspace-money-devolution',
        },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(339, 78%, 43%, 0.4) 100%)`,
        icon: 'redo',
        disabled: true,
    },
    {
        name: 'Cancelamento de vendas',
        path: 'workspace-sales-cancel',
        props: { options: cancelOptions, path: 'workspace-sales-cancel' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 1) 100%)`,
        icon: 'thumbs down',
        disabled: true,
    },
    {
        name: 'Fornecedores',
        path: 'workspaceSuppliers',
        props: { path: 'workspaceSuppliers', crudName: 'supplier' },
        color: `linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(21, 79%, 43%, 0.8) 100%)`,
        icon: 'warehouse',
        disabled: true,
    },
]
