import React, { useContext } from 'react'
import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel'
import { GoodVariationContext } from '../../../../contexts/goodVariation/GoodVariationContext'

export function Carousel() {
    const { goodVariation } = useContext(GoodVariationContext)

    return (
        <ReactResponsiveCarousel
            showThumbs={false}
            showArrows
            showStatus={false}
        >
            {goodVariation.pictures.map((pic) => (
                <img
                    key={pic._id}
                    src={pic.path}
                    alt={pic._id}
                    style={{ height: '300px', width: 'auto' }}
                />
            ))}
        </ReactResponsiveCarousel>
    )
}
