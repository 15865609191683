import React, { memo } from 'react'

import { Box, VStack } from '@chakra-ui/react'
import { collectionSwitch } from './collectionSwitch'

function FieldsListBase({ data, crudName }) {
    const Component = collectionSwitch[crudName]

    return (
        <Box width="85%">
            <VStack spacing={4} w="100%" align="flex-start">
                <Component {...data} />
            </VStack>
        </Box>
    )
}

export const FieldsList = memo(FieldsListBase)
