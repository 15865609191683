import React, { useContext, useEffect, useState } from 'react'

import { Text, VStack } from '@chakra-ui/react'

import { SocketContext } from '../../../../../contexts/socket/SocketContext'
import { Select } from '../../../../Form/Select'

export function Attributes({ data, setCustomOnSubmit, setValue }) {
    const { receiver } = useContext(SocketContext)

    const [attributes, setAttributes] = useState([])
    const [selectedAttributesValues, setSelectedAttributesValues] = useState({})
    const [initialAttributes, setInitialAttributes] = useState({})

    const [avErrors, setAvErrors] = useState({})

    const [goods, setGoods] = useState([])
    const [selectedGood, setSelectedGood] = useState('')

    useEffect(() => {
        if (goods.length > 0) {
            setSelectedGood(data._gdId)
        }
    }, [goods])

    useEffect(() => {
        if (selectedGood !== '') {
            receiver.emit('good:get:attributes', { gdId: selectedGood })
            setValue('_gdId', selectedGood, { shouldDirty: true })
        }
    }, [selectedGood])

    useEffect(() => {
        receiver.emit('good_variation:get:attributes', { gdvId: data._id })

        receiver.emit('crud:good:find', {
            page: 0,
            size: Number.MAX_VALUE,
        })
    }, [])

    useEffect(() => {
        receiver.on('good_variation:get:attributes:ok', (response) => {
            setInitialAttributes(response)
        })

        receiver.on('crud:good:find:ok', (response) => {
            setGoods(response.result || [])
        })

        receiver.on('good:get:attributes:ok', (response) => {
            setAttributes(response)

            const newSelectedAttributesValues = {}

            response?.forEach((attribute) => {
                if (selectedGood === data._gdId) {
                    newSelectedAttributesValues[attribute._id] =
                        initialAttributes[attribute._id]
                            ? initialAttributes[attribute._id]._avId
                            : attribute.values?.[0]?._id
                } else {
                    newSelectedAttributesValues[attribute._id] =
                        attribute.values?.[0]?._id
                }
            })

            setSelectedAttributesValues(newSelectedAttributesValues)
        })

        return () => {
            receiver.off('good_variation:get:attributes:ok')
            receiver.off('good:get:attributes:ok')
            receiver.off('crud:good:find:ok')
        }
    }, [selectedGood, initialAttributes])

    const updateGoodAttributes = () => {
        receiver.emit('attribute:update_by_good_variation', {
            _coId: data._coId,
            _gdvId: data._id,
            attributesValues: Object.values(selectedAttributesValues),
        })
    }

    const customSubmit = () => {
        updateGoodAttributes()

        return false
    }

    /* effect to update customSubmit */
    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })
    }, [selectedAttributesValues, avErrors])

    const handleAttributeValueChange = (e, akId) => {
        setSelectedAttributesValues({
            ...selectedAttributesValues,
            [akId]: e.target.value,
        })
    }

    return (
        <VStack spacing={4} w="100%" align="flex-start" px={1}>
            <Select
                id="_gdId"
                name="_gdId"
                label="Produto"
                options={goods}
                value={selectedGood}
                onChange={(e) => {
                    setAttributes([])
                    setAvErrors({})
                    setSelectedGood(e.target.value)
                }}
            />

            <Text fontSize="2xl">Atributos</Text>

            {goods
                .find((good) => good._id === selectedGood)
                ?._akIds.map((akId) => (
                    <Select
                        key={akId}
                        id={akId}
                        name={akId}
                        error={avErrors?.[akId]}
                        value={selectedAttributesValues?.[akId]}
                        onChange={(e) => handleAttributeValueChange(e, akId)}
                        label={
                            attributes.find((attr) => attr._id === akId)?.name
                        }
                        options={
                            attributes.find((attr) => attr._id === akId)
                                ?.values || []
                        }
                    />
                ))}
        </VStack>
    )
}
