import React, { useContext } from 'react'

import {
    Checkbox,
    Flex,
    IconButton,
    SimpleGrid,
    Skeleton,
    Text,
} from '@chakra-ui/react'
import { EditIcon, InfoOutlineIcon } from '@chakra-ui/icons'

import { Link } from 'react-router-dom'
import { FieldsList } from '../FieldsList'
import { Empty } from '../Empty'
import { crudViewPages, crudEditPages } from '../../../../pages/Crud/crudPages'

import { SocketContext } from '../../../../contexts/socket/SocketContext'

export function Grid({
    data,
    checkboxes,
    checkboxesSet,
    crudName,
    loading,
    size,
}) {
    const { user } = useContext(SocketContext)

    const handleToggleCheckbox = (e) => {
        checkboxesSet((checks) => {
            const cbs = Object.entries(checks).reduce(
                (acc, [k, { key, checked }]) => {
                    acc[k] = {
                        key,
                        checked: e.target.value === key ? !checked : checked,
                    }
                    return acc
                },
                {}
            )

            return cbs
        })
    }

    if (loading) {
        return (
            <SimpleGrid
                w="100%"
                minChildWidth="280px"
                spacing={4}
                p={2}
                overflow="auto"
                maxH={[
                    'calc(100vh - 352px)',
                    'calc(100vh - 352px)',
                    'calc(100vh - 296px)',
                ]}
            >
                {Array(parseInt(size, 10))
                    .fill(0)
                    .map((k, index) => (
                        <Skeleton
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            borderRadius="2xl"
                            h="250px"
                            w="100%"
                        />
                    ))}
            </SimpleGrid>
        )
    }

    return data.length > 0 ? (
        <SimpleGrid
            w="100%"
            minChildWidth="280px"
            spacing={4}
            p={2}
            overflow="auto"
            maxH={[
                'calc(100vh - 352px)',
                'calc(100vh - 352px)',
                'calc(100vh - 296px)',
            ]}
        >
            {data.map((d) => (
                <Flex
                    key={d._id}
                    position="relative"
                    w="100%"
                    minH="150px"
                    boxShadow="base"
                    borderRadius="2xl"
                    p={4}
                >
                    <FieldsList data={d} crudName={crudName} />
                    {user.role === '0' && (
                        <Checkbox
                            key={d._id}
                            position="absolute"
                            top={4}
                            right={4}
                            colorScheme="blackAlpha"
                            size="lg"
                            borderColor="blackAlpha"
                            value={checkboxes[d._id]?.key}
                            isChecked={checkboxes[d._id]?.checked}
                            onChange={handleToggleCheckbox}
                        />
                    )}

                    <Link
                        to={{
                            pathname: 'view',
                            state: {
                                data: d,
                                crudName,
                                path: crudViewPages[crudName],
                            },
                        }}
                    >
                        <IconButton
                            icon={<InfoOutlineIcon fontSize="2xl" />}
                            position="absolute"
                            right={4}
                            bottom={16}
                            colorScheme="orange"
                        />
                    </Link>

                    {crudEditPages[crudName] !== undefined ? (
                        <Link
                            to={{
                                pathname: 'edit',
                                state: {
                                    data: d,
                                    crudName,
                                    path: crudEditPages[crudName],
                                },
                            }}
                        >
                            <IconButton
                                icon={<EditIcon fontSize="2xl" />}
                                position="absolute"
                                right={4}
                                bottom={4}
                                colorScheme="orange"
                            />
                        </Link>
                    ) : (
                        <IconButton
                            icon={<EditIcon fontSize="2xl" />}
                            position="absolute"
                            right={4}
                            bottom={4}
                            colorScheme="orange"
                            disabled
                        />
                    )}
                </Flex>
            ))}
        </SimpleGrid>
    ) : (
        <Empty />
    )
}
