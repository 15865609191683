import React, { useContext, useEffect } from 'react'
import {
    Button,
    Divider,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from '@chakra-ui/react'

import { differenceInDays } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { SaleContext } from '../../../contexts/sale/SaleContext'

const dateFormatter = new Intl.DateTimeFormat('pt-BR')

export function CertificateModal() {
    const { branches, isSaleTest } = useContext(SaleContext)
    const navigate = useHistory()

    const {
        isOpen: certificateIsOpen,
        onOpen: certificateOnOpen,
        onClose: certificateOnClose,
    } = useDisclosure()

    const {
        isOpen: certificatedNeeded,
        onOpen: certificatedNeededOnOpen,
        onClose: certificatedNeededOnClose,
    } = useDisclosure()

    useEffect(() => {
        const certificate = branches.find(
            (branch) => branch.nfeio?._certificateId !== null
        )
        if (!isSaleTest && certificate) {
            if (
                differenceInDays(
                    new Date(certificate?.nfeio?.certificateValidUntil),
                    new Date()
                ) < 30
            ) {
                certificateOnOpen()
            }
        } else if (!isSaleTest) {
            certificatedNeededOnOpen()
        }
    }, [branches, isSaleTest])

    return (
        <>
            <Modal
                isOpen={certificateIsOpen}
                onClose={certificateOnClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent backgroundColor="yellow.600" margin={4}>
                    <ModalHeader backgroundColor="yellow.600" color="white">
                        Certificado
                    </ModalHeader>

                    <ModalBody backgroundColor="yellow.600" marginBottom={8}>
                        <Flex w="100%" direction="column" gap={4}>
                            <Text color="white">
                                Fique atento ao tempo de expiração dos seus
                                certificados digitais:
                            </Text>

                            <Divider />

                            {branches.map((branch) => (
                                <Flex
                                    direction="row"
                                    justify="space-between"
                                    key={branch._id}
                                >
                                    <Text color="white">{branch.name}</Text>
                                    <Text color="white">
                                        Expira dia:
                                        {`  ${
                                            dateFormatter.format(
                                                new Date(
                                                    branch?.nfeio.certificateValidUntil
                                                )
                                            ) || '--/--/--'
                                        }`}
                                    </Text>
                                </Flex>
                            ))}
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="blackAlpha"
                            onClick={certificateOnClose}
                        >
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={certificatedNeeded}
                onClose={certificatedNeededOnClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent backgroundColor="yellow.600" margin={4}>
                    <ModalHeader backgroundColor="yellow.600" color="white">
                        Certificado
                    </ModalHeader>

                    <ModalBody backgroundColor="yellow.600" marginBottom={8}>
                        <Flex w="100%" direction="column" gap={4}>
                            <Text color="white">
                                Para a venda de um produto gerador de nota
                                fiscal é necessário que haja pelo menos uma
                                filial com o certificado digital cadastrado
                            </Text>
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="blackAlpha"
                            onClick={() => {
                                certificatedNeededOnClose()
                                navigate.push('/main')
                            }}
                        >
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
