import React, { forwardRef } from 'react'

import {
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input as ChakraInput,
    InputGroup,
} from '@chakra-ui/react'

function InputBase({ name, label, error, type, addon, ...rest }, ref) {
    return (
        <FormControl isInvalid={!!error}>
            {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

            {type === 'checkbox' ? (
                <Checkbox name={name} id={name} size="lg" ref={ref} {...rest} />
            ) : (
                <InputGroup>
                    <ChakraInput
                        name={name}
                        id={name}
                        ref={ref}
                        type={type}
                        size="lg"
                        {...rest}
                    />
                    {addon}
                </InputGroup>
            )}

            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
    )
}

export const Input = forwardRef(InputBase)
