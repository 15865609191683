export const searchByFields = {
    user: {
        label: 'Buscar Usuário',
        keys: ['firstName', 'lastName', 'icNumber'],
        placeholder: 'Nome, CPF',
    },
    customer: {
        label: 'Buscar Cliente',
        keys: ['firstName', 'lastName', 'icNumber'],
        placeholder: 'Nome, CPF',
    },
    brand: {
        label: 'Buscar Marca',
        keys: ['name'],
        placeholder: 'Nome',
    },
    category: {
        label: 'Buscar Categoria',
        keys: ['name'],
        placeholder: 'Nome',
    },
    attribute_key: {
        label: 'Buscar Atributo',
        keys: ['name'],
        placeholder: 'Nome',
    },
    good: {
        label: 'Buscar Produto',
        keys: ['name'],
        placeholder: 'Nome',
    },
    good_variation: {
        label: 'Buscar Variação de Produto',
        keys: ['reference'],
        placeholder: 'Referência',
    },
    supplier: {
        label: 'Buscar Fornecedor',
        keys: ['name'],
        placeholder: 'Nome',
    },
    sale: { label: 'Buscar Venda', keys: ['_id'], placeholder: 'Id' },
    payment: { label: 'Buscar Pagamento', keys: ['_id'], placeholder: 'Id' },
    installment: {
        label: 'Buscar Crediário',
        keys: ['_id'],
        placeholder: 'Id',
    },
}
