import React from 'react'

import { Attribute } from './attribute'
import { Brand } from './brand'
import { Cancellation } from './cancellation'
import { CancellationReason } from './cancellationReason'
import { Category } from './category'
import { Customer } from './customer'
import { Exchange } from './exchange'
import { ExchangeReason } from './exchangeReason'
import { Expense } from './expense'
import { ExpenseType } from './expenseType'
import { Good } from './good'
import { GoodVariation } from './goodVariation'
import { Installment } from './installment'
import { InstallmentDue } from './installmentDue'
import { Payment } from './payment'
import { Refund } from './refund'
import { RefundReason } from './refundReason'
import { Sale } from './sale'
import { Supplier } from './supplier'
import { User } from './user'

export const collectionSwitch = {
    user: (props) => <User {...props} />,
    customer: (props) => <Customer {...props} />,
    brand: (props) => <Brand {...props} />,
    category: (props) => <Category {...props} />,
    attribute_key: (props) => <Attribute {...props} />,
    good: (props) => <Good {...props} />,
    good_variation: (props) => <GoodVariation {...props} />,
    payment: (props) => <Payment {...props} />,
    installment: (props) => <Installment {...props} />,
    installment_due: (props) => <InstallmentDue {...props} />,
    sale: (props) => <Sale {...props} />,
    expense_type: (props) => <ExpenseType {...props} />,
    expense: (props) => <Expense {...props} />,
    exchange_reason: (props) => <ExchangeReason {...props} />,
    exchange: (props) => <Exchange {...props} />,
    refund_reason: (props) => <RefundReason {...props} />,
    refund: (props) => <Refund {...props} />,
    cancellation_reason: (props) => <CancellationReason {...props} />,
    cancellation: (props) => <Cancellation {...props} />,
    supplier: (props) => <Supplier {...props} />,
}
