import React from 'react'

import { Input } from '../../../Form/Input'

export function InstallmentDue({ errors, register }) {
    return (
        <Input
            name="date"
            error={errors.date}
            label="Data"
            type="date"
            {...register('date')}
        />
    )
}
