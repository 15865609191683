import React from 'react'

import { Input } from '../../../Form/Input'

export function CancellationReason({ errors, register }) {
    return (
        <Input
            name="name"
            error={errors.name}
            label="Nome"
            type="text"
            {...register('name')}
        />
    )
}
