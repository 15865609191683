import React, { useContext, useEffect, useState } from 'react'
import { Divider, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { SocketContext } from '../../../../contexts/socket/SocketContext'

import { FieldLine } from '../../FieldLine'

export function Sale({ _id, _brId, _custId, _usrId, timestamp }) {
    const { receiver } = useContext(SocketContext)

    const [branch, setBranch] = useState({
        name: '',
    })

    const [customer, setCustomer] = useState({
        firstName: '',
        lastName: '',
    })

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
    })

    const [goods, setGoods] = useState([])

    useEffect(() => {
        receiver.on('crud:branch:find:ok', (response) => {
            setBranch(response)
        })
        receiver.on('crud:customer:find:ok', (response) => {
            setCustomer(response)
        })
        receiver.on('crud:user:find:ok', (response) => {
            setUser(response)
        })
        receiver.on('crud:sale_item:find:ok', (response) => {
            setGoods(response)
        })

        receiver.emit('crud:branch:find', { _id: _brId })
        receiver.emit('crud:customer:find', { _id: _custId })
        receiver.emit('crud:user:find', { _id: _usrId })
        receiver.emit('crud:sale_item:find', { _slId: _id })

        return () => {
            receiver.off('crud:branch:find:ok')
            receiver.off('crud:customer:find:ok')
            receiver.off('crud:user:find:ok')
            receiver.off('crud:sale_item:find:ok')
        }
    }, [])

    return (
        <>
            <Text fontSize="2xl">Informações</Text>
            <FieldLine title="Filial" value={branch.name} />
            <FieldLine
                title="Cliente"
                value={`${customer.firstName} ${customer.lastName}`}
            />
            <FieldLine
                title="Vendedor"
                value={`${user.firstName} ${user.lastName}`}
            />
            <FieldLine
                title="Data"
                value={`${new Intl.DateTimeFormat('pt-BR').format(timestamp)}`}
            />
            <Divider />
            <Text fontSize="2xl">Produtos</Text>
            <SimpleGrid
                w="100%"
                minChildWidth="280px"
                spacing={4}
                p={2}
                overflow="auto"
                maxH="calc(100vh - 346px)"
            >
                {goods.map((good) => (
                    <Flex
                        key={good._id}
                        position="relative"
                        direction="column"
                        gap={4}
                        w="100%"
                        minH="150px"
                        boxShadow="base"
                        borderRadius="2xl"
                        p={4}
                    >
                        <FieldLine title="Nome" value={`${good.name}`} />
                        <FieldLine
                            title="Descrição"
                            value={`${good.description}`}
                        />
                        <FieldLine title="Quantidade" value={`${good.count}`} />
                    </Flex>
                ))}
            </SimpleGrid>
        </>
    )
}
