import React, { useCallback, useContext } from 'react'
import { Checkbox, Divider, Flex, Stack, Text } from '@chakra-ui/react'
import { FilterContext } from '../../../../contexts/filter/FilterContext'

export function Attribute({ attribute, options }) {
    const { filters, filtersSet } = useContext(FilterContext)

    const onChange = useCallback(
        (e, option, optId) => {
            if (e.target.checked) {
                const newFilters = { ...filters }
                newFilters[optId] = option
                filtersSet(newFilters)
            } else {
                const newFilters = { ...filters }
                delete newFilters[optId]
                filtersSet(newFilters)
            }
        },
        [filters, filtersSet]
    )

    return (
        <Flex direction="column">
            <Text fontSize="medium" color="orange.500" fontWeight="bold">
                {attribute}
            </Text>
            <Stack ml={4}>
                {Object.entries(options).map(([optId, option]) => (
                    <Checkbox
                        key={optId}
                        isChecked={!!filters[optId]}
                        onChange={(e) => onChange(e, option, optId)}
                        colorScheme="orange"
                        size="lg"
                    >
                        <Text fontSize="medium" color="blackAlpha.700">
                            {option.name}
                        </Text>
                    </Checkbox>
                ))}
            </Stack>
        </Flex>
    )
}
