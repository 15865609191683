import React, { useContext } from 'react'

import { Flex } from '@chakra-ui/react'

import { AttributeFilterBox } from '../../../components/Search/AttributeFilterBox'
import { FilterResults } from '../../../components/Search/FilterResults'

export function Content() {
    return (
        <Flex
            flex="1"
            direction="column"
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            gap={4}
            h="max-content"
        >
            <Flex direction={{ base: 'column', xl: 'row' }} gap={4}>
                <AttributeFilterBox />
                <FilterResults variation="attribute" />
            </Flex>
        </Flex>
    )
}
