import React from 'react'

import { Flex, Text } from '@chakra-ui/react'

export function FieldLine({ title, value }) {
    return (
        <Flex direction="column" w="100%">
            <Text fontWeight="bold">{title}</Text>
            <Text>{value}</Text>
        </Flex>
    )
}
