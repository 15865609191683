import React from 'react'

import { Flex, Icon, Text } from '@chakra-ui/react'
import { Icon as IconSemantic } from 'semantic-ui-react'

export function SalesAnalyticReport() {
    return (
        <Flex
            as="button"
            onClick={() => {}}
            direction="column"
            justifyContent="space-between"
            background="linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(339, 78%, 43%, 1) 100%)"
            height="150px"
            borderRadius="2xl"
            p={4}
            transition="0.5s filter"
            _hover={{
                filter: 'brightness(0.85)',
            }}
            disabled
            opacity={0.5}
            cursor="not-allowed"
        >
            <Text fontSize="2xl" color="white" align="start">
                Relatório Analítico
            </Text>
            <Icon
                as={IconSemantic}
                name="file"
                size="big"
                alignSelf="end"
                color="white"
            />
        </Flex>
    )
}
