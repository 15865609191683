import React, { useState, useEffect } from 'react'

import { Checkbox, Text, VStack, Divider } from '@chakra-ui/react'
import { Input } from '../../../../Form/Input'

export function NfeFields({ register, setValue }) {
    const [saleTest, setSaleTest] = useState(false)

    useEffect(() => {
        setValue('saleTest', false, {
            shouldDirty: true,
        })
    }, [])

    return (
        <VStack spacing={4} w="100%" alignItems="flex-start" px={2}>
            <Divider />
            <Text fontSize="2xl">Informações para geração de nota fiscal</Text>

            <Checkbox
                isChecked={saleTest}
                onChange={(e) => {
                    setSaleTest(e.target.checked)
                    setValue('saleTest', e.target.checked, {
                        shouldDirty: true,
                    })
                }}
            >
                Produto para venda teste
            </Checkbox>

            {!saleTest && (
                <>
                    <Input
                        name="nfe.code"
                        label="Código"
                        type="text"
                        {...register('nfe.code')}
                    />
                    <Input
                        name="nfe.cfop"
                        label="CFOP"
                        type="number"
                        step="1"
                        {...register('nfe.cfop')}
                    />
                    <Input
                        name="nfe.ncm"
                        label="Código NCM"
                        type="text"
                        {...register('nfe.ncm')}
                    />
                    <Input
                        name="nfe.codeGTIN"
                        label="Código GTIN"
                        type="text"
                        {...register('nfe.codeGTIN')}
                    />
                    <Input
                        name="nfe.cest"
                        label="CEST - Código especificador da substituição tributária"
                        type="text"
                        {...register('nfe.cest')}
                    />
                    <Input
                        name="nfe.description"
                        label="Descrição"
                        type="text"
                        {...register('nfe.description')}
                    />
                    <VStack w="100%" alignItems="start" pt={4}>
                        <Text fontWeight="bold">Taxa</Text>

                        <Input
                            name="nfe.tax.totalTax"
                            label="Valor aproximado total de tributos federais, estaduais e municipais"
                            type="number"
                            step="0.01"
                            {...register('nfe.tax.totalTax')}
                        />

                        <VStack w="100%" alignItems="start" pt={4}>
                            <Text fontWeight="bold">ICMS</Text>
                            <Input
                                name="nfe.tax.icms.csosn"
                                label="(CSOSN) Código de Situação da Operação no Simples Nacional"
                                type="text"
                                {...register('nfe.tax.icms.csosn')}
                            />
                            <Input
                                name="nfe.tax.icms.cst"
                                label="Tributação do ICMS"
                                type="text"
                                {...register('nfe.tax.icms.cst')}
                            />
                            <Input
                                name="nfe.tax.icms.origin"
                                label="Origem da mercadoria"
                                type="text"
                                {...register('nfe.tax.icms.origin')}
                            />
                        </VStack>

                        <VStack w="100%" alignItems="start" pt={4}>
                            <Text fontWeight="bold">PIS</Text>

                            <Input
                                name="nfe.tax.pis.cst"
                                label="Código de Situação Tributária do PIS"
                                type="text"
                                {...register('nfe.tax.pis.cst')}
                            />
                        </VStack>

                        <VStack w="100%" alignItems="start" pt={4}>
                            <Text fontWeight="bold">Cofins</Text>

                            <Input
                                name="nfe.tax.cofins.cst"
                                label="Código de Situação Tributária da COFINS"
                                type="text"
                                {...register('nfe.tax.cofins.cst')}
                            />
                        </VStack>
                    </VStack>
                </>
            )}
        </VStack>
    )
}
