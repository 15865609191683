import { Text } from '@chakra-ui/react'
import React from 'react'
import { paymentTypes } from '../../../../utils/enums'

import { FieldLine } from '../../FieldLine'

export function Payment({ cash, discount, pt, obs, tax, total, _slId }) {
    return (
        <>
            <Text fontSize="2xl">Informações</Text>
            <FieldLine title="Dinheiro" value={`${cash}`} />
            <FieldLine title="Desconto" value={`${discount}`} />
            <FieldLine
                title="Tipo de pagamento"
                value={`${paymentTypes[pt]}`}
            />
            {obs && <FieldLine title="Observação" value={obs} />}
            <FieldLine title="Taxa" value={`${tax}`} />
            <FieldLine title="Total" value={`${total}`} />
            <FieldLine title="Id da venda" value={`${_slId}`} />
        </>
    )
}
