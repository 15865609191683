import React from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Flex, Text } from '@chakra-ui/react'

import { Link } from 'react-router-dom'

import { breadcrumbs } from './breadcrumbs'

export function Breadcrumb({ page }) {
    return (
        <Flex
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            flexWrap="wrap"
            align="center"
        >
            <Link to="/main">
                <Text whiteSpace="nowrap" wordBreak="keep-all" cursor="pointer">
                    StoreView
                </Text>
            </Link>

            <ChevronRightIcon />

            {breadcrumbs[page].pages.map((pg) => (
                <Flex key={pg}>
                    <Link to={`/${pg}`}>
                        <Text
                            whiteSpace="nowrap"
                            wordBreak="keep-all"
                            cursor="pointer"
                        >
                            {breadcrumbs[pg].name}
                        </Text>
                    </Link>
                    <ChevronRightIcon alignSelf="center" />
                </Flex>
            ))}

            <Text
                whiteSpace="nowrap"
                wordBreak="keep-all"
                color="orange.400"
                cursor="pointer"
            >
                {breadcrumbs[page].name}
            </Text>
        </Flex>
    )
}
