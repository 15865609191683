import React from 'react'

import { Cart } from './pages/Cart'
import { CrudEdit } from './pages/Crud/Edit'
import { CrudInsert } from './pages/Crud/Insert'
import { CrudList } from './pages/Crud/List'
import { CrudView } from './pages/Crud/View'
import { CustomerSelect } from './pages/CustomerSelect'
import { FinishSale } from './pages/FinishSale'
import { Loader } from './pages/Loader'
import { Login } from './pages/Login'
import { GoodVariation } from './pages/GoodVariation'
import { Search } from './pages/Search'
import { SearchByAttribute } from './pages/Search/ByAttribute'
import { SearchByCategory } from './pages/Search/ByCategory'
import { SearchByCode } from './pages/Search/ByCode'
import { SearchByName } from './pages/Search/ByName'
import { ServerDown } from './pages/ServerDown'
import { Workspace } from './pages/Workspace'
import {
    cancelOptions,
    devolutionOptions,
    expenseOptions,
    goodOptions,
    options,
    tradeOptions,
} from './pages/Workspace/options'
import { Nfe } from './pages/Nfe'
import { Main } from './pages/Main'
import { Sales } from './pages/Sales'
import { ReportSales } from './pages/Reports/Sales'

export const publicRoutes = {
    '/': {
        Component: Loader,
    },

    login: {
        Component: Login,
    },

    down: {
        Component: ServerDown,
    },
}

export const privateRoutes = {
    main: {
        Component: (props) => (
            <Workspace {...props} path="workspace" menus={options} />
        ),
    },

    search: {
        Component: Search,
    },

    'search-by-category': {
        Component: SearchByCategory,
    },

    'search-by-attribute': {
        Component: SearchByAttribute,
    },

    'search-by-name': {
        Component: SearchByName,
    },

    'search-by-code': {
        Component: SearchByCode,
    },

    good: {
        Component: GoodVariation,
    },

    cart: {
        Component: Cart,
    },

    'finish-sale': {
        Component: FinishSale,
    },

    'customer-select': {
        Component: CustomerSelect,
    },

    workspace: {
        Component: (props) => (
            <Workspace {...props} path="workspace" menus={options} />
        ),
    },

    'workspace-users': {
        Component: (props) => (
            <CrudList {...props} path="workspace-users" crudName="user" />
        ),
    },

    'workspace-customers': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-customers"
                crudName="customer"
            />
        ),
    },

    'workspace-brands': {
        Component: (props) => (
            <CrudList {...props} path="workspace-brands" crudName="brand" />
        ),
    },

    'workspace-good': {
        Component: (props) => (
            <Workspace {...props} path="workspace-good" menus={goodOptions} />
        ),
    },

    'workspace-good-variations': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-good-variations"
                crudName="good_variation"
            />
        ),
    },

    'workspace-categories': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-categories"
                crudName="category"
            />
        ),
    },

    'workspace-attributes': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-attributes"
                crudName="attribute_key"
            />
        ),
    },

    'workspace-goods': {
        Component: (props) => (
            <CrudList {...props} path="workspace-goods" crudName="good" />
        ),
    },

    'workspace-payments': {
        Component: (props) => (
            <CrudList {...props} path="workspace-payments" crudName="payment" />
        ),
    },

    'workspace-installments': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-installments"
                crudName="installment"
            />
        ),
    },

    'workspace-sales': {
        Component: () => <Sales />,
    },

    'workspace-sales-list': {
        Component: (props) => (
            <CrudList {...props} path="workspace-sales-list" crudName="sale" />
        ),
    },

    'workspace-sales-reports': {
        Component: () => <ReportSales />,
    },

    'workspace-expense': {
        Component: (props) => (
            <Workspace
                {...props}
                path="workspace-expense"
                menus={expenseOptions}
            />
        ),
    },

    'workspace-expense-types': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-expense-types"
                crudName="expense_type"
            />
        ),
    },

    'workspace-expenses': {
        Component: (props) => (
            <CrudList {...props} path="workspace-expenses" crudName="expense" />
        ),
    },

    'workspace-trade': {
        Component: (props) => (
            <Workspace {...props} path="workspace-trade" menus={tradeOptions} />
        ),
    },

    'workspace-trade-reasons': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-trade-reasons"
                crudName="exchange_reason"
            />
        ),
    },

    'workspace-trades': {
        Component: (props) => (
            <CrudList {...props} path="workspace-trades" crudName="exchange" />
        ),
    },

    'workspace-money-devolution': {
        Component: (props) => (
            <Workspace
                {...props}
                path="workspace-money-devolution"
                menus={devolutionOptions}
            />
        ),
    },

    'workspace-devolution-reasons': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-devolution-reasons"
                crudName="refund_reason"
            />
        ),
    },

    'workspace-devolutions': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-devolutions"
                crudName="refund"
            />
        ),
    },

    'workspace-sales-cancel': {
        Component: (props) => (
            <Workspace
                {...props}
                path="workspace-sales-cancel"
                menus={cancelOptions}
            />
        ),
    },

    'workspace-sales-cancel-reasons': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-sales-cancel-reasons"
                crudName="cancellation_reason"
            />
        ),
    },

    'workspace-sales-cancels': {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspace-sales-cancels"
                crudName="cancellation"
            />
        ),
    },

    workspaceSuppliers: {
        Component: (props) => (
            <CrudList
                {...props}
                path="workspaceSuppliers"
                crudName="supplier"
            />
        ),
    },

    view: {
        Component: (props) => <CrudView {...props} />,
    },

    edit: {
        Component: (props) => <CrudEdit {...props} />,
    },

    insert: {
        Component: (props) => <CrudInsert {...props} />,
    },

    'workspace-nfe': {
        Component: () => <Nfe />,
    },
}

export const privateRoutesWorkers = {
    main: {
        Component: Search,
    },

    search: {
        Component: Search,
    },

    'search-by-category': {
        Component: SearchByCategory,
    },

    'search-by-attribute': {
        Component: SearchByAttribute,
    },

    'search-by-name': {
        Component: SearchByName,
    },

    'search-by-code': {
        Component: SearchByCode,
    },

    good: {
        Component: GoodVariation,
    },

    cart: {
        Component: Cart,
    },

    'finish-sale': {
        Component: FinishSale,
    },

    'customer-select': {
        Component: CustomerSelect,
    },
}
