import React from 'react'

import { Avatar, Text } from '@chakra-ui/react'

import { FieldLine } from '../../FieldLine'

export function Customer({
    firstName,
    lastName,
    dob,
    icNumber,
    base64,
    address,
    contact,
}) {
    return (
        <>
            <Text fontSize="2xl">Informações</Text>
            <Avatar src={base64} size="lg" name={`${firstName} ${lastName}`} />
            <FieldLine title="Primeiro nome" value={firstName} />
            <FieldLine title="Último nome" value={lastName} />
            <FieldLine title="Data de Nascimento" value={dob} />
            <FieldLine title="CPF" value={icNumber} />
            <FieldLine title="Rua" value={address?.street} />
            <FieldLine title="Bairro" value={address?.district} />
            <FieldLine title="Número" value={address?.number} />
            <FieldLine title="Cidade" value={address?.city?.name} />
            <FieldLine title="Estado" value={address?.state?.name} />
            <FieldLine
                title="Informações adicionais"
                value={address?.additionalInformation}
            />
            <FieldLine title="CEP" value={address?.zipCode} />
            <FieldLine title="Email" value={contact?.email} />
            <FieldLine title="Número telefone" value={contact?.phoneNumber} />
        </>
    )
}
