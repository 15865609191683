import React from 'react'

import { Flex } from '@chakra-ui/react'

import { FilterContextProvider } from '../../../contexts/filter/FilterContext'
import { Breadcrumb } from '../../../components/Breadcrumb'
import { Content } from './Content'

export function SearchByCategory() {
    return (
        <FilterContextProvider>
            <Flex
                flex="1"
                w="100%"
                direction="column"
                p={4}
                gap={4}
                h="max-content"
            >
                <Breadcrumb page="search-by-category" />
                <Content />
            </Flex>
        </FilterContextProvider>
    )
}
