import { viacep } from './api'

const cepVerify = (cep) => {
    return viacep.get(`/${cep}/json`)
}

const viacepApi = {
    cepVerify,
}

export default viacepApi
