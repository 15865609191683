import React, { useContext } from 'react'
import { Button, Center, Divider, Flex } from '@chakra-ui/react'
import { FilterContext } from '../../../../contexts/filter/FilterContext'
import { Categories } from './Categories'
import { SocketContext } from '../../../../contexts/socket/SocketContext'

export function Filters({ onClose, isMobileUser }) {
    const { receiver } = useContext(SocketContext)
    const { filters } = useContext(FilterContext)

    const onFilterByCategory = () => {
        const selectedCategories = {
            categories: Object.entries(filters).map(([id, filter]) => id),
        }

        receiver.emit('good:find:by:category', selectedCategories)
    }

    return (
        <Flex
            backgroundColor="whiteAlpha.900"
            minW="186px"
            borderRadius="2xl"
            direction="column"
            height="max-content"
            p={!isMobileUser && 4}
        >
            <Categories />
            <Center height="40px">
                <Divider />
            </Center>
            <Button
                colorScheme="orange"
                onClick={() => {
                    if (onClose) {
                        onClose()
                    }
                    onFilterByCategory()
                }}
            >
                Filtrar
            </Button>
        </Flex>
    )
}
