import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import {
    Button,
    Center,
    Divider,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'

import { io } from 'socket.io-client'
import { differenceInDays } from 'date-fns'

export const SocketContext = createContext({})

const dateFormatter = new Intl.DateTimeFormat('pt-BR')

export function SocketContextProvider({ children }) {
    const toast = useToast()
    const [receiver, receiverSet] = useState(undefined)
    const [bearer, setBearer] = useState(undefined)
    const [user, userSet] = useState({ _id: '', name: '', img: undefined })

    const {
        isOpen: reconnectionIsOpen,
        onOpen: reconnectionOnOpen,
        onClose: reconnectionOnClose,
    } = useDisclosure()

    const {
        isOpen: certificateIsOpen,
        onOpen: certificateOnOpen,
        onClose: certificateOnClose,
    } = useDisclosure()

    const [isReconnect, setIsReconnect] = useState(false)

    const [branches, setBranches] = useState([])

    const [plan, setPlan] = useState(0)

    useEffect(() => {
        if (bearer && receiver) {
            receiver.on('crud:company:find:ok', (response) => {
                setPlan(response.plan)
            })

            receiver.emit('crud:company:find', {
                _id: bearer.user._coId,
            })
        }
        return () => {
            receiver?.off('crud:company:find:ok')
        }
    }, [receiver, bearer])

    useEffect(() => {
        if (bearer) {
            userSet(bearer?.user)
        }
    }, [bearer])

    useEffect(() => {
        if (receiver) {
            try {
                receiver.on('crud:branch:find:ok', (response) => {
                    receiver.off('crud:branch:find:ok')

                    setBranches(response.result)

                    if (response.result.length > 0) {
                        const certificate = response.result.find(
                            (branch) => branch.nfeio?._certificateId !== null
                        )

                        if (
                            differenceInDays(
                                new Date(
                                    certificate?.nfeio?.certificateValidUntil
                                ),
                                new Date()
                            ) < 30
                        ) {
                            certificateOnOpen()
                        }
                    }
                })

                receiver.on('client:clients_list', () => {
                    receiver.emit('crud:branch:find', {
                        page: 0,
                        size: Number.MAX_VALUE,
                    })
                })

                receiver.on('connect_error', (err) => {
                    toast({
                        status: 'error',
                        title: 'Conexão',
                        description: 'Erro na conexão',
                    })
                })

                receiver.on('connect', () => {
                    if (isReconnect) {
                        receiver.emit('client:reconnect')
                        reconnectionOnClose()
                        setIsReconnect(false)
                    } else {
                        receiver.emit('client:connect')
                    }
                })

                receiver.on('disconnect', (reason) => {
                    if (reason === 'transport close') {
                        reconnectionOnOpen()
                        setIsReconnect(true)
                    }
                })
            } catch (e) {
                toast({
                    status: 'error',
                    title: 'Erro',
                    description: e,
                })
            }
        }

        return () => {
            receiver?.off('connect_error')
            receiver?.off('connect')
            receiver?.off('client:clients_list')
            receiver?.off('disconnect')
            receiver?.off('crud:branch:find:ok')
        }
    }, [receiver, isReconnect])

    const initSocket = useCallback(
        (token) => {
            if (!receiver && token) {
                const devMode = process.env.REACT_APP_DEV_MODE === 'true'

                const address = devMode
                    ? process.env.REACT_APP_DEV_SERVER_ADDRESS
                    : process.env.REACT_APP_SERVER_ADDRESS

                const port = devMode
                    ? process.env.REACT_APP_DEV_SERVER_PORT
                    : process.env.REACT_APP_SERVER_PORT

                receiverSet(
                    io(`wss://${address}:${port}`, {
                        path: '/prt',
                        transports: ['websocket'],
                        reconnection: true,
                        auth: { token },
                    })
                )
            }
        },
        [receiver]
    )

    const logout = () => {
        receiver.disconnect()
        receiverSet(undefined)
        setBearer(undefined)
        userSet({ _id: '', name: '', img: undefined })
    }

    const value = useMemo(
        () => ({
            initSocket,
            logout,
            receiver,
            bearer,
            user,
            plan,
            setBearer,
            branches,
        }),
        [initSocket, receiver, bearer, user, plan, branches]
    )

    return (
        <SocketContext.Provider value={value}>
            <Modal
                isOpen={reconnectionIsOpen}
                onClose={reconnectionOnClose}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                isCentered
            >
                <ModalOverlay />
                <ModalContent backgroundColor="red.600" margin={4}>
                    <ModalHeader backgroundColor="red.600" color="white">
                        Reconectando
                    </ModalHeader>

                    <ModalBody backgroundColor="red.600" marginBottom={8}>
                        <Center
                            w="100%"
                            display="flex"
                            flexDirection="column"
                            gap={4}
                        >
                            <Text color="white">
                                Conexão com o servidor perdida
                            </Text>
                            <Spinner color="white" />
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={certificateIsOpen}
                onClose={certificateOnClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent backgroundColor="yellow.600" margin={4}>
                    <ModalHeader backgroundColor="yellow.600" color="white">
                        Certificado
                    </ModalHeader>

                    <ModalBody backgroundColor="yellow.600" marginBottom={8}>
                        <Flex w="100%" direction="column" gap={4}>
                            <Text color="white">
                                Fique atento ao tempo de expiração dos seus
                                certificados digitais:
                            </Text>

                            <Divider />

                            {branches.map((branch) => (
                                <Flex
                                    direction="row"
                                    justify="space-between"
                                    key={branch._id}
                                >
                                    <Text color="white">{branch.name}</Text>
                                    <Text color="white">
                                        Expira dia:
                                        {`  ${
                                            dateFormatter.format(
                                                new Date(
                                                    branch?.nfeio.certificateValidUntil
                                                )
                                            ) || '--/--/--'
                                        }`}
                                    </Text>
                                </Flex>
                            ))}
                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="blackAlpha"
                            onClick={certificateOnClose}
                        >
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {children}
        </SocketContext.Provider>
    )
}
