import React, { useContext, useEffect, useMemo, useState } from 'react'
import { saveAs } from 'file-saver'

import {
    useToast,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    Text,
    Divider,
} from '@chakra-ui/react'
import { differenceInDays, isBefore } from 'date-fns'
import moment from 'moment/moment'
import { SocketContext } from '../../../../contexts/socket/SocketContext'

import { Input } from '../../../Form/Input'

const useInvoicesDownload = (data, onClose, branch) => {
    const { dateStart, dateEnd } = data
    const toast = useToast()

    const { receiver } = useContext(SocketContext)

    const [errors, setErrors] = useState({
        dateStart: false,
        dateEnd: false,
    })

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        receiver.on('nfe:productInvoice:xml:ok', (res) => {
            const file = new Blob([res])

            saveAs(file, 'Notas-Fiscais.zip')
            toast({
                title: 'Sucesso',
                description: 'Formulário salvo',
                position: 'top',
                status: 'success',
                isClosable: true,
            })
            onClose()
            setIsLoading(false)
        })

        receiver.on('nfe:productInvoice:xml:error', (res) => {
            toast({
                title: 'Erro',
                description: `Erro: ${res}`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
            setIsLoading(false)
        })

        receiver.on('nfe:consumerInvoice:xml:ok', (res) => {
            const file = new Blob([res])

            saveAs(file, 'Notas-Fiscais.zip')
            toast({
                title: 'Sucesso',
                description: 'Formulário salvo',
                position: 'top',
                status: 'success',
                isClosable: true,
            })
            onClose()
            setIsLoading(false)
        })

        receiver.on('nfe:consumerInvoice:xml:error', (res) => {
            toast({
                title: 'Erro',
                description: `Erro: ${res}`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
            setIsLoading(false)
        })

        receiver.on('event:rejected', (res) => {
            toast({
                title: 'Erro',
                description: `Erro: Evento rejeitado`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
            setIsLoading(false)
        })

        return () => {
            receiver.off('nfe:productInvoice:xml:ok')
            receiver.off('nfe:productInvoice:xml:error')
            receiver.off('nfe:consumerInvoice:xml:ok')
            receiver.off('nfe:consumerInvoice:xml:error')
            receiver.off('event:rejected')
        }
    }, [])

    const isInputValid = () => {
        let isValid = true

        if (isBefore(new Date(dateEnd), new Date(dateStart))) {
            isValid = false

            toast({
                title: 'Erro no formulário',
                description: 'Data de inicio deve ser anterior a data final',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        }

        if (differenceInDays(new Date(dateEnd), new Date(dateStart)) > 31) {
            isValid = false

            toast({
                title: 'Erro no formulário',
                description: 'Período de tempo deve ser menor que 31 dias',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        }

        setErrors({
            dateStart: !isValid,
            dateEnd: !isValid,
        })

        return isValid
    }

    const downloadInvoices = async () => {
        if (isInputValid()) {
            setIsLoading(true)

            if (branch.nfeio.canEmitNfe) {
                receiver.emit('nfe:productInvoice:xml', {
                    dateStart,
                    dateEnd,
                    branchId: branch._id,
                })
            } else if (branch.nfeio.canEmitNfce) {
                receiver.emit('nfe:consumerInvoice:xml', {
                    dateStart,
                    dateEnd,
                    branchId: branch._id,
                })
            }
        } else {
            toast({
                title: 'Erro no formulário',
                description: 'Verifique os campos destacados',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        }
    }

    const values = useMemo(
        () => ({
            downloadInvoices,
            isLoading,
            errors,
        }),
        [downloadInvoices, isLoading, errors]
    )

    return values
}

export function InvoicesDownloadModal({ isOpen, onClose, branchId }) {
    const { branches } = useContext(SocketContext)

    const branch = useMemo(() => {
        return branches.find((b) => b._id === branchId)
    }, [branchId, branches])

    const [dateStart, setdateStart] = useState(
        moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD')
    )

    const [dateEnd, setdateEnd] = useState(
        moment(new Date()).format('YYYY-MM-DD')
    )

    const { downloadInvoices, isLoading, errors } = useInvoicesDownload(
        {
            dateStart,
            dateEnd,
        },
        onClose,
        branch
    )

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent margin={4}>
                <ModalHeader>Notas Fiscais</ModalHeader>

                <ModalBody>
                    <VStack w="100%" spacing={2}>
                        <Text>
                            Para realizar o download das notas fiscais geradas,
                            especifique um período de tempo
                        </Text>

                        <Divider />
                        <Input
                            error={errors.dateStart}
                            label="Data Inicial"
                            type="date"
                            value={dateStart}
                            onChange={(e) => setdateStart(e.target.value)}
                        />
                        <Input
                            error={errors.dateEnd}
                            label="Data Final"
                            type="date"
                            value={dateEnd}
                            onChange={(e) => setdateEnd(e.target.value)}
                        />
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="gray" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        colorScheme="orange"
                        onClick={downloadInvoices}
                        isLoading={isLoading}
                    >
                        Confirmar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
