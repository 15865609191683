import React from 'react'

import { Box, Button, Center, Divider, Flex, Text } from '@chakra-ui/react'

import { Link } from 'react-router-dom'

export function CustomerCard({ customer, onClose }) {
    return (
        <Flex
            p={4}
            background="white"
            flexDirection="column"
            borderRadius={20}
            height="max-content"
            boxShadow="base"
        >
            <Box>
                <Text fontWeight="bold">
                    Nome:{' '}
                    <Text as="span" fontWeight="medium">
                        {`${customer.firstName} ${customer.lastName}`}
                    </Text>
                </Text>
                <Text fontWeight="bold">
                    CPF:{' '}
                    <Text as="span" fontWeight="medium">
                        {customer.icNumber}
                    </Text>
                </Text>
                <Text fontWeight="bold">
                    Data de Nascimento:{' '}
                    <Text as="span" fontWeight="medium">
                        {customer.dob}
                    </Text>
                </Text>
            </Box>

            <Center h={4}>
                <Divider />
            </Center>

            <Box>
                <Text fontWeight="bold">
                    Endereço:{' '}
                    <Text as="span" fontWeight="medium">
                        {`${customer.address.street}, ${customer.address.district}, ${customer.address.number}`}
                    </Text>
                </Text>
                <Text fontWeight="bold">
                    CEP:{' '}
                    <Text as="span" fontWeight="medium">
                        {customer.address.zipCode}
                    </Text>
                </Text>
                <Text fontWeight="bold">
                    Cidade:{' '}
                    <Text as="span" fontWeight="medium">
                        {customer?.address?.city?.name}
                    </Text>
                </Text>
            </Box>

            <Center h={4}>
                <Divider />
            </Center>

            <Flex
                alignItems="center"
                justifyContent="flex-end"
                flexDirection="row"
            >
                {onClose && (
                    <Link
                        to={{ pathname: '/finish-sale', state: { customer } }}
                    >
                        <Button colorScheme="orange" onClick={onClose}>
                            Selecionar
                        </Button>
                    </Link>
                )}
            </Flex>
        </Flex>
    )
}
