import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    background: radial-gradient(#f97e3e, #ff9a3f);
`

const drift = keyframes`
    from {
        transform: rotate(0deg);
    }

    from {
        transform: rotate(360deg);
    }
`

export const Box = styled.div`
    position: fixed;
    top: 0px;
    transform: rotate(100deg);
    left: 0px;

    .wave {
        position: fixed;
        top: 0px;
        left: 0px;
        opacity: 0.4;
        position: absolute;
        top: 3%;
        left: 10%;
        background: #ff813f;
        width: 1500px;
        height: 1300px;
        margin-left: -150px;
        margin-top: -250px;
        transform-origin: 50% 48%;
        border-radius: 43%;
        animation: ${drift} 30000ms infinite linear;
    }

    .-three {
        animation: ${drift} 30500ms infinite linear;
        position: fixed;
        background-color: #ff9a3f;
    }

    .-two {
        animation: ${drift} 25000ms infinite linear;
        opacity: 0.1;
        background: black;
        position: fixed;
    }

    &:after {
        content: '';
        display: block;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 11;
        transform: translate3d(0, 0, 0);
    }
`
