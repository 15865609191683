import React from 'react'

import {
    Button,
    Flex,
    Image,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react'

import { SearchIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import { Calculator } from './Calculator'
import { Cart } from './Cart'
import { RightMenus } from './RightMenus'

import { PrincipalMenus } from './PrincipalMenus'

export function Header() {
    const isMobileUser = useBreakpointValue({
        base: true,
        md: false,
    })

    if (isMobileUser) {
        return (
            <Flex
                w="100%"
                py={2}
                px={4}
                gap={2}
                direction="column"
                backgroundColor="rgb(255, 154, 63)"
            >
                <Flex w="100%" justify="space-between" align="center">
                    <PrincipalMenus />
                    <RightMenus />
                </Flex>
            </Flex>
        )
    }

    return (
        <Flex w="100%" backgroundColor="rgb(255, 154, 63)" justify="center">
            <Stack
                w="100%"
                maxW={['100%', '100%', '723px', '933px', '1128px']}
                direction="row"
                py={2}
            >
                <PrincipalMenus />
                <Flex w="100%" justifyContent="flex-end" ml="auto" gap={4}>
                    <Calculator />
                </Flex>
                <RightMenus />
                <Cart />
            </Stack>
        </Flex>
    )
}
