import React, { useMemo } from 'react'

import { FieldLine } from '../../FieldLine'

const paymentTypes = [
    'Transferência Bancária',
    'Dinheiro',
    'Débito',
    'Crédito',
    'Crediário',
]

export function Payment({ total, pt, _slId }) {
    const paymentType = useMemo(() => paymentTypes[pt], [pt])

    return (
        <>
            <FieldLine title="Tipo de pagamento" value={`${paymentType}`} />
            <FieldLine title="Total" value={`${total}`} />
            <FieldLine title="Id da venda" value={`${_slId}`} />
        </>
    )
}
