export const breadcrumbs = {
    main: {
        name: 'Analítico Geral',
        pages: [],
    },
    good: {
        name: 'Produto',
        pages: ['search'],
    },
    search: {
        name: 'Produtos',
        pages: [],
    },
    'search-by-name': {
        name: 'Por Nome',
        pages: ['search'],
    },
    'search-by-code': {
        name: 'Por Código',
        pages: ['search'],
    },
    'search-by-attribute': {
        name: 'Por Atributo',
        pages: ['search'],
    },
    'search-by-category': {
        name: 'Por Categoria',
        pages: ['search'],
    },
    cart: {
        name: 'Cesta',
        pages: [],
    },
    'customer-select': {
        name: 'Selecionar Cliente',
        pages: ['cart'],
    },
    'finish-sale': {
        name: 'Finalizar Venda',
        pages: ['cart', 'customer-select'],
    },
    workspace: {
        name: 'Área de trabalho',
        pages: [],
    },
    'workspace-users': {
        name: 'Usuarios',
        pages: ['workspace'],
    },
    'workspace-customers': {
        name: 'Clientes',
        pages: ['workspace'],
    },
    workspaceBillsToPay: {
        name: 'Contas a pagar',
        pages: ['workspace'],
    },
    'workspace-brands': {
        name: 'Marcas dos produtos',
        pages: ['workspace'],
    },
    'workspace-good': {
        name: 'Produto',
        pages: ['workspace'],
    },
    'workspace-good-variations': {
        name: 'Variações de Produtos',
        pages: ['workspace', 'workspace-good'],
    },
    'workspace-categories': {
        name: 'Categorias',
        pages: ['workspace', 'workspace-good'],
    },
    'workspace-attributes': {
        name: 'Atributos',
        pages: ['workspace', 'workspace-good'],
    },
    'workspace-goods': {
        name: 'Produtos',
        pages: ['workspace', 'workspace-good'],
    },
    'workspace-payments': {
        name: 'Pagamentos',
        pages: ['workspace'],
    },
    'workspace-installments': {
        name: 'Crediarios',
        pages: ['workspace'],
    },
    'workspace-installment-dues': {
        name: 'Parcelas',
        pages: ['workspace', 'workspace-installments'],
    },
    'workspace-sales': {
        name: 'Vendas',
        pages: ['workspace'],
    },
    'workspace-sales-list': {
        name: 'Lista de vendas',
        pages: ['workspace', 'workspace-sales'],
    },
    'workspace-sales-reports': {
        name: 'Relatórios de vendas',
        pages: ['workspace', 'workspace-sales'],
    },
    'workspace-expense': {
        name: 'Despesa',
        pages: ['workspace'],
    },
    'workspace-expense-types': {
        name: 'Tipos',
        pages: ['workspace', 'workspace-expense'],
    },
    'workspace-expenses': {
        name: 'Despesas',
        pages: ['workspace', 'workspace-expense'],
    },
    'workspace-trade': {
        name: 'Troca de produtos',
        pages: ['workspace'],
    },
    'workspace-trade-reasons': {
        name: 'Razões de Troca',
        pages: ['workspace', 'workspace-trade'],
    },
    'workspace-trades': {
        name: 'Trocas',
        pages: ['workspace', 'workspace-trade'],
    },
    'workspace-money-devolution': {
        name: 'Devoluções de dinheiro',
        pages: ['workspace'],
    },
    'workspace-devolution-reasons': {
        name: 'Razões de Devolução',
        pages: ['workspace', 'workspace-money-devolution'],
    },
    'workspace-devolutions': {
        name: 'Devoluções',
        pages: ['workspace', 'workspace-money-devolution'],
    },
    'workspace-sales-cancel': {
        name: 'Cancelamentos de vendas',
        pages: ['workspace'],
    },
    'workspace-sales-cancel-reasons': {
        name: 'Razões para Cancelamento',
        pages: ['workspace', 'workspace-sales-cancel'],
    },
    'workspace-sales-cancels': {
        name: 'Cancelamentos',
        pages: ['workspace', 'workspace-sales-cancel'],
    },
    workspaceSuppliers: {
        name: 'Fornecedores',
        pages: ['workspace'],
    },
    workspaceViewUser: {
        name: 'Usuario',
        pages: ['workspace', 'workspace-users'],
    },
    workspaceViewCustomer: {
        name: 'Cliente',
        pages: ['workspace', 'workspace-customers'],
    },
    workspaceViewBillsToPay: {
        name: 'Conta a pagar',
        pages: ['workspace', 'workspaceBillsToPays'],
    },
    workspaceViewBrand: {
        name: 'Marca do produto',
        pages: ['workspace', 'workspace-brands'],
    },
    workspaceViewGoodVariation: {
        name: 'Variação de produto',
        pages: ['workspace', 'workspace-good', 'workspace-good-variations'],
    },
    workspaceViewCategory: {
        name: 'Categoria',
        pages: ['workspace', 'workspace-good', 'workspace-categories'],
    },
    workspaceViewAttribute: {
        name: 'Atributo',
        pages: ['workspace', 'workspace-good', 'workspace-attributes'],
    },
    workspaceViewGood: {
        name: 'Produto',
        pages: ['workspace', 'workspace-good', 'workspace-goods'],
    },
    workspaceViewPayment: {
        name: 'Pagamento',
        pages: ['workspace', 'workspace-payments'],
    },
    workspaceViewInstallment: {
        name: 'Crediario',
        pages: ['workspace', 'workspace-installments'],
    },
    workspaceViewInstallmentDue: {
        name: 'Parcela',
        pages: [
            'workspace',
            'workspace-installments',
            'workspaceViewInstallment',
        ],
    },
    workspaceViewSale: {
        name: 'Venda',
        pages: ['workspace', 'workspace-sales', 'workspace-sales-list'],
    },
    workspaceViewExpenseType: {
        name: 'Tipo',
        pages: ['workspace', 'workspace-expense', 'workspace-expense-types'],
    },
    workspaceViewExpense: {
        name: 'Despesa',
        pages: ['workspace', 'workspace-expense', 'workspace-expenses'],
    },
    workspaceViewTradeReason: {
        name: 'Razão de Troca',
        pages: ['workspace', 'workspace-trade', 'workspace-trade-reasons'],
    },
    workspaceViewTrade: {
        name: 'Troca',
        pages: ['workspace', 'workspace-trade', 'workspace-trades'],
    },
    workspaceViewDevolutionReason: {
        name: 'Razão de Devolução',
        pages: [
            'workspace',
            'workspace-money-devolution',
            'workspace-devolution-reasons',
        ],
    },
    workspaceViewDevolution: {
        name: 'Devolução',
        pages: [
            'workspace',
            'workspace-money-devolution',
            'workspace-devolutions',
        ],
    },
    workspaceViewSalesCancelReason: {
        name: 'Razão para Cancelamento',
        pages: [
            'workspace',
            'workspace-sales-cancel',
            'workspace-sales-cancel-reasons',
        ],
    },
    workspaceViewSalesCancel: {
        name: 'Cancelamento',
        pages: [
            'workspace',
            'workspace-sales-cancel',
            'workspace-sales-cancels',
        ],
    },
    workspaceViewSupplier: {
        name: 'Fornecedor',
        pages: ['workspace', 'workspaceSuppliers'],
    },
    workspaceEditUser: {
        name: 'Usuario',
        pages: ['workspace', 'workspace-users'],
    },
    workspaceEditCustomer: {
        name: 'Cliente',
        pages: ['workspace', 'workspace-customers'],
    },
    workspaceEditBillsToPay: {
        name: 'Conta a pagar',
        pages: ['workspace', 'workspaceBillsToPays'],
    },
    workspaceEditBrand: {
        name: 'Marca do produto',
        pages: ['workspace', 'workspace-brands'],
    },
    workspaceEditGoodVariation: {
        name: 'Produto',
        pages: ['workspace', 'workspace-good', 'workspace-good-variations'],
    },
    workspaceEditCategory: {
        name: 'Categoria',
        pages: ['workspace', 'workspace-good', 'workspace-categories'],
    },
    workspaceEditAttribute: {
        name: 'Atributo',
        pages: ['workspace', 'workspace-good', 'workspace-attributes'],
    },
    workspaceEditGood: {
        name: 'Produto',
        pages: ['workspace', 'workspace-good', 'workspace-goods'],
    },
    workspaceEditPayment: {
        name: 'Pagamento',
        pages: ['workspace', 'workspace-payments'],
    },
    workspaceEditInstallment: {
        name: 'Crediario',
        pages: ['workspace', 'workspace-installments'],
    },
    workspaceEditInstallmentDue: {
        name: 'Parcela',
        pages: [
            'workspace',
            'workspace-installments',
            'workspaceEditInstallment',
        ],
    },
    workspaceEditSale: {
        name: 'Venda',
        pages: ['workspace', 'workspace-sales', 'workspace-sales-list'],
    },
    workspaceEditExpenseType: {
        name: 'Tipo',
        pages: ['workspace', 'workspace-expense', 'workspace-expense-types'],
    },
    workspaceEditExpense: {
        name: 'Despesa',
        pages: ['workspace', 'workspace-expense', 'workspace-expenses'],
    },
    workspaceEditTradeReason: {
        name: 'Razão de Troca',
        pages: ['workspace', 'workspace-trade', 'workspace-trade-reasons'],
    },
    workspaceEditTrade: {
        name: 'Troca',
        pages: ['workspace', 'workspace-trade', 'workspace-trades'],
    },
    workspaceEditDevolutionReason: {
        name: 'Razão de Devolução',
        pages: [
            'workspace',
            'workspace-money-devolution',
            'workspace-devolution-reasons',
        ],
    },
    workspaceEditDevolution: {
        name: 'Devolução',
        pages: [
            'workspace',
            'workspace-money-devolution',
            'workspace-devolutions',
        ],
    },
    workspaceEditSalesCancelReason: {
        name: 'Razão para Cancelamento',
        pages: [
            'workspace',
            'workspace-sales-cancel',
            'workspace-sales-cancel-reasons',
        ],
    },
    workspaceEditSalesCancel: {
        name: 'Cancelamento',
        pages: [
            'workspace',
            'workspace-sales-cancel',
            'workspace-sales-cancels',
        ],
    },
    workspaceEditSupplier: {
        name: 'Fornecedor',
        pages: ['workspace', 'workspaceSuppliers'],
    },
    workspaceInsertUser: {
        name: 'Usuario',
        pages: ['workspace', 'workspace-users'],
    },
    workspaceInsertCustomer: {
        name: 'Cliente',
        pages: ['workspace', 'workspace-customers'],
    },
    workspaceInsertBrand: {
        name: 'Marca do produto',
        pages: ['workspace', 'workspace-brands'],
    },
    workspaceInsertGoodVariation: {
        name: 'Produto',
        pages: ['workspace', 'workspace-good-variations'],
    },
    workspaceInsertCategory: {
        name: 'Categoria',
        pages: ['workspace', 'workspace-categories'],
    },
    workspaceInsertAttribute: {
        name: 'Atributo',
        pages: ['workspace', 'workspace-attributes'],
    },
    workspaceInsertGood: {
        name: 'Produto',
        pages: ['workspace', 'workspace-goods'],
    },
    workspaceInsertPayment: {
        name: 'Pagamento',
        pages: ['workspace', 'workspace-payments'],
    },
    workspaceInsertInstallment: {
        name: 'Crediario',
        pages: ['workspace', 'workspace-installments'],
    },
    workspaceInsertInstallmentDue: {
        name: 'Parcela',
        pages: ['workspace', 'workspace-installments'],
    },
    workspaceInsertSale: {
        name: 'Venda',
        pages: ['workspace', 'workspace-sales', 'workspace-sales-list'],
    },
    workspaceInsertExpenseType: {
        name: 'Tipo',
        pages: ['workspace', 'workspace-expense-types'],
    },
    workspaceInsertExpense: {
        name: 'Despesa',
        pages: ['workspace', 'workspace-expenses'],
    },
    workspaceInsertTradeReason: {
        name: 'Razão de Troca',
        pages: ['workspace', 'workspace-trade-reasons'],
    },
    workspaceInsertTrade: {
        name: 'Troca',
        pages: ['workspace', 'workspace-trades'],
    },
    workspaceInsertDevolutionReason: {
        name: 'Razão de Devolução',
        pages: ['workspace', 'workspace-devolution-reasons'],
    },
    workspaceInsertDevolution: {
        name: 'Devolução',
        pages: ['workspace', 'workspace-devolutions'],
    },
    workspaceInsertSalesCancelReason: {
        name: 'Razão para Cancelamento',
        pages: ['workspace', 'workspace-sales-cancel-reasons'],
    },
    workspaceInsertSalesCancel: {
        name: 'Cancelamento',
        pages: ['workspace', 'workspace-sales-cancels'],
    },
    workspaceInsertSupplier: {
        name: 'Fornecedor',
        pages: ['workspace', 'workspaceSuppliers'],
    },
    'workspace-nfe': {
        name: 'Nota Fiscal',
        pages: ['workspace'],
    },
}
