import React from 'react'

import { FieldLine } from '../../FieldLine'

export function Expense({ timestamp, value }) {
    return (
        <>
            <FieldLine
                title="Data"
                value={`${new Intl.DateTimeFormat('pt-BR').format(
                    timestamp * 1000
                )}`}
            />
            <FieldLine title="Valor" value={value} />
        </>
    )
}
