import React, { useContext, useMemo, useState } from 'react'

import {
    Collapse,
    Flex,
    Text,
    Button,
    HStack,
    VStack,
    Divider,
} from '@chakra-ui/react'

import { WarningIcon } from '@chakra-ui/icons'
import { SaleContext } from '../../../../../../contexts/sale/SaleContext'
import { Input } from '../../../../../Form/Input'
import { SocketContext } from '../../../../../../contexts/socket/SocketContext'

export function Exchange({
    isOpen,
    isSubmitSuccessful,
    isSubmitSuccessfulSet,
    customer,
    pdfBase64,
    waiting,
    waitingSet,
    branchHasUpdatedCertificate,
    finishSale,
}) {
    const { user } = useContext(SocketContext)
    const { sale, branch, isSaleTest } = useContext(SaleContext)

    const [obs, obsSet] = useState('')

    const onSubmit = (e) => {
        e.preventDefault()

        isSubmitSuccessfulSet(false)
        waitingSet(true)

        const data = {
            customer,
            user,
            branch,
            saleItems: sale.products.map((product) => ({
                ...product,
                price: {
                    retail: parseFloat(product?.price?.retail),
                    cost: parseFloat(product?.price?.cost),
                },
                saleTest: !!product.saleTest,
                nfe: {
                    ...product.nfe,
                    unitAmount: parseFloat(product?.price?.retail || 0),
                    quantity: 1,
                },
            })),
            payment: {
                pt: 5,
                tax: 0,
                total: sale.total,
                obs,
                discount: 0,
                cash: 0,
            },
            saleTest: sale.products.reduce(
                (acc, product) => acc || !!product.saleTest,
                false
            ),
        }

        finishSale(data)
    }

    return (
        <Collapse in={isOpen} animateOpacity>
            <Flex
                as="form"
                direction="column"
                justify="center"
                align="center"
                onSubmit={onSubmit}
                gap={4}
            >
                <Input
                    label="Observação"
                    name="obs"
                    type="text"
                    value={obs}
                    onChange={(e) => {
                        obsSet(e.target.value)
                    }}
                />
                <Flex direction="row" alignItems="center" alignSelf="start">
                    <WarningIcon marginRight={1} color="red.300" />
                    <Text align="left" color="red.300" justifySelf="left">
                        É necessário realizar a inserção manualmente no estoque
                        para o produto a que a troca refere-se
                    </Text>
                </Flex>

                <Button
                    colorScheme="orange"
                    isLoading={waiting}
                    type="submit"
                    alignSelf="end"
                    isDisabled={!branchHasUpdatedCertificate && !isSaleTest}
                >
                    Confirmar
                </Button>

                {isSubmitSuccessful && (
                    <Text textAlign="center" color="green.500">
                        Venda realizada com sucesso!
                    </Text>
                )}
                {isSaleTest && isSubmitSuccessful && !!pdfBase64 && (
                    <a
                        download={`sale-${new Date().getTime()}.pdf`}
                        href={pdfBase64}
                    >
                        Baixar cupom fiscal como pdf
                    </a>
                )}
            </Flex>
        </Collapse>
    )
}
