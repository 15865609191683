import React from 'react'

import { Center, Text } from '@chakra-ui/react'

export function Empty() {
    return (
        <Center minH="200px" height="100%" borderRadius="2xl">
            <Text fontSize="large">Nenhum resultado encontrado</Text>
        </Center>
    )
}
