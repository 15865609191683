import React, { useContext, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import {
    Button,
    Flex,
    IconButton,
    Image,
    SimpleGrid,
    Text,
    useToast,
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'

import { useDropzone } from 'react-dropzone'
import { SocketContext } from '../../../../../contexts/socket/SocketContext'
import { apiRest } from '../../../../../services/api'

export function ImageUpload({ setCustomOnSubmit, data }) {
    const { receiver } = useContext(SocketContext)
    const toast = useToast()
    const [files, setFiles] = useState([])
    const [tryingToRemove, setTryingToRemove] = useState(false)

    const dispatchAlert = (title_, description_, status_) => {
        toast({
            title: title_,
            description: description_,
            position: 'top',
            status: status_,
            isClosable: true,
        })
    }

    const { open, getInputProps, getRootProps } = useDropzone({
        accept: '.webp,.jpeg,.png,.jpg',
        onDropRejected: (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                if (file.file.size > 10000000)
                    dispatchAlert(
                        `Erro ao inserir a imagem ${file.file.name}`,
                        `Tamanho limite de arquivo excedido`,
                        'warning'
                    )
                else
                    dispatchAlert(
                        `Erro ao inserir a imagem ${file.file.name}`,
                        `Selecione um arquivo válido`,
                        'warning'
                    )
            })
        },
        onDropAccepted: async (acceptedFiles) => {
            const newFiles = await acceptedFiles.reduce(async (acc, file) => {
                const newAcc = await acc

                newAcc.push({
                    file_: file,
                    preview: URL.createObjectURL(file),
                    id: uuidv4(),
                    originalName: `${uuidv4()}-${file.name}`,
                })
                return newAcc
            }, Promise.resolve([]))

            setFiles([...files, ...newFiles])
        },
        maxSize: 10000000,
    })

    const removeImage = async (file) => {
        if (file._id) {
            try {
                setTryingToRemove(true)
                await apiRest.post(`/delete`, {
                    name: file.originalName,
                })
                receiver.emit('good_variation:delete:picture', file)
                setTryingToRemove(false)
                dispatchAlert(
                    `Remoção de Imagem`,
                    `Imagem removida com sucesso`,
                    'success'
                )
            } catch (error) {
                setTryingToRemove(false)
                if (error.message === 'Network Error') {
                    dispatchAlert(
                        `Erro ao deletar a imagem`,
                        `Servidor de upload de imagem fora do ar`,
                        'warning'
                    )
                }
            }
        } else {
            setFiles(files.filter((f) => f.id !== file.id))
            dispatchAlert(
                `Remoção de Imagem`,
                `Imagem removida com sucesso`,
                'success'
            )
        }
    }

    const customSubmit = async () => {
        const requests = files.map((currentFile) => {
            if (!currentFile.preview) {
                return null
            }

            const idsObject = {
                _coId: data._coId,
                _gdvId: data._id,
            }
            const dataForm = new FormData()

            dataForm.append('file', currentFile.file_, currentFile.originalName)
            Object.keys(idsObject).forEach((key) =>
                dataForm.append(key, idsObject[key])
            )

            return apiRest.post(`/upload`, dataForm, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        })

        try {
            await Promise.all(requests)
            return true
        } catch (error) {
            if (error.message === 'Network Error') {
                toast({
                    title: `Erro ao inserir a(s) imagem(ns)`,
                    description: `Servidor de upload de imagem fora do ar`,
                    position: 'top',
                    status: 'warning',
                    isClosable: true,
                })
            }
        }
        return false
    }

    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })
    }, [files])

    useEffect(() => {
        receiver.on('good_variation:delete:picture:ok', (res) => {
            const { _id } = res
            if (_id) {
                setFiles(files.filter((file) => file._id !== _id))
            }
        })
    }, [files])

    useEffect(() => {
        receiver.on('good_variation:delete:picture:error', (err) => {
            toast({
                title: 'Erro',
                description: err,
                position: 'top',
                status: 'warning',
                isClosable: true,
            })
        })

        receiver.on('good_variation:get:pictures:ok', (res) => {
            setFiles(
                res?.pictures?.map((picture) => {
                    const newPic = { ...picture }
                    newPic.id = newPic._id
                    return newPic
                }) || []
            )
        })

        receiver.on('good_variation:get:pictures:error', (err) => {
            toast({
                title: 'Erro',
                description: err,
                position: 'top',
                status: 'warning',
                isClosable: true,
            })
        })

        receiver.emit('good_variation:get:pictures', data)

        return () => {
            receiver.off('good_variation:delete:picture:ok')
            receiver.off('good_variation:delete:picture:error')
            receiver.off('good_variation:get:pictures:ok')
            receiver.off('good_variation:get:pictures:error')
        }
    }, [data])

    return (
        <>
            <Text fontSize="2xl">Fotos</Text>
            <Text fontSize="1xl">
                Formatos suportados: PNG, JPG, JPEG e WEBP
            </Text>
            <Flex
                w="100%"
                border="1px solid"
                borderStyle="dashed"
                borderColor="blackAlpha.400"
                my={4}
                borderRadius="2xl"
                minH="300px"
            >
                <input {...getInputProps()} />
                {files.length > 0 ? (
                    <SimpleGrid
                        minChildWidth="280px"
                        w="100%"
                        p={4}
                        direction="row"
                        gap={4}
                        justifyContent="start"
                        alignItems="center"
                        overflowY="scroll"
                    >
                        {files.map((file) => (
                            <Flex
                                flex={1}
                                h="100%"
                                direction="column"
                                gap={4}
                                key={file.id}
                            >
                                <Image
                                    flex={1}
                                    maxH="220px"
                                    width="auto"
                                    src={file.path ? file.path : file.preview}
                                    borderRadius="2xl"
                                    objectFit="cover"
                                />
                                <Button
                                    colorScheme="blackAlpha"
                                    onClick={() => removeImage(file)}
                                    disabled={tryingToRemove}
                                >
                                    Remover
                                </Button>
                            </Flex>
                        ))}
                        <Flex
                            flex={1}
                            minH="220px"
                            h="100%"
                            justifyContent="center"
                            direction="column"
                            alignItems="center"
                            gap={4}
                        >
                            <IconButton
                                size="lg"
                                maxW="40px"
                                icon={<FaPlus />}
                                colorScheme="orange"
                                onClick={open}
                            />
                        </Flex>
                    </SimpleGrid>
                ) : (
                    <Flex
                        direction="column"
                        justify="center"
                        alignItems="center"
                        w="100%"
                        gap={2}
                        p={4}
                    >
                        <IconButton
                            size="lg"
                            icon={<FaPlus />}
                            colorScheme="orange"
                            onClick={open}
                        />
                        <Text textAlign="center">
                            Pressione para selecionar as fotos
                        </Text>
                    </Flex>
                )}
            </Flex>
        </>
    )
}
