import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Fonts } from './styles/fonts'
import { SocketContextProvider } from './contexts/socket/SocketContext'
import { theme } from './styles/theme'
import { Router } from './Router'

function App() {
    return (
        <ChakraProvider theme={theme}>
            <SocketContextProvider>
                <Router />
            </SocketContextProvider>
            <Fonts />
        </ChakraProvider>
    )
}

export default App
