import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useReactToPrint } from 'react-to-print'

import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    Text,
    Divider,
    useToast,
    Box,
} from '@chakra-ui/react'
import { addMonths } from 'date-fns'

import { Input } from '../../../Form/Input'
import { useReportDownload } from './UseReportDownload'
import { SintheticReport } from './Report'
import { SaleContext } from '../../../../contexts/sale/SaleContext'

const now = new Date()
const aMonthAfterNow = addMonths(now, 1)

const traitDate = (date) => {
    return date.toString().padStart(2, '0')
}

export function SalesSintheticReportModal({ isOpen, onClose, branchId }) {
    const { branches } = useContext(SaleContext)

    let printRef = useRef()

    const branch = useMemo(
        () => branches.find((br) => br._id === branchId),
        [branchId]
    )

    const [initialDate, setInitialDate] = useState(
        `${now.getFullYear()}-${traitDate(now.getMonth() + 1)}-${traitDate(
            now.getDay()
        )}`
    )

    const [endDate, setEndDate] = useState(
        `${aMonthAfterNow.getFullYear()}-${traitDate(
            aMonthAfterNow.getMonth() + 1
        )}-${traitDate(aMonthAfterNow.getDay())}`
    )

    const toast = useToast()

    const { downloadReportSinthetic, isLoading, isReportReady, errors, sales } =
        useReportDownload(branchId, initialDate, endDate)

    const openPrintDocument = useReactToPrint({
        content: () => printRef,
        documentTitle: 'vendas',
        onAfterPrint: () => {
            onClose()
        },
        onPrintError: () => {
            toast({
                title: 'Erro',
                description: `Erro ao imprimir relatórios`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        },
    })

    const handlePrint = () => {
        downloadReportSinthetic()
    }

    useEffect(() => {
        if (isReportReady) {
            openPrintDocument()
        }
    }, [isReportReady])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent margin={4}>
                    <ModalHeader>Vendas - Sintético</ModalHeader>

                    <ModalBody>
                        <VStack w="100%" spacing={2}>
                            <Text>
                                Para realizar o download do relatório sintético,
                                especifique um período de tempo
                            </Text>

                            <Divider />
                            <Input
                                error={errors.initialDate}
                                label="Data Inicial"
                                type="date"
                                value={initialDate}
                                onChange={(e) => setInitialDate(e.target.value)}
                            />
                            <Input
                                error={errors.endDate}
                                label="Data Final"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="gray" mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button
                            colorScheme="orange"
                            isLoading={isLoading}
                            onClick={handlePrint}
                        >
                            Confirmar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Box display="none">
                <SintheticReport
                    sales={sales}
                    branch={branch}
                    ref={(el) => {
                        printRef = el
                    }}
                />
            </Box>
        </>
    )
}
