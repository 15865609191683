import React, { useMemo } from 'react'

import {
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    FormControl,
    FormLabel,
} from '@chakra-ui/react'
import { Icon } from 'semantic-ui-react'
import { SearchIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

export function SearchBar({
    isMobileUser,
    label,
    placeholder,
    value,
    valueSet,
    onFilter,
    qrCode,
}) {
    const showQrCode = useMemo(() => {
        return !isMobileUser && qrCode
    }, [isMobileUser, qrCode])

    const onSearch = (e) => {
        e.preventDefault()
        onFilter(value)
    }

    return (
        <FormControl
            display="flex"
            align="center"
            flexDirection="column"
            zIndex={100}
        >
            <FormLabel>{label}</FormLabel>

            <InputGroup as="form" onSubmit={onSearch}>
                <Input
                    pr={!showQrCode ? '2rem' : '5rem'}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => valueSet(e.target.value)}
                    backgroundColor="white"
                />

                <InputRightElement width={!showQrCode ? '2rem' : '5rem'}>
                    {showQrCode && (
                        <Link to="/qrCode">
                            <IconButton
                                aria-label="qrCode product"
                                colorScheme="orange"
                                icon={
                                    <Icon
                                        style={{ marginRight: '0px' }}
                                        name="qrcode"
                                    />
                                }
                                maxW="2rem"
                                borderRadius="0"
                            />
                        </Link>
                    )}

                    <IconButton
                        type="submit"
                        aria-label="search product"
                        icon={<SearchIcon />}
                        colorScheme="orange"
                        onClick={onSearch}
                        maxW="2rem"
                        borderLeftRadius="0"
                    />
                </InputRightElement>
            </InputGroup>
        </FormControl>
    )
}
