import React from 'react'
import {
    Button,
    useDisclosure,
    Icon,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerCloseButton,
    Heading,
    Center,
    Divider,
    useBreakpointValue,
} from '@chakra-ui/react'

import { FaFilter } from 'react-icons/fa'
import { Filters } from './Filters'

export function CategoryFilterBox() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const isMobileUser = useBreakpointValue({
        base: true,
        xl: false,
    })

    if (isMobileUser) {
        return (
            <>
                <Button
                    leftIcon={<Icon as={FaFilter} />}
                    onClick={onOpen}
                    alignSelf="start"
                    colorScheme="orange"
                    mb={2}
                >
                    Filtros
                </Button>
                <Drawer onClose={onClose} isOpen={isOpen} size="md">
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>
                            <Heading color="orange.600">Filtros</Heading>
                            <Center height="20px">
                                <Divider />
                            </Center>
                        </DrawerHeader>
                        <DrawerBody>
                            <Filters onClose={onClose} />
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </>
        )
    }

    return <Filters isMobileUser={isMobileUser} />
}
