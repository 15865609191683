import React, { useContext, useEffect, useState } from 'react'
import { Text } from '@chakra-ui/react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Input } from '../../../Form/Input'
import { Select } from '../../../Form/Select'
import { CustomBehaviorGood } from '../CustomBehaviors/good'

export function Good({ errors, register, reset, setCustomOnSubmit, setValue }) {
    const { receiver } = useContext(SocketContext)

    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])

    useEffect(() => {
        /* load brands */
        receiver.on('crud:brand:find:ok', (response) => {
            setBrands(response.result)
        })

        /* load categories */
        receiver.on('crud:category:find:ok', (response) => {
            setCategories(response.result)
        })

        receiver.emit('crud:brand:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:category:find', { page: 0, size: Number.MAX_VALUE })

        setValue('_akIds', [], { shouldDirty: true })

        return () => {
            receiver.off('crud:brand:find:ok')
            receiver.off('crud:category:find:ok')
        }
    }, [])

    useEffect(() => {
        if (brands.length > 0 && categories.length > 0) {
            reset({
                _brId: brands?.[0]?._id,
                _catId: categories?.[0]?._id,
            })
        }
    }, [brands, categories])

    return (
        <>
            <Text fontSize="2xl">Informações</Text>
            <Input
                name="name"
                error={errors.name}
                label="Nome"
                type="text"
                {...register('name')}
            />
            <Input
                name="description"
                error={errors.description}
                label="Descrição"
                type="text"
                {...register('description')}
            />

            <CustomBehaviorGood setCustomOnSubmit={setCustomOnSubmit} />

            <Select
                name="_brId"
                error={errors._brId}
                label="Marca"
                options={brands}
                {...register('_brId')}
            />
            <Select
                name="_catId"
                error={errors._catId}
                label="Categoria"
                options={categories}
                {...register('_catId')}
            />
        </>
    )
}
