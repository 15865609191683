import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: var(--background);

    overflow: hidden;
`

const rotate = keyframes`
    100% {
        transform: rotate(360deg)
    }
`

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    position: relative;

    .loader:before {
        position: absolute;
        top: 4px;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 100%;

        box-shadow: 0 -10px 20px 20px #fff8f540 inset,
            0 -5px 15px 10px #fff8f550 inset, 0 -2px 5px #fff8f580 inset,
            0 -3px 2px #fff8f5bb inset, 0 2px 0px #fff8f5, 0 2px 3px #fff8f5,
            0 5px 5px #fff8f590, 0 10px 15px #fff8f560,
            0 10px 20px 20px #fff8f540;

        filter: blur(3px);
        animation: 2s ${rotate} linear infinite;
    }

    .position {
        top: 8px;
        position: relative;
    }
`
