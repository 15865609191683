import React, { useContext, useState } from 'react'

import { Flex } from '@chakra-ui/react'
import { FilterResults } from '../../../components/Search/FilterResults'
import { SearchBar } from '../../../components/Search/SearchBar'
import { AttributeFilterBox } from '../../../components/Search/AttributeFilterBox'
import { FilterContext } from '../../../contexts/filter/FilterContext'
import { SocketContext } from '../../../contexts/socket/SocketContext'

export function Content() {
    const [search, searchSet] = useState('')
    const [filteredBy, filteredBySet] = useState('')
    const { receiver } = useContext(SocketContext)
    const { filters } = useContext(FilterContext)

    const onFilterByName = () => {
        const searchParams = {
            name: search || '',
            attributeValues: Object.entries(filters).map(([id, filter]) => id),
        }

        receiver.emit('good:find:by:name', searchParams)
    }

    return (
        <Flex
            direction="column"
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            gap={4}
            h="max-content"
        >
            <SearchBar
                label="Nome do produto"
                placeholder="Nome"
                value={search}
                valueSet={searchSet}
                filteredBySet={filteredBySet}
                onFilter={onFilterByName}
            />

            <Flex direction={{ base: 'column', xl: 'row' }} gap={4}>
                <AttributeFilterBox />
                <FilterResults variation="attribute" filteredBy={filteredBy} />
            </Flex>
        </Flex>
    )
}
