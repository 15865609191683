import React, { useContext, useMemo } from 'react'

import {
    Button,
    Collapse,
    Divider,
    Flex,
    Heading,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import { differenceInDays } from 'date-fns'
import { Credit } from './Credit'
import { Debit } from './Debit'
import { Money } from './Money'
import { SaleContext } from '../../../../../contexts/sale/SaleContext'
import { Exchange } from './Exchange'

export function AtSight({
    isOpen,
    isMobileUser,
    customer,
    pdfBase64,
    isSubmitSuccessfulSet,
    isSubmitSuccessful,
    waiting,
    waitingSet,
    finishSale,
}) {
    const { branch, branches } = useContext(SaleContext)

    const branchHasUpdatedCertificate = useMemo(() => {
        const now = new Date()
        const actualBranch = branches.find((br) => br._id === branch)

        return (
            differenceInDays(
                new Date(actualBranch?.nfeio?.certificateValidUntil).getTime(),
                now.getTime()
            ) > 0
        )
    }, [branches, branch])

    const {
        isOpen: isCreditOpen,
        onToggle: onToggleCredit,
        onClose: onCloseCredit,
    } = useDisclosure()

    const {
        isOpen: isDebitOpen,
        onToggle: onToggleDebit,
        onClose: onCloseDebit,
    } = useDisclosure()

    const {
        isOpen: isMoneyOpen,
        onToggle: onToggleMoney,
        onClose: onCloseMoney,
    } = useDisclosure()

    const {
        isOpen: isExchangeOpen,
        onToggle: onToggleExchange,
        onClose: onCloseExchange,
    } = useDisclosure()

    return (
        <Collapse in={isOpen} animateOpacity>
            <Flex direction="column" gap={4}>
                <Flex direction={isMobileUser ? 'column' : 'row'} gap={2}>
                    <Button
                        flex="1"
                        minH="70px"
                        maxH="70px"
                        colorScheme="orange"
                        opacity={
                            isDebitOpen || isMoneyOpen || isExchangeOpen
                                ? 0.4
                                : 1
                        }
                        onClick={() => {
                            onCloseDebit()
                            onCloseMoney()
                            onCloseExchange()
                            onToggleCredit()
                            isSubmitSuccessfulSet(false)
                        }}
                    >
                        <Heading fontSize="medium">Crédito</Heading>
                    </Button>

                    <Divider orientation="vertical" />

                    <Button
                        flex="1"
                        minH="70px"
                        maxH="70px"
                        colorScheme="orange"
                        opacity={
                            isCreditOpen || isMoneyOpen || isExchangeOpen
                                ? 0.4
                                : 1
                        }
                        onClick={() => {
                            onCloseCredit()
                            onCloseMoney()
                            onCloseExchange()
                            onToggleDebit()
                            isSubmitSuccessfulSet(false)
                        }}
                    >
                        <Text fontSize="medium">Débito</Text>
                    </Button>

                    <Divider orientation="vertical" />

                    <Button
                        flex="1"
                        minH="70px"
                        maxH="70px"
                        colorScheme="orange"
                        opacity={
                            isCreditOpen || isDebitOpen || isExchangeOpen
                                ? 0.4
                                : 1
                        }
                        onClick={() => {
                            onCloseCredit()
                            onCloseDebit()
                            onCloseExchange()
                            onToggleMoney()
                            isSubmitSuccessfulSet(false)
                        }}
                    >
                        <Text fontSize="medium">Dinheiro</Text>
                    </Button>

                    <Divider orientation="vertical" />

                    <Button
                        flex="1"
                        minH="70px"
                        maxH="70px"
                        colorScheme="orange"
                        opacity={
                            isCreditOpen || isDebitOpen || isMoneyOpen ? 0.4 : 1
                        }
                        onClick={() => {
                            onCloseCredit()
                            onCloseDebit()
                            onCloseMoney()
                            onToggleExchange()
                            isSubmitSuccessfulSet(false)
                        }}
                    >
                        <Text fontSize="medium">Troca</Text>
                    </Button>
                </Flex>

                <Credit
                    isOpen={isCreditOpen}
                    customer={customer}
                    isSubmitSuccessful={isSubmitSuccessful}
                    waiting={waiting}
                    isSubmitSuccessfulSet={isSubmitSuccessfulSet}
                    waitingSet={waitingSet}
                    pdfBase64={pdfBase64}
                    branchHasUpdatedCertificate={branchHasUpdatedCertificate}
                    finishSale={finishSale}
                />
                <Debit
                    isOpen={isDebitOpen}
                    customer={customer}
                    isSubmitSuccessful={isSubmitSuccessful}
                    waiting={waiting}
                    isSubmitSuccessfulSet={isSubmitSuccessfulSet}
                    waitingSet={waitingSet}
                    pdfBase64={pdfBase64}
                    branchHasUpdatedCertificate={branchHasUpdatedCertificate}
                    finishSale={finishSale}
                />
                <Money
                    isOpen={isMoneyOpen}
                    customer={customer}
                    isSubmitSuccessful={isSubmitSuccessful}
                    waiting={waiting}
                    isSubmitSuccessfulSet={isSubmitSuccessfulSet}
                    waitingSet={waitingSet}
                    pdfBase64={pdfBase64}
                    branchHasUpdatedCertificate={branchHasUpdatedCertificate}
                    finishSale={finishSale}
                />
                <Exchange
                    isOpen={isExchangeOpen}
                    customer={customer}
                    isSubmitSuccessful={isSubmitSuccessful}
                    waiting={waiting}
                    pdfBase64={pdfBase64}
                    isSubmitSuccessfulSet={isSubmitSuccessfulSet}
                    waitingSet={waitingSet}
                    branchHasUpdatedCertificate={branchHasUpdatedCertificate}
                    finishSale={finishSale}
                />
            </Flex>
        </Collapse>
    )
}
