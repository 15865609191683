import React, { useContext, useEffect, useState } from 'react'

import * as yup from 'yup'

import { Text, VStack } from '@chakra-ui/react'

import { SocketContext } from '../../../../../contexts/socket/SocketContext'
import { Select } from '../../../../Form/Select'

export function Attributes({
    setCustomOnSubmit,
    setCustomValidateFields,
    setValue,
}) {
    const { receiver } = useContext(SocketContext)

    const [attributes, setAttributes] = useState([])
    const [goodVariationAttributes, setGoodVariationAttributes] = useState({})

    const [avErrors, setAvErrors] = useState({})

    const [goods, setGoods] = useState([])
    const [selectedGood, setSelectedGood] = useState('')

    useEffect(() => {
        if (goods.length > 0) {
            setSelectedGood(goods[0]._id)
        }
    }, [goods])

    useEffect(() => {
        if (selectedGood !== '') {
            receiver.emit('good:get:attributes', { gdId: selectedGood })
            setValue('_gdId', selectedGood, { shouldDirty: true })
        }
    }, [selectedGood])

    useEffect(() => {
        setGoodVariationAttributes(
            attributes.reduce((acc, cur) => {
                const newAcc = { ...acc }
                newAcc[cur._id] = cur.values?.[0]?._id
                return newAcc
            }, {})
        )
    }, [attributes])

    useEffect(() => {
        receiver.on('good:get:attributes:ok', (response) => {
            setAttributes(response)
        })

        receiver.on('crud:good:find:ok', (response) => {
            setGoods(response.result)
        })

        receiver.emit('crud:good:find', {
            page: 0,
            size: Number.MAX_VALUE,
        })

        return () => {
            receiver.off('good:get:attributes:ok')
            receiver.off('crud:good:find:ok')
        }
    }, [])

    const insertGoodAttributes = (formData) => {
        /* parse the goodAttributesToInsert item to not send _id */
        Object.entries(goodVariationAttributes).forEach(
            ([attrKey, attrVal]) => {
                receiver.emit('crud:attribute:insert', {
                    _avId: attrVal,
                    _gdvId: formData._id,
                    _coId: formData._coId,
                })
            }
        )
    }

    const customSubmit = (formData) => {
        insertGoodAttributes(formData)

        return false
    }

    const customValidateFields = async () => {
        /* schema to validate name */
        const schema = yup
            .string()
            .min(1)
            .typeError('Selecione um valor para o atributo')
            .required('Campo obrigatório')

        /* object of errors */
        const newErrors = { ...avErrors }

        const isValid = await Object.entries(goodVariationAttributes).reduce(
            async (acc, [attrKey, attrValue]) => {
                let newAcc = await acc

                try {
                    /* if not valid, set a new error for this field */
                    await schema.validate(attrValue)
                    /* remove the error message */
                    delete newErrors[attrKey]
                } catch (e) {
                    newAcc = false
                    /* show the first error message */
                    newErrors[attrKey] = { message: e?.errors[0] }
                }

                return newAcc
            },
            Promise.resolve(true)
        )

        setAvErrors(newErrors)

        return isValid
    }

    /* effect to update customSubmit */
    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })

        setCustomValidateFields(() => {
            return customValidateFields
        })
    }, [goodVariationAttributes, avErrors])

    const handleAttributeValueChange = (e, akId) => {
        setGoodVariationAttributes({
            ...goodVariationAttributes,
            [akId]: e.target.value,
        })
    }

    return (
        <VStack spacing={4} w="100%" align="flex-start" px={1}>
            <Select
                id="_gdId"
                name="_gdId"
                label="Produto"
                options={goods}
                value={selectedGood}
                onChange={(e) => setSelectedGood(e.target.value)}
            />

            <Text fontSize="2xl">Atributos</Text>

            {goods
                .find((good) => good._id === selectedGood)
                ?._akIds.map((akId) => (
                    <Select
                        key={akId}
                        id={akId}
                        name={akId}
                        error={avErrors[akId]}
                        value={goodVariationAttributes[akId]}
                        onChange={(e) => handleAttributeValueChange(e, akId)}
                        label={
                            attributes.find((attr) => attr._id === akId)?.name
                        }
                        options={
                            attributes.find((attr) => attr._id === akId)
                                ?.values || []
                        }
                    />
                ))}
        </VStack>
    )
}
