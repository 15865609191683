import React, { createContext, useMemo, useState } from 'react'

export const CartContext = createContext({})

export function CartContextProvider({ children }) {
    const [cart, cartSet] = useState([])

    const addGoodToCart = (good) => {
        cartSet([...cart, good])
    }

    const removeGoodFromCart = (good) => {
        if (Array.isArray(good)) {
            cartSet([
                ...cart.filter(
                    (prod) => !good.some((gd) => gd._saleId === prod._saleId)
                ),
            ])
        } else {
            cartSet([...cart.filter((prod) => prod._saleId !== good._saleId)])
        }
    }

    const value = useMemo(
        () => ({
            cart,
            addGoodToCart,
            removeGoodFromCart,
        }),
        [cart, addGoodToCart, removeGoodFromCart]
    )

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}
