import React, { useContext } from 'react'

import { Button, Divider, Flex, Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { SaleContext } from '../../../../contexts/sale/SaleContext'

export function Total({ isMobileUser }) {
    const { sale } = useContext(SaleContext)

    return (
        <VStack width={['100%', '100%', '100%', '500px']}>
            <Text fontSize="2xl" color="orange.400" w="100%">
                Resumo da Venda
            </Text>

            <Flex
                width="100%"
                backgroundColor="whiteAlpha.900"
                borderRadius="2xl"
                minHeight={!isMobileUser ? '300px' : '200px'}
                p={4}
                direction="column"
                justifyContent="space-between"
            >
                <VStack width="100%" flex={1}>
                    <Text
                        width="100%"
                        fontSize="large"
                        display="flex"
                        justifyContent="space-between"
                    >
                        Subtotal de {sale.products.length}x itens:
                        <Text as="span" color="green.500">
                            {sale.totalFormatted}
                        </Text>
                    </Text>

                    <Divider />

                    {sale.products.map((product) => (
                        <Text w="100%" key={product._saleId}>
                            {`>  ${product.reference}`}
                        </Text>
                    ))}
                </VStack>
                <Divider />
                <Flex width="100%" justifyContent="flex-end" pt={4}>
                    <Link to="customer-select">
                        <Button
                            colorScheme="green"
                            disabled={sale.products.length === 0}
                        >
                            Finalizar separação de produtos
                        </Button>
                    </Link>
                </Flex>
            </Flex>
        </VStack>
    )
}
