import React, { useContext, useEffect, useState } from 'react'

import { Flex, SimpleGrid } from '@chakra-ui/react'

import { useHistory } from 'react-router-dom'
import {
    DigitalCertificate,
    InvoicesDownload,
    StateRegistration,
} from '../../components/Nfe'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Select } from '../../components/Form/Select'
import { SocketContext } from '../../contexts/socket/SocketContext'

export function Nfe() {
    const { receiver, plan } = useContext(SocketContext)
    const navigate = useHistory()

    const [branch, setBranch] = useState('')
    const [branches, setBranches] = useState([])

    useEffect(() => {
        if (plan !== 3) {
            navigate.goBack()
        }

        receiver.on('crud:branch:find:ok', (res) => {
            setBranches(res.result)
            setBranch(res.result[0]?._id)
        })

        receiver.emit('crud:branch:find', {
            page: 0,
            size: Number.MAX_VALUE,
        })

        return () => {
            receiver.off('crud:branch:find:ok')
        }
    }, [])

    return (
        <Flex height="max-content" p={4} gap={4} direction="column" w="100%">
            <Breadcrumb page="workspace-nfe" />
            <Flex
                w="100%"
                maxW={['100%', '100%', '723px', '933px', '1128px']}
                marginX="auto"
                direction="column"
                gap={4}
            >
                <Flex
                    w="100%"
                    p={4}
                    boxShadow="base"
                    borderRadius="2xl"
                    direction="column"
                    gap={4}
                >
                    <Select
                        id="branch"
                        name="branch"
                        label="Filial"
                        options={branches}
                        value={branch}
                        onChange={(e) => {
                            setBranch(e.target.value)
                        }}
                    />
                </Flex>
                <SimpleGrid w="100%" minChildWidth="180px" spacing={[4, 8]}>
                    <InvoicesDownload branch={branch} />
                </SimpleGrid>
            </Flex>
        </Flex>
    )
}
