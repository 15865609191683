import React, { PureComponent } from 'react'

import {
    Divider,
    Flex,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { paymentTypes } from '../../../../utils/enums'

const numberFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
})

const dateFormatter = new Intl.DateTimeFormat('pt-BR')

export class SintheticReport extends PureComponent {
    render() {
        const { sales, branch } = this.props

        const totalCost = sales?.reduce((acc, sale) => {
            return acc + sale.totalCost
        }, 0)
        const totalRetail = sales?.reduce((acc, sale) => {
            return acc + sale.totalRetail
        }, 0)
        const totalProfit = sales?.reduce((acc, sale) => {
            return acc + sale.totalProfit
        }, 0)

        return (
            <Flex w="100%" direction="column" p={8}>
                <Heading>Vendas - Relatório Sintético</Heading>
                <Text fontSize="2xl" marginBottom={4}>
                    Relatório de vendas da filial: {branch?.name}
                </Text>

                <Table variant="simple" size="sm" colorScheme="facebook">
                    <Thead>
                        <Tr borderBottom="1px solid #ddd">
                            <Th width="10%">Vendedor</Th>
                            <Th width="10%">Cliente</Th>
                            <Th width="70%">Items</Th>
                            <Th width="10%">Data</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {sales?.map((sale) => (
                            <>
                                <Tr
                                    borderBottom="1px solid #ddd"
                                    key={sale._id}
                                >
                                    <Td>{sale?.seller?.firstName || ''}</Td>
                                    <Td>{sale?.custommer?.firstName || ''}</Td>
                                    <Table variant="unstyled">
                                        <Thead>
                                            <Tr borderBottom="1px solid #ddd">
                                                <Th width="80%">Referência</Th>
                                                <Th width="10%">Custo</Th>
                                                <Th width="10%">Varejo</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {sale?.items.map((item) => (
                                                <Tr
                                                    _notLast={{
                                                        borderBottom:
                                                            '1px solid #ddd',
                                                    }}
                                                >
                                                    <Td>
                                                        {item.gdv.reference}
                                                    </Td>
                                                    <Td>
                                                        {
                                                            item.gdv
                                                                .formattedPrice
                                                                .cost
                                                        }
                                                    </Td>
                                                    <Td>
                                                        {
                                                            item.gdv
                                                                .formattedPrice
                                                                .retail
                                                        }
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                    <Td>{sale.date}</Td>
                                </Tr>
                                <Tr key={`${sale._id}-2`}>
                                    <Th width="10%" colSpan={2}>
                                        Tipo de pagamento
                                    </Th>
                                    {sale.payment.pt === 5 ? (
                                        <Th width="50%" colSpan={1}>
                                            Observação
                                        </Th>
                                    ) : (
                                        <Th width="50%" colSpan={1} />
                                    )}
                                    <Th width="40%" colSpan={1}>
                                        Id da Venda
                                    </Th>
                                </Tr>
                                <Tr
                                    key={`${sale._id}-3`}
                                    borderBottom="1px solid #ddd111"
                                >
                                    <Td colSpan={2}>
                                        {paymentTypes[sale.payment.pt]}
                                    </Td>
                                    {sale.payment.pt === 5 ? (
                                        <Td colSpan={1}>{sale.payment.obs}</Td>
                                    ) : (
                                        <Td colSpan={1} />
                                    )}
                                    <Td colSpan={1}>{sale._id}</Td>
                                </Tr>
                            </>
                        ))}
                    </Tbody>
                </Table>
                <Divider />
                <Table>
                    <Thead>
                        <Tr borderBottom="1px solid #ddd">
                            <Th w="60%" />
                            <Th textAlign="end">Custo</Th>
                            <Th textAlign="end">Varejo</Th>
                            <Th textAlign="end">Lucro</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr borderBottom="1px solid #ddd">
                            <Td />
                            <Td textAlign="end">
                                {numberFormatter.format(totalCost)}
                            </Td>
                            <Td textAlign="end">
                                {numberFormatter.format(totalRetail)}
                            </Td>
                            <Td textAlign="end">
                                {numberFormatter.format(totalProfit)}
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
                <Text position="absolute" top={4} right={4}>
                    Emitido em: {dateFormatter.format(new Date())}
                </Text>
            </Flex>
        )
    }
}
