import React from 'react'

import { Avatar, Text } from '@chakra-ui/react'

import { FieldLine } from '../../FieldLine'
import { genders, roles } from '../../../../utils/enums'

export function User({
    base64,
    firstName,
    lastName,
    dob,
    icNumber,
    address,
    contact,
    gender,
    role,
}) {
    return (
        <>
            <Text fontSize="2xl">Informações</Text>
            <Avatar src={base64} size="lg" name={`${firstName} ${lastName}`} />
            <FieldLine title="Primeiro nome" value={firstName} />
            <FieldLine title="Último nome" value={lastName} />
            <FieldLine title="Data de Nascimento" value={dob} />
            <FieldLine title="CPF" value={icNumber} />
            <FieldLine title="Rua" value={address?.street} />
            <FieldLine title="Bairro" value={address?.district} />
            <FieldLine title="Número" value={address?.number} />
            <FieldLine title="Cidade" value={address?.city} />
            <FieldLine
                title="Informações adicionais"
                value={address?.additionalInformation}
            />
            <FieldLine title="CEP" value={address?.zipCode} />
            <FieldLine title="Email" value={contact?.email} />
            <FieldLine title="Número telefone" value={contact?.phoneNumber} />
            <FieldLine title="Gênero" value={genders[gender]} />
            <FieldLine title="Permissão" value={roles[role]} />
        </>
    )
}
