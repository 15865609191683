import React, { useContext, useEffect, useState } from 'react'

import { Flex, SimpleGrid } from '@chakra-ui/react'
import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { FieldsList } from '../../List/FieldsList'

export function CustomBehaviorInstallment({ data }) {
    const { receiver } = useContext(SocketContext)
    const [dues, setDues] = useState([])

    useEffect(() => {
        receiver.on('crud:installment_due:find:ok', (response) => {
            setDues(response)
        })

        receiver.emit('crud:installment_due:find', { _instlId: data._id })

        return () => {
            receiver.off('crud:installment_due:find:ok')
        }
    }, [])

    return (
        <SimpleGrid
            w="100%"
            minChildWidth="280px"
            spacing={4}
            p={2}
            overflow="auto"
            maxH="calc(100vh - 346px)"
        >
            {dues.map((due) => (
                <Flex
                    key={due._id}
                    position="relative"
                    w="100%"
                    minH="150px"
                    boxShadow="base"
                    borderRadius="2xl"
                    p={4}
                >
                    <FieldsList data={due} crudName="installment_due" />
                </Flex>
            ))}
        </SimpleGrid>
    )
}
