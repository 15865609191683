import React, { useState, useEffect } from 'react'
import { IconButton, InputRightElement, Text } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

import { Input } from '../../../Form/Input'
import { Select } from '../../../Form/Select'
import { genders, roles } from '../../../../utils/enums'

import viacepApi from '../../../../services/viacep'

export function User({ errors, register, setValue }) {
    const [show, setShow] = useState(false)
    const [zipCode, setZipCode] = useState('')

    useEffect(() => {
        if (/^[0-9]{8}$/.test(zipCode)) {
            viacepApi.cepVerify(zipCode).then((res) => {
                setValue('address.zipCode', zipCode, { shouldDirty: true })
                setValue('address.street', res.data?.logradouro, {
                    shouldDirty: true,
                })
                setValue('address.district', res.data?.bairro, {
                    shouldDirty: true,
                })
                setValue('address.city', res.data?.localidade, {
                    shouldDirty: true,
                })
            })
        }
    }, [zipCode])

    return (
        <>
            <Text fontSize="2xl">Informações</Text>
            <Input
                name="firstName"
                error={errors.firstName}
                label="Primeiro nome"
                type="text"
                {...register('firstName')}
            />
            <Input
                name="lastName"
                error={errors.lastName}
                label="Último nome"
                type="text"
                {...register('lastName')}
            />
            <Input
                name="username"
                error={errors.username}
                label="Login"
                type="text"
                {...register('username')}
            />
            <Input
                name="password"
                error={errors.password}
                label="Senha"
                type={show ? 'text' : 'password'}
                addon={
                    <InputRightElement h="100%">
                        <IconButton
                            icon={!show ? <ViewIcon /> : <ViewOffIcon />}
                            h="100%"
                            colorScheme="orange"
                            onClick={() => setShow(!show)}
                        >
                            {show ? 'Hide' : 'Show'}
                        </IconButton>
                    </InputRightElement>
                }
                {...register('password')}
            />
            <Input
                name="dob"
                error={errors.dob}
                label="Data de Nascimento"
                type="date"
                {...register('dob')}
            />
            <Input
                name="icNumber"
                error={errors.icNumber}
                label="CPF - Somente números"
                type="number"
                step="1"
                {...register('icNumber')}
            />
            <Input
                error={errors.address?.zipCode}
                label="CEP"
                type="text"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
            />
            <Input
                name="address.city"
                error={errors.address?.city}
                label="Cidade"
                type="text"
                {...register('address.city')}
            />
            <Input
                name="address.street"
                error={errors.address?.street}
                label="Rua"
                type="text"
                {...register('address.street')}
            />
            <Input
                name="address.district"
                error={errors.address?.district}
                label="Bairro"
                type="text"
                {...register('address.district')}
            />
            <Input
                name="address.number"
                error={errors.address?.number}
                label="Número"
                type="text"
                {...register('address.number')}
            />
            <Input
                name="address.additionalInformation"
                error={errors.address?.additionalInformation}
                label="Informações adicionais"
                type="text"
                {...register('address.additionalInformation')}
            />
            <Input
                name="contact.email"
                error={errors.contact?.email}
                label="Email"
                type="text"
                {...register('contact.email')}
            />
            <Input
                name="contact.phoneNumber"
                error={errors.contact?.phoneNumber}
                label="Número telefone"
                type="text"
                {...register('contact.phoneNumber')}
            />
            <Select
                name="gender"
                error={errors.gender}
                label="Gênero"
                options={Object.entries(genders).map(([_id, name]) => ({
                    _id,
                    name,
                }))}
                {...register('gender')}
            />
            <Select
                name="role"
                error={errors.role}
                label="Tipo de usuário"
                options={Object.entries(roles).map(([_id, name]) => ({
                    _id,
                    name,
                }))}
                {...register('role')}
            />
            <Input
                name="enabled"
                error={errors.enabled}
                label="Ativo"
                type="checkbox"
                {...register('enabled')}
            />
        </>
    )
}
