import React, { memo, useMemo } from 'react'

import { VStack } from '@chakra-ui/react'
import { collectionSwitch } from './Fields/collectionSwitch'

function CrudDetailsSwitch({ data, crudName }) {
    const Component = useMemo(() => collectionSwitch[crudName], [crudName])

    return (
        <VStack spacing={4} w="100%" align="flex-start">
            <Component {...data} />
        </VStack>
    )
}

export default memo(CrudDetailsSwitch)
