import React, { useEffect, useState, useContext } from 'react'

import { SearchIcon } from '@chakra-ui/icons'
import {
    Center,
    Divider,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
} from '@chakra-ui/react'
import { CustomerCard } from '../../FinishSale/SaleDetails/CustomerCard'

import { SocketContext } from '../../../contexts/socket/SocketContext'

export function ClientModal({ isOpen, onClose }) {
    const { receiver } = useContext(SocketContext)

    const [search, searchSet] = useState('')
    const [results, resultsSet] = useState([])

    useEffect(() => {
        receiver.on('crud:customer:find:ok', (response) => {
            resultsSet(response.result)
        })

        receiver.emit('crud:customer:find', { page: 0, size: Number.MAX_VALUE })

        return () => {
            receiver.off('crud:customer:find:ok')
        }
    }, [])

    const onSearch = () => {
        resultsSet([])
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent mx={4} background="#E9E9E9" maxW="900px">
                <ModalHeader>Buscar Cliente</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={4}>
                    <InputGroup>
                        <Input
                            value={search}
                            onChange={(e) => searchSet(e.target.value)}
                            placeholder="Nome do cliente ou CPF"
                            pr="4.5rem"
                            background="white"
                        />
                        <InputRightElement>
                            <IconButton
                                icon={<SearchIcon color="white" />}
                                background="orange.500"
                                onClick={onSearch}
                            />
                        </InputRightElement>
                    </InputGroup>

                    <Center h={4}>
                        <Divider />
                    </Center>

                    <SimpleGrid
                        maxH="calc(100vh - 240px)"
                        overflowY="scroll"
                        spacing={4}
                        minChildWidth="300px"
                    >
                        {results.map((customer) => (
                            <CustomerCard
                                key={customer._id}
                                customer={customer}
                                onClose={onClose}
                            />
                        ))}
                    </SimpleGrid>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
