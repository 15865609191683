import React, { useEffect, useState } from 'react'

import { Divider } from '@chakra-ui/react'

import { Attributes } from './Attributes'
import { NfeFields } from './NfeFields'
import { Quantity } from './Quantity'
import { ImageUpload } from './ImageUpload'

export function CustomBehaviorGoodVariation({
    data,
    setCustomOnSubmit,
    setCustomValidateFields,
    setValue,
    register,
}) {
    const [customAttributesSubmit, setCustomAttributesSubmit] = useState(null)
    const [customQuantitySubmit, setCustomQuantitySubmit] = useState(null)
    const [customPhotosSubmit, setCustomPhotosSubmit] = useState(null)

    const [customValidateQuantityFields, setCustomValidateQuantityFields] =
        useState(null)

    useEffect(() => {
        setCustomOnSubmit(() => {
            return (formData) => {
                const attributes = customAttributesSubmit(formData)
                const quantities = customQuantitySubmit(formData)
                const photos = customPhotosSubmit(formData)
                return attributes && quantities && photos
            }
        })

        setCustomValidateFields(() => {
            return async () => {
                const quantityValid = await customValidateQuantityFields()

                return quantityValid
            }
        })
    }, [
        customAttributesSubmit,
        customQuantitySubmit,
        customPhotosSubmit,
        customValidateQuantityFields,
    ])

    return (
        <>
            <Attributes
                data={data}
                setCustomOnSubmit={setCustomAttributesSubmit}
                setValue={setValue}
            />

            <Divider />

            <ImageUpload
                setCustomOnSubmit={setCustomPhotosSubmit}
                data={data}
            />

            <Divider />

            <Quantity
                data={data}
                setCustomOnSubmit={setCustomQuantitySubmit}
                setCustomValidateFields={setCustomValidateQuantityFields}
            />

            <NfeFields data={data} register={register} setValue={setValue} />
        </>
    )
}
