import React, { useContext, useEffect, useState } from 'react'
import { Divider, Text } from '@chakra-ui/react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Input } from '../../../Form/Input'
import { Select } from '../../../Form/Select'
import { CustomBehaviorGood } from '../CustomBehaviors/good'

export function Good({
    errors,
    register,
    data,
    setDefaultValues,
    setCustomOnSubmit,
}) {
    const { receiver } = useContext(SocketContext)

    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])
    const [attributeKeys, setAttributeKeys] = useState([])

    useEffect(() => {
        if (brands.length > 0 && categories.length > 0) {
            setDefaultValues(data)
        }
    }, [brands, categories])

    useEffect(() => {
        /* load brands */
        receiver.on('crud:brand:find:ok', (response) => {
            setBrands(response.result)
        })

        /* load categories */
        receiver.on('crud:category:find:ok', (response) => {
            setCategories(response.result)
        })

        /* load attribute keys */
        receiver.on('crud:attribute_key:find:ok', (response) => {
            setAttributeKeys(response.result)
        })

        receiver.emit('crud:brand:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:category:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:attribute_key:find', {
            page: 0,
            size: Number.MAX_VALUE,
        })

        return () => {
            receiver.off('crud:brand:find:ok')
            receiver.off('crud:category:find:ok')
            receiver.off('crud:attribute_key:find:ok')
        }
    }, [])

    return (
        <>
            <Text fontSize="2xl">Informações</Text>
            <Input
                name="name"
                error={errors.name}
                label="Nome"
                type="text"
                {...register('name')}
            />
            <Input
                name="description"
                error={errors.description}
                label="Descrição"
                type="text"
                {...register('description')}
            />

            <CustomBehaviorGood
                data={data}
                setCustomOnSubmit={setCustomOnSubmit}
            />

            <Select
                name="_brId"
                error={errors._brId}
                label="Marca"
                options={brands}
                {...register('_brId')}
            />
            <Select
                name="_catId"
                error={errors._catId}
                label="Categoria"
                options={categories}
                {...register('_catId')}
            />
        </>
    )
}
