import React, { useCallback, useEffect, useState } from 'react'

import { Grid, Image } from 'semantic-ui-react'
import { Button, Flex, Heading, Text } from '@chakra-ui/react'

import { useHistory } from 'react-router-dom'
import icon from '../../assets/satellite.svg'

import { Container } from './styles'
import { api } from '../../services/api'

export function ServerDown(props) {
    const navigate = useHistory()
    const [loading, setLoading] = useState(false)

    const healthRequest = useCallback(() => {
        setLoading(true)
        api.get('/api/management/health')
            .then((res) => {
                navigate.replace('login')
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }, [navigate])

    useEffect(() => {
        healthRequest()
    }, [healthRequest])

    const messageRender = () => {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Image src={icon} size="small" centered />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Heading
                            as="h1"
                            size="xl"
                            color="#ffffff"
                            textAlign="center"
                        >
                            Desculpa, o problema não é você. Somos nós.
                        </Heading>
                        <Text fontSize="xl" color="#ffffff" textAlign="center">
                            Estamos diante de um problema de servidor. Tente
                            novamente mais tarde.
                        </Text>
                        <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                            marginTop={4}
                        >
                            <Button
                                type="button"
                                onClick={healthRequest}
                                isLoading={loading}
                            >
                                Tentar novamente
                            </Button>
                        </Flex>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    return (
        <Container>
            <div className="area">
                <ul className="circles">
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                </ul>
            </div>
            {messageRender()}
        </Container>
    )
}
