import React, { useState, useCallback, useContext } from 'react'

import { Button, Flex, Icon, IconButton } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'

import { useHistory } from 'react-router-dom'
import { CartContext } from '../../contexts/cart/CartContext'

export function Footer() {
    const { cart } = useContext(CartContext)

    const navigate = useHistory()

    const handleItemClick = (name) => {
        navigate.push(name)
    }

    const handleCalculatorClick = () => {
        alert('calculadora')
    }

    const CalculatorIcon = useCallback(() => {
        return (
            <Icon
                viewBox="0 0 448 512"
                color="#ffffff"
                style={{ width: '24px', height: '24px' }}
            >
                <path
                    fill="currentColor"
                    d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zM128 435.2c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V268.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v166.4zm0-256c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8V76.8C64 70.4 70.4 64 76.8 64h294.4c6.4 0 12.8 6.4 12.8 12.8v102.4z"
                />
            </Icon>
        )
    }, [])

    const BasketGenerateIcon = useCallback(
        (props) => (
            <Icon viewBox="0 0 576 512" {...props}>
                <path
                    fill="currentColor"
                    d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z"
                />
            </Icon>
        ),
        []
    )

    return (
        <Flex
            position="fixed"
            bottom="0px"
            w="100%"
            justify="space-between"
            backgroundColor="orange.500"
            h="50px"
            align="center"
            display={{ base: 'flex', md: 'none' }}
        >
            <IconButton
                icon={<SearchIcon fontSize="2xl" />}
                colorScheme="orange"
                onClick={() => handleItemClick('/search')}
                flex="1"
                h="100%"
            />

            <IconButton
                icon={<CalculatorIcon />}
                colorScheme="orange"
                onClick={handleCalculatorClick}
                flex="1"
                h="100%"
                disabled
            />

            <Button
                leftIcon={<BasketGenerateIcon boxSize={8} />}
                colorScheme="orange"
                alignSelf="center"
                onClick={() => handleItemClick('/cart')}
                flex={1}
                h="100%"
                p={0}
            >
                {cart.length}
            </Button>
        </Flex>
    )
}
