import React, { useContext, useEffect, useState } from 'react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Input } from '../../../Form/Input'
import { FieldLine } from '../../FieldLine'
import { CustomBehaviorInstallment } from '../CustomBehaviors/intallment'

export function Installment({ errors, register, data }) {
    const { receiver } = useContext(SocketContext)

    const [payment, setPayment] = useState({
        total: 0,
    })

    const [customer, setCustomer] = useState({
        firstName: '',
        lastName: '',
    })

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
    })

    useEffect(() => {
        receiver.on('crud:customer:find:ok', (response) => {
            setCustomer(response)
        })

        receiver.on('crud:user:find:ok', (response) => {
            setUser(response)
        })

        receiver.on('crud:sale:find:ok', (response) => {
            receiver.emit('crud:customer:find', { _id: response._custId })
            receiver.emit('crud:user:find', { _id: response._usrId })
        })

        receiver.on('crud:payment:find:ok', (response) => {
            receiver.emit('crud:sale:find', { _id: response._slId })
            setPayment(response)
        })

        receiver.emit('crud:payment:find', { _id: data._pmtId })

        return () => {
            receiver.off('crud:payment:find:ok')
            receiver.off('crud:sale:find:ok')
            receiver.off('crud:customer:find:ok')
        }
    }, [])

    return (
        <>
            <FieldLine
                title="Cliente"
                value={`${customer.firstName} ${customer.lastName}`}
            />
            <FieldLine
                title="Vendedor"
                value={`${user.firstName} ${user.lastName}`}
            />
            <FieldLine title="Total" value={payment.total} />
            <Input
                name="discount"
                error={errors.discount}
                label="Desconto"
                type="number"
                {...register('discount')}
            />
            <Input
                name="gracePeriod"
                error={errors.gracePeriod}
                label="Periodo de Carência"
                type="number"
                {...register('gracePeriod')}
            />
            <Input
                name="dir"
                error={errors.dir}
                label="DIR"
                type="number"
                {...register('dir')}
            />
            <Input
                name="fine"
                error={errors.fine}
                label="Fine"
                type="number"
                {...register('fine')}
            />

            <CustomBehaviorInstallment data={data} />
        </>
    )
}
