import React, { useContext } from 'react'

import { Flex } from '@chakra-ui/react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { DeleteButton } from './DeleteButton'
import { InsertButton } from './InsertButton'
import { Paginator } from './Paginator'
import { NumberOfItemsSelector } from './NumberOfItemsSelector'

export function Footer({
    path,
    crudName,
    checkboxes,
    deleteActive,
    data,
    dataSet,
    page,
    pageSet,
    pagesTotal,
    size,
    sizeSet,
    nextPage,
}) {
    const { user } = useContext(SocketContext)

    if (user.role === '0') {
        return (
            <Flex justifyContent="space-between" gap={4}>
                <DeleteButton
                    path={path}
                    crudName={crudName}
                    deleteActive={deleteActive}
                    checkboxes={checkboxes}
                    data={data}
                    dataSet={dataSet}
                />

                <NumberOfItemsSelector
                    size={size}
                    sizeSet={sizeSet}
                    pageSet={pageSet}
                />

                <Paginator
                    page={page}
                    pageSet={pageSet}
                    totalPages={pagesTotal}
                    nextPage={nextPage}
                />

                <InsertButton crudName={crudName} />
            </Flex>
        )
    }

    return (
        <Flex justifyContent="space-between" gap={4}>
            <NumberOfItemsSelector
                size={size}
                sizeSet={sizeSet}
                pageSet={pageSet}
            />
            <Paginator
                page={page}
                pageSet={pageSet}
                totalPages={pagesTotal}
                nextPage={nextPage}
            />
            <InsertButton crudName={crudName} />
        </Flex>
    )
}
