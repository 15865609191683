import React, { useCallback, useEffect, useState } from 'react'

import { Text } from '@chakra-ui/react'
import { Grid, Image } from 'semantic-ui-react'

import { useHistory } from 'react-router-dom'
import icon from '../../assets/favicon.svg'
import { api } from '../../services/api'

import { Container, Content } from './styles'

export function Loader() {
    const [timeout, timeoutSet] = useState(undefined)
    const navigate = useHistory()

    const healthRequest = useCallback(() => {
        if (!timeout) {
            const time = setTimeout(() => {
                api.get('/api/management/health')
                    .then((res) => {
                        navigate.replace('login')
                    })
                    .catch((e) => {
                        console.error(e, e.message)
                        navigate.replace('down')
                    })
                clearTimeout(timeout)
            }, 1000)
            timeoutSet(time)
        }
    }, [navigate, timeout])

    useEffect(() => {
        healthRequest()
    }, [healthRequest])

    return (
        <Container>
            <Content>
                <div className="loader" />
                <div className="position">
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Image src={icon} size="small" centered />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Text color="#ffffff" textAlign="center">
                                    powered by VSH
                                </Text>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Content>
        </Container>
    )
}
