import { Divider, Text, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Input } from '../../../Form/Input'
import { Select } from '../../../Form/Select'

export function Sale({ errors, register, data }) {
    const { receiver } = useContext(SocketContext)

    const [sales, salesSet] = useState([])
    const [branchs, branchsSet] = useState([])
    const [customers, customersSet] = useState([])
    const [users, usersSet] = useState([])
    const [goods, goodsSet] = useState([])

    useEffect(() => {
        receiver.on('crud:sale_item:find:ok', (response) => {
            salesSet(response)
        })
        receiver.on('crud:branch:find:ok', (response) => {
            branchsSet(response.result)
        })
        receiver.on('crud:customer:find:ok', (response) => {
            customersSet(response.result)
        })
        receiver.on('crud:user:find:ok', (response) => {
            usersSet(response.result)
        })
        receiver.on('crud:good:find:ok', (response) => {
            goodsSet(response)
        })

        receiver.emit('crud:sale_item:find', { _slId: data._id })
        receiver.emit('crud:branch:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:customer:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:user:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:good:find', { page: 0, size: Number.MAX_VALUE })

        return () => {
            receiver.off('crud:sale_item:find:ok')
            receiver.off('crud:branch:find:ok')
            receiver.off('crud:customer:find:ok')
            receiver.off('crud:user:find:ok')
            receiver.off('crud:good:find:ok')
        }
    }, [])

    return (
        <>
            <Input
                name="timestamp"
                error={errors.timestamp}
                label="Data"
                type="date"
                {...register('timestamp')}
            />
            <Select
                crudName="branch"
                name="_brId"
                error={errors._brId}
                label="Filial"
                options={branchs}
                {...register('_brId')}
            />
            <Select
                crudName="customer"
                name="_custId"
                error={errors._custId}
                label="Cliente"
                options={customers}
                {...register('_custId')}
            />
            <Select
                crudName="user"
                name="_usrId"
                error={errors._usrId}
                label="Vendedor"
                options={users}
                {...register('_usrId')}
            />
            <Divider />
            <VStack spacing={4} w="100%" align="flex-start">
                <Text>Produtos</Text>
                {sales.map((item, index) => (
                    <Select
                        key={item._id}
                        name={`_gdId${index}`}
                        label="Produto"
                        options={goods}
                        {...register(`_gdId${index}`)}
                    />
                ))}
            </VStack>
        </>
    )
}
