import React from 'react'

import { Input } from '../../../Form/Input'

export function Expense({ errors, register }) {
    return (
        <>
            <Input
                name="date"
                error={errors.date}
                label="Data"
                type="date"
                {...register('date')}
            />
            <Input
                name="value"
                error={errors.value}
                label="Valor"
                type="number"
                step="1"
                {...register('value')}
            />
        </>
    )
}
