import React, { useContext } from 'react'
import { Box, Divider, Stack, Text } from '@chakra-ui/react'
import { Attribute } from '../Attribute'
import { FilterContext } from '../../../../contexts/filter/FilterContext'

export function Categories() {
    const { filtersOptions } = useContext(FilterContext)

    return (
        <Stack>
            {Object.entries(filtersOptions).map(
                ([id, { name, attributes }]) => (
                    <Box key={id}>
                        <Text
                            marginBottom={2}
                            fontSize={['2xl', 'medium']}
                            color="blackAlpha.900"
                        >
                            {name}
                        </Text>
                        <Stack ml={2}>
                            {Object.entries(attributes).map(
                                ([idAttr, { name: attrName, values }]) => (
                                    <Attribute
                                        key={idAttr}
                                        attribute={attrName}
                                        options={values}
                                    />
                                )
                            )}
                            <Divider />
                        </Stack>
                    </Box>
                )
            )}
        </Stack>
    )
}
