import React from 'react'

import { Flex, SimpleGrid } from '@chakra-ui/react'

import { Breadcrumb } from '../../components/Breadcrumb'

import { SalesList, Reports } from '../../components/Sales'

export function Sales() {
    return (
        <Flex height="max-content" p={4} gap={4} direction="column" w="100%">
            <Breadcrumb page="workspace-sales" />
            <Flex
                w="100%"
                maxW={['100%', '100%', '723px', '933px', '1128px']}
                marginX="auto"
                direction="column"
                gap={4}
            >
                <SimpleGrid w="100%" minChildWidth="180px" spacing={[4, 8]}>
                    <SalesList />
                    <Reports />
                </SimpleGrid>
            </Flex>
        </Flex>
    )
}
