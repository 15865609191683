import React, { useContext, useState, useEffect } from 'react'

import {
    Button,
    Flex,
    Text,
    useBreakpointValue,
    useDisclosure,
    useToast,
    VStack,
} from '@chakra-ui/react'
import { AtSight } from './AtSight'
import { NfeModal } from '../../NfeModal'
import { CartContext } from '../../../../contexts/cart/CartContext'
import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { SaleContext } from '../../../../contexts/sale/SaleContext'

export function PaymentMethods({ customer }) {
    const toast = useToast()

    const { removeGoodFromCart } = useContext(CartContext)
    const { receiver, user } = useContext(SocketContext)
    const { sale, branch, isSaleTest } = useContext(SaleContext)

    const {
        isOpen: isAtSightOpen,
        onToggle: onToggleAtSight,
        onClose: onCloseAtSight,
    } = useDisclosure()

    const {
        isOpen: isInstallmentOpen,
        onToggle: onToggleInstallment,
        onClose: onCloseInstallment,
    } = useDisclosure()

    const {
        isOpen: isOpenNfe,
        onOpen: onOpenNfe,
        onClose: onCloseNfe,
    } = useDisclosure()

    const isMobileUser = useBreakpointValue({
        base: true,
        lg: false,
    })

    const [waiting, waitingSet] = useState(false)
    const [isSubmitSuccessful, isSubmitSuccessfulSet] = useState(false)

    const [pdfBase64, setPdfBase64] = useState('')

    const [productsForNfe, setProductsForNfe] = useState([])

    const [saleItems, setSaleItems] = useState([])
    const [payment, setPayment] = useState({})

    const finishSale = (data) => {
        if (isSaleTest) {
            receiver.emit('sale:finish', data)
        } else {
            setSaleItems(data.saleItems)
            setPayment(data.payment)
            setTimeout(() => {
                receiver.emit('sale:verifyInventory', data)
            }, 100)
        }
    }

    const emitNfe = () => {
        receiver.emit('nfe:productInvoice:insert', {
            branchId: branch,
            user,
            saleItems,
            payment,
            nfe: {
                items: productsForNfe.reduce((acc, product) => {
                    if (!product.saleTest) {
                        return [
                            ...acc,
                            {
                                ...product.nfe,
                                unitAmount: parseFloat(
                                    product?.price?.retail || 0
                                ),
                                quantity: 1,
                            },
                        ]
                    }
                    return acc
                }, []),
                buyer: {
                    ...customer,
                },
            },
        })
    }

    const emitNfce = () => {
        receiver.emit('nfe:consumerInvoice:insert', {
            branchId: branch,
            user,
            saleItems,
            payment,
            nfe: {
                items: productsForNfe.reduce((acc, product) => {
                    if (!product.saleTest) {
                        return [
                            ...acc,
                            {
                                ...product.nfe,
                                unitAmount: parseFloat(
                                    product?.price?.retail || 0
                                ),
                                quantity: 1,
                            },
                        ]
                    }
                    return acc
                }, []),
                buyer: {
                    ...customer,
                },
            },
        })
    }

    const onWebhookAnswer = (success) => {
        isSubmitSuccessfulSet(success)
        waitingSet(false)
    }

    useEffect(() => {
        receiver.on('sale:finish:ok', (response) => {
            setPdfBase64(response.cupomFiscal)

            toast({
                title: 'Venda',
                description: 'Venda realizada com sucesso',
                position: 'top',
                status: 'success',
                isClosable: true,
            })

            removeGoodFromCart(sale.products)
            isSubmitSuccessfulSet(true)
            waitingSet(false)
        })

        receiver.on('sale:finish:error', (response) => {
            isSubmitSuccessfulSet(false)
            waitingSet(false)
            toast({
                title: 'Venda',
                description: `Erro: ${
                    response?.errors?.[0]?.message ||
                    response?.message ||
                    response ||
                    'não identificado'
                }`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        })

        receiver.on('sale:verifyInventory:ok', (response) => {
            const products = [...sale.products]

            setProductsForNfe(products)
            onOpenNfe()
        })

        receiver.on('sale:verifyInventory:error', (response) => {
            isSubmitSuccessfulSet(false)
            waitingSet(false)
            toast({
                title: 'Venda',
                description: `Erro: ${
                    response?.errors?.[0]?.message ||
                    response?.message ||
                    response ||
                    'não identificado'
                }`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        })

        receiver.on('nfe:productInvoice:insert:ok', (response) => {
            toast({
                title: 'Nota fiscal de produto',
                description: `Solicitada com sucesso`,
                position: 'top',
                status: 'success',
                isClosable: true,
            })

            removeGoodFromCart(sale.products)
        })

        receiver.on('nfe:consumerInvoice:insert:ok', (response) => {
            toast({
                title: 'Nota fiscal do consumidor',
                description: `Solicitada com sucesso`,
                position: 'top',
                status: 'success',
                isClosable: true,
            })

            removeGoodFromCart(sale.products)
        })

        receiver.on('nfe:productInvoice:insert:error', (response) => {
            isSubmitSuccessfulSet(false)
            waitingSet(false)
            toast({
                title: 'Nota fiscal de produto',
                description: `Erro: ${
                    response?.errors?.[0]?.message || 'não identificado'
                }`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        })

        receiver.on('nfe:consumerInvoice:insert:error', (response) => {
            isSubmitSuccessfulSet(false)
            waitingSet(false)
            toast({
                title: 'Nota fiscal do consumidor',
                description: `Erro: ${
                    response?.errors?.[0]?.message || 'não identificado'
                }`,
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        })

        return () => {
            receiver.off('sale:finish:ok')
            receiver.off('sale:finish:error')
            receiver.off('nfe:productInvoice:insert:error')
            receiver.off('nfe:productInvoice:insert:ok')
            receiver.off('nfe:consumerInvoice:insert:error')
            receiver.off('nfe:consumerInvoice:insert:ok')
            receiver.off('sale:verifyInventory:ok')
            receiver.off('sale:verifyInventory:error')
        }
    }, [branch])

    return (
        <VStack w="100%" justify="start">
            {!isSaleTest && (
                <Button
                    position="absolute"
                    colorScheme="orange"
                    top={4}
                    right={4}
                    onClick={onOpenNfe}
                    disabled={
                        saleItems?.length === 0 &&
                        Object.keys(payment).length === 0
                    }
                >
                    Nota Fiscal
                </Button>
            )}
            <Flex w="100%" direction={isMobileUser ? 'column' : 'row'} gap={4}>
                <Flex flex="1" direction="column" gap={4}>
                    <Button
                        minH="60px"
                        maxH="60px"
                        boxShadow="base"
                        colorScheme="orange"
                        opacity={isInstallmentOpen ? 0.4 : 1}
                        onClick={() => {
                            onCloseInstallment()
                            onToggleAtSight()
                        }}
                    >
                        <Text fontSize="medium">À Vista</Text>
                    </Button>
                    <AtSight
                        isMobileUser={isMobileUser}
                        isOpen={isAtSightOpen}
                        customer={customer}
                        onOpenNfe={onOpenNfe}
                        pdfBase64={pdfBase64}
                        isSubmitSuccessfulSet={isSubmitSuccessfulSet}
                        isSubmitSuccessful={isSubmitSuccessful}
                        waiting={waiting}
                        waitingSet={waitingSet}
                        finishSale={finishSale}
                    />
                    <NfeModal
                        isOpen={isOpenNfe}
                        onClose={onCloseNfe}
                        emitNfe={emitNfe}
                        emitNfce={emitNfce}
                        branchId={branch}
                        onWebhookAnswer={onWebhookAnswer}
                    />
                </Flex>
            </Flex>
        </VStack>
    )
}
