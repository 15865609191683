import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    VStack,
} from '@chakra-ui/react'

import axios from 'axios'
import { SocketContext } from '../../contexts/socket/SocketContext'

export function NfeModal({
    isOpen,
    onClose,
    emitNfe,
    emitNfce,
    branchId,
    onWebhookAnswer,
}) {
    const toast = useToast()
    const { receiver, branches } = useContext(SocketContext)

    const nfeButtonRef = useRef(null)
    const nfceButtonRef = useRef(null)

    const selectedBranch = useMemo(() => {
        return branches.find((b) => b._id === branchId)
    }, [branchId])

    const [loadingNfe, setLoadingNfe] = useState(false)
    const [loadingNfce, setLoadingNfce] = useState(false)

    const [nfeResponse, setNfeResponse] = useState({
        error: false,
        response: undefined,
    })
    const [nfceResponse, setNfceResponse] = useState({
        error: false,
        response: undefined,
    })

    const [nfePdf, setNfePdf] = useState(undefined)
    const [nfcePdf, setNfcePdf] = useState(undefined)

    useEffect(() => {
        receiver.on('nfe:productinvoice:webhook:error', (res) => {
            setNfeResponse({
                error: true,
                response: res,
            })
            onWebhookAnswer(false)
        })

        receiver.on('nfe:productinvoice:webhook:success', (res) => {
            setNfeResponse({
                error: false,
                response: res,
            })
            onWebhookAnswer(true)
        })

        receiver.on('nfe:consumerinvoice:webhook:error', (res) => {
            setNfceResponse({
                error: true,
                response: res,
            })
            onWebhookAnswer(false)
        })

        receiver.on('nfe:consumerinvoice:webhook:success', (res) => {
            setNfceResponse({
                error: false,
                response: res,
            })
            onWebhookAnswer(true)
        })

        return () => {
            receiver.off('nfe:productinvoice:webhook:error')
            receiver.off('nfe:productinvoice:webhook:success')
            receiver.off('nfe:consumerinvoice:webhook:error')
            receiver.off('nfe:consumerinvoice:webhook:success')
        }
    }, [receiver])

    useEffect(() => {
        if (loadingNfe) {
            if (nfeResponse.error) {
                toast({
                    title: 'Nota Fiscal de Produto',
                    description: `Lista de eventos: ${(
                        nfeResponse.response?.events?.map(
                            (event) => event?.data?.message
                        ) || []
                    )
                        .filter((msg) => !!msg)
                        .reverse()
                        .join(', ')}`,
                    position: 'top',
                    status: 'error',
                    isClosable: true,
                    duration: null,
                })
                setLoadingNfe(false)
            } else if (!nfeResponse.error && nfeResponse.response) {
                toast({
                    title: 'Nota Fiscal de Produto',
                    description: `${nfeResponse.response?.events[0]}`,
                    position: 'top',
                    status: 'success',
                    isClosable: true,
                    duration: null,
                })

                axios
                    .get(nfeResponse.response.pdf, { responseType: 'blob' })
                    .then((res) => {
                        const download = URL.createObjectURL(
                            new Blob([res.data])
                        )
                        setNfePdf(download)
                    })
                    .catch((e) => {
                        toast({
                            title: 'Nota Fiscal de Produto',
                            description: `Ocorreu um erro ao criar o link dinamico de download da nota fiscal, favor utilizar o link: ${nfeResponse.response.pdf}`,
                            position: 'top',
                            status: 'error',
                            isClosable: true,
                            duration: null,
                        })
                        setNfePdf(nfeResponse.response.pdf)
                    })
                    .finally(() => {
                        setLoadingNfe(false)
                    })
            }
        }
    }, [loadingNfe, nfeResponse])

    useEffect(() => {
        if (loadingNfce) {
            if (nfceResponse.error) {
                toast({
                    title: 'Nota Fiscal do consumidor',
                    description: `Lista de eventos: ${(
                        nfceResponse.response?.events?.map(
                            (event) => event?.data?.message
                        ) || []
                    )
                        .filter((msg) => !!msg)
                        .reverse()
                        .join(', ')}`,
                    position: 'top',
                    status: 'error',
                    isClosable: true,
                    duration: null,
                })
                setLoadingNfce(false)
            } else if (!nfceResponse.error && nfceResponse.response) {
                toast({
                    title: 'Nota Fiscal do consumidor',
                    description: `Lista de eventos: ${(
                        nfceResponse.response?.events?.map(
                            (event) => event?.data?.message
                        ) || []
                    )
                        .filter((msg) => !!msg)
                        .reverse()
                        .join(', ')}`,
                    position: 'top',
                    status: 'success',
                    isClosable: true,
                    duration: null,
                })

                axios
                    .get(nfceResponse.response.pdf, { responseType: 'blob' })
                    .then((res) => {
                        const download = URL.createObjectURL(
                            new Blob([res.data])
                        )
                        setNfcePdf(download)
                    })
                    .catch((e) => {
                        toast({
                            title: 'Nota Fiscal do consumidor',
                            description: `Ocorreu um erro ao criar o link dinamico de download da nota fiscal, favor utilizar o link: ${nfceResponse.response.pdf}`,
                            position: 'top',
                            status: 'error',
                            isClosable: true,
                            duration: null,
                        })
                        setNfcePdf(nfceResponse.response.pdf)
                    })
                    .finally(() => {
                        setLoadingNfce(false)
                    })
            }
        }
    }, [loadingNfce, nfceResponse])

    useEffect(() => {
        if (nfeButtonRef.current && nfePdf) {
            nfeButtonRef.current?.click()
        }
    }, [nfeButtonRef.current, nfePdf])

    useEffect(() => {
        if (nfceButtonRef.current && nfcePdf) {
            nfceButtonRef.current?.click()
        }
    }, [nfceButtonRef.current, nfcePdf])

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent mx={4}>
                <ModalHeader color="orange.500">Nota Fiscal</ModalHeader>
                <Divider />

                <ModalBody>
                    <VStack spacing={4} w="100%" justifyContent="space-between">
                        {selectedBranch?.nfeio?.canEmitNfe && (
                            <VStack justifyContent="center" align="center">
                                <Button
                                    colorScheme="orange"
                                    onClick={() => {
                                        setNfePdf(undefined)
                                        setNfeResponse({
                                            error: false,
                                            response: undefined,
                                        })
                                        setLoadingNfe(true)
                                        emitNfe()
                                    }}
                                    minH="60px"
                                    minW="150px"
                                    isLoading={loadingNfe}
                                >
                                    Gerar NF-e
                                </Button>
                                {nfePdf && (
                                    <a
                                        download={`nfe-${new Date().getTime()}.pdf`}
                                        href={nfePdf}
                                        ref={nfeButtonRef}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Baixar nota fiscal de produto
                                    </a>
                                )}
                            </VStack>
                        )}

                        {selectedBranch?.nfeio?.canEmitNfe &&
                            selectedBranch?.nfeio?.canEmitNfce && <Divider />}

                        {selectedBranch?.nfeio?.canEmitNfce && (
                            <VStack justifyContent="center" align="center">
                                <Button
                                    colorScheme="orange"
                                    onClick={() => {
                                        setNfcePdf(undefined)
                                        setNfceResponse({
                                            error: false,
                                            response: undefined,
                                        })
                                        setLoadingNfce(true)
                                        emitNfce()
                                    }}
                                    minH="60px"
                                    minW="150px"
                                    isLoading={loadingNfce}
                                >
                                    Gerar NFC-e
                                </Button>
                                {nfcePdf && (
                                    <a
                                        download={`nfe-${new Date().getTime()}.pdf`}
                                        href={nfcePdf}
                                        ref={nfceButtonRef}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Baixar nota fiscal do consumidor
                                    </a>
                                )}
                            </VStack>
                        )}
                    </VStack>
                </ModalBody>
                <Divider />

                <ModalFooter>
                    <Button colorScheme="blackAlpha" onClick={onClose}>
                        Fechar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
