import React, { useContext, useEffect, useState } from 'react'

import { v4 as uuid } from 'uuid'
import {
    Divider,
    Flex,
    Image,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'

import { FieldLine } from '../../FieldLine'

export function GoodVariation({ _id, _gdId, reference, price, saleTest, nfe }) {
    const { receiver } = useContext(SocketContext)

    const [attributes, setAttributes] = useState([])
    const [goodVariationAttributes, setGoodVariationAttributes] = useState([])

    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])

    const [quantities, setQuantities] = useState(0)
    const [branches, setBranches] = useState([])

    const [good, setGood] = useState([])

    const [pictures, setPictures] = useState([])

    useEffect(() => {
        if (branches.length > 0) {
            receiver.emit('good_variation:get:quantity', { _gdvId: _id })
        }
    }, [branches])

    /* effect to update lists of attributes and good attributes */
    useEffect(() => {
        receiver.on('good_variation:get:attributes:ok', (response) => {
            setGoodVariationAttributes(response)
        })

        receiver.on('good:get:attributes:ok', (response) => {
            setAttributes(response)
        })

        /* load brands */
        receiver.on('crud:brand:find:ok', (response) => {
            setBrands(response.result)
        })

        /* load categories */
        receiver.on('crud:category:find:ok', (response) => {
            setCategories(response.result)
        })

        /* load good */
        receiver.on('crud:good:find:ok', (response) => {
            setGood(response)
        })

        receiver.on('crud:branch:find:ok', (response) => {
            setBranches(response.result)
        })

        receiver.on('good_variation:get:quantity:ok', (response) => {
            setQuantities(response)
        })

        receiver.on('good_variation:get:pictures:ok', (response) => {
            setPictures(
                response.pictures.map((pic) => {
                    const newPic = { ...pic }
                    newPic.id = uuid()
                    return newPic
                })
            )
        })

        receiver.emit('good_variation:get:attributes', { gdvId: _id })
        receiver.emit('good:get:attributes', { gdId: _gdId })
        receiver.emit('crud:brand:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:category:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('crud:good:find', { _id: _gdId })
        receiver.emit('crud:branch:find', { page: 0, size: Number.MAX_VALUE })
        receiver.emit('good_variation:get:pictures', {
            _id,
        })

        return () => {
            receiver.off('good_variation:get:attributes:ok')
            receiver.off('good:get:attributes:ok')
            receiver.off('crud:brand:find:ok')
            receiver.off('crud:category:find:ok')
            receiver.off('crud:good:find:ok')
            receiver.off('crud:branch:find:ok')
            receiver.off('good_variation:get:pictures:ok')
        }
    }, [])

    return (
        <>
            <Text fontSize="2xl">Informações</Text>

            <FieldLine title="Nome" value={`${good.name}`} />
            <FieldLine title="Referência" value={`${reference}`} />
            <FieldLine title="Descrição" value={`${good.description}`} />
            <FieldLine title="Custo" value={price.cost} />
            <FieldLine title="Preço de venda" value={price.retail} />
            <FieldLine
                title="Marca"
                value={brands.find((br) => br._id === good._brId)?.name}
            />
            <FieldLine
                title="Categoria"
                value={categories.find((cat) => cat._id === good._catId)?.name}
            />

            <Divider />

            <Text fontSize="2xl">Atributos</Text>

            {Object.entries(goodVariationAttributes).map(([akId, goodAttr]) => (
                <FieldLine
                    key={akId}
                    title={attributes.find((attr) => attr._id === akId)?.name}
                    value={
                        attributes
                            .find((attr) => attr._id === akId)
                            ?.values?.find(
                                (attrVal) => attrVal._id === goodAttr._avId
                            )?.name
                    }
                />
            ))}

            <Divider />

            <Text fontSize="2xl">Fotos</Text>
            {pictures.length > 0 && (
                <Flex
                    w="100%"
                    border="1px solid"
                    borderStyle="dashed"
                    borderColor="blackAlpha.400"
                    my={4}
                    borderRadius="2xl"
                >
                    <SimpleGrid
                        minChildWidth="280px"
                        w="100%"
                        p={4}
                        direction="row"
                        gap={4}
                        justifyContent="start"
                        alignItems="center"
                        overflowY="scroll"
                    >
                        {pictures?.map((file) => (
                            <Flex
                                flex={1}
                                h="100%"
                                direction="column"
                                gap={4}
                                key={file.id}
                            >
                                <Image
                                    flex={1}
                                    maxH="220px"
                                    width="auto"
                                    maxW="280px"
                                    src={file.path}
                                    borderRadius="2xl"
                                    objectFit="cover"
                                />
                            </Flex>
                        ))}
                    </SimpleGrid>
                </Flex>
            )}

            <Divider />

            <Text fontSize="2xl">Quantidade</Text>

            {branches.map((branch) => (
                <FieldLine
                    key={branch?._id}
                    title={branch?.name}
                    value={quantities[branch?._id]?.quantity}
                />
            ))}

            <VStack spacing={4} w="100%" alignItems="flex-start">
                <Divider />

                <Text fontSize="2xl">
                    Informações para geração de nota fiscal
                </Text>

                <FieldLine
                    title="Produto para venda teste"
                    value={saleTest ? 'Sim' : 'Não'}
                />

                {!saleTest && (
                    <>
                        <FieldLine title="Código" value={nfe.code} />
                        <FieldLine title="CFOP" value={nfe.cfop} />
                        <FieldLine title="Código NCM" value={nfe.ncm} />
                        <FieldLine title="Código GTIN" value={nfe.codeGTIN} />
                        <FieldLine
                            title="CEST - Código especificador da substituição tributária"
                            value={nfe.cest}
                        />
                        <FieldLine title="Descrição" value={nfe.description} />
                        <VStack w="100%" alignItems="start" pt={4}>
                            <Text fontWeight="bold">Taxa</Text>

                            <FieldLine
                                title="Valor aproximado total de tributos federais, estaduais e municipais"
                                value={nfe.tax.totalTax}
                            />

                            <VStack w="100%" alignItems="start" pt={4}>
                                <Text fontWeight="bold">ICMS</Text>
                                <FieldLine
                                    title="(CST) Tributação do ICMS"
                                    value={nfe.tax.icms.cst}
                                />
                                <FieldLine
                                    title="CSOSN"
                                    value={nfe.tax.icms.csosn}
                                />
                                <FieldLine
                                    title="Origem da mercadoria"
                                    value={nfe.tax.icms.origin}
                                />
                            </VStack>

                            <VStack w="100%" alignItems="start" pt={4}>
                                <Text fontWeight="bold">PIS</Text>

                                <FieldLine
                                    title="(CST) Código de Situação Tributária do PIS"
                                    value={nfe.tax.pis.cst}
                                />
                            </VStack>

                            <VStack w="100%" alignItems="start" pt={4}>
                                <Text fontWeight="bold">Cofins</Text>

                                <FieldLine
                                    title="(CST) Código de Situação Tributária da COFINS"
                                    value={nfe.tax.cofins.cst}
                                />
                            </VStack>
                        </VStack>
                    </>
                )}
            </VStack>
        </>
    )
}
