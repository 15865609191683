import React from 'react'

import { Flex } from '@chakra-ui/react'
import { Breadcrumb } from '../../../components/Breadcrumb'
import CrudDetailsSwitch from '../../../components/Crud/View/CrudDetailsSwitch'

export function CrudView({ location }) {
    const { data, crudName, path } = location.state

    return (
        <Flex height="max-content" p={4} gap={4} direction="column" w="100%">
            <Breadcrumb page={path} />
            <Flex
                backgroundColor="white"
                w="100%"
                maxW={['100%', '100%', '723px', '933px', '1128px']}
                marginX="auto"
                borderRadius="2xl"
                p={4}
                direction="column"
                gap={4}
                overflowY="scroll"
                maxH={[
                    'calc(100vh - 170px)',
                    'calc(100vh - 170px)',
                    'calc(100vh - 114px)',
                ]}
            >
                <CrudDetailsSwitch data={data} crudName={crudName} />
            </Flex>
        </Flex>
    )
}
