import React from 'react'
import { Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { options } from './options'

export function SearchOptions() {
    return (
        <Flex
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            direction="column"
            gap={4}
        >
            <Heading size="lg">Buscar produtos</Heading>
            <SimpleGrid w="100%" minChildWidth="150px" spacing={[4, 8]}>
                {options.map(({ name, path, color }) => {
                    return (
                        <Link key={name} to={path}>
                            <Flex
                                background={color}
                                height="150px"
                                borderRadius="2xl"
                                p={8}
                                cursor="pointer"
                                transition="0.5s filter"
                                _hover={{
                                    filter: 'brightness(0.85)',
                                }}
                            >
                                <Text fontSize="2xl" color="white">
                                    {name}
                                </Text>
                            </Flex>
                        </Link>
                    )
                })}
            </SimpleGrid>
        </Flex>
    )
}
