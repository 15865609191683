import React from 'react'
import { VStack } from '@chakra-ui/react'
import { Breadcrumb } from '../../components/Breadcrumb'
import { Sales } from '../../components/Cart/Sales'

export function Cart() {
    return (
        <VStack flex="1" p={4} spacing={4}>
            <Breadcrumb page="cart" />
            <Sales />
        </VStack>
    )
}
