import React, { useContext, useEffect } from 'react'

import {
    Flex,
    Image,
    Text,
    Icon as ChakraIcon,
    Divider,
    Checkbox,
    useBreakpointValue,
} from '@chakra-ui/react'

import { Icon } from 'semantic-ui-react'
import { CartContext } from '../../../../../../contexts/cart/CartContext'
import { SocketContext } from '../../../../../../contexts/socket/SocketContext'
import { SaleContext } from '../../../../../../contexts/sale/SaleContext'

export function Content({ product }) {
    const { user } = useContext(SocketContext)
    const { removeGoodFromCart } = useContext(CartContext)
    const { addGoodToSale, removeGoodFromSale, resetSale } =
        useContext(SaleContext)

    useEffect(() => {
        resetSale()
    }, [resetSale])

    return (
        <Flex gap={8} flexDirection={['column', 'column', 'row']}>
            <Flex
                gap={4}
                alignItems="center"
                position="absolute"
                right={4}
                top={4}
            >
                <ChakraIcon
                    aria-label="remove-product-from-cart"
                    name="trash"
                    cursor="pointer"
                    size="large"
                    as={Icon}
                    onClick={() => {
                        removeGoodFromSale(product)
                        removeGoodFromCart(product)
                    }}
                />

                <Checkbox
                    colorScheme="green"
                    size="lg"
                    onChange={(e) => {
                        if (e.target.checked) {
                            addGoodToSale(product)
                        } else {
                            removeGoodFromSale(product)
                        }
                    }}
                />
            </Flex>
            <Image
                maxW="200px"
                maxH="200px"
                h="auto"
                w="auto"
                src={product.pictures[0].path}
                alt={product.pictures[0]._id}
                alignSelf="center"
            />

            <Flex direction="column" width="100%">
                <Flex
                    width={['100%', '100%', '80%']}
                    justifyContent="space-between"
                    gap={8}
                >
                    <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        wordBreak="break-all"
                    >
                        {product.name}
                    </Text>
                </Flex>

                <Text> Ref: {product.reference} </Text>

                <Divider h={2} />

                <Text fontSize={18}>
                    Vendido por:{' '}
                    <Text fontSize={18} as="span" color="orange.500">
                        {user.firstName}
                    </Text>
                </Text>

                {product.attributes.map(({ attrKey, attrValue }) => (
                    <Text
                        key={`${attrKey?._id}-${attrValue?._id}`}
                        fontSize={18}
                    >
                        {attrKey.name}:{' '}
                        <Text fontSize={18} as="span" color="green.500">
                            {attrValue?.name}
                        </Text>
                    </Text>
                ))}
            </Flex>
        </Flex>
    )
}
