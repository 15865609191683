import React, { useContext } from 'react'
import {
    Button,
    Center,
    Divider,
    Flex,
    Text,
    useBreakpointValue,
    useToast,
} from '@chakra-ui/react'
import { Link, useHistory } from 'react-router-dom'
import { SaleContext } from '../../../contexts/sale/SaleContext'

export function ClientBox({ onOpen }) {
    const toast = useToast()

    const { isSaleTest } = useContext(SaleContext)

    const navigate = useHistory()

    const isMobileUser = useBreakpointValue({
        base: true,
        lg: false,
    })

    const handleWithoutClient = () => {
        if (isSaleTest) {
            navigate.push('/finish-sale', {
                customer: {
                    firstName: 'Cliente',
                    lastName: 'não registrado',
                    icNumber: '---.---.---.--',
                    dob: '--/--/--',
                    address: {
                        street: '-----',
                        district: '-----',
                        number: '---',
                        zipCode: '--.---.---',
                    },
                    city: {
                        name: '----',
                    },
                },
            })
        } else {
            toast({
                title: 'Erro',
                description:
                    'Como a venda irá gerar nota fiscal, é impossível vender para um cliente sem registro',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        }
    }

    return (
        <Flex
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            backgroundColor="whiteAlpha.900"
            p={8}
            borderRadius="2xl"
            direction={isMobileUser ? 'column' : 'row'}
        >
            <Center flex={1} flexDirection="column" gap={8}>
                <Text fontSize="2xl">Cliente já registrado?</Text>
                <Button colorScheme="orange" w={260} onClick={onOpen}>
                    Buscar cliente
                </Button>
            </Center>

            <Center w={!isMobileUser && 10} h={isMobileUser && 10}>
                <Divider
                    orientation={isMobileUser ? 'horizontal' : 'vertical'}
                />
            </Center>

            <Center flex={1} flexDirection="column" gap={8}>
                <Text fontSize="2xl">Novo cliente?</Text>
                <Link
                    to={{
                        pathname: '/insert',
                        state: {
                            path: 'workspace-customers',
                            crudName: 'customer',
                        },
                    }}
                >
                    <Button colorScheme="green" w={260}>
                        Registrar cliente
                    </Button>
                </Link>
                <Button
                    colorScheme="blue"
                    w={260}
                    onClick={handleWithoutClient}
                >
                    Vender sem registrar cliente
                </Button>
            </Center>
        </Flex>
    )
}
