import React, { useContext, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useHistory } from 'react-router-dom'

import { Divider, Flex, useToast, VStack } from '@chakra-ui/react'
import { Breadcrumb } from '../../../components/Breadcrumb'
import { Footer } from '../../../components/Crud/Insert/Footer'
import { SocketContext } from '../../../contexts/socket/SocketContext'
import { CrudInsertSwitch } from '../../../components/Crud/Insert/CrudInsertSwitch'
import { breadcrumbs } from '../../../components/Breadcrumb/breadcrumbs'
import { formSchemas } from '../../../utils/schemas'

export function CrudInsert({ location }) {
    const { crudName, path } = location.state

    const history = useHistory()
    const toast = useToast()

    const { receiver, user } = useContext(SocketContext)
    const [customOnSubmit, setCustomOnSubmit] = useState(null)
    const [customValidateFields, setCustomValidateFields] = useState(null)

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(formSchemas[crudName]),
    })

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            toast({
                title: breadcrumbs[path]?.name,
                description: 'Verifique os campos sinalizados',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        }
    }, [errors])

    const traitProps = (formData) => {
        const newFormData = { ...formData }

        newFormData._coId = user._coId

        if (crudName === 'user' || crudName === 'customer') {
            newFormData.dob = newFormData?.dob?.toISOString().substr(0, 10)
        }

        return newFormData
    }

    const onSubmit = async (formData) => {
        const newFormData = traitProps(formData)

        if (customValidateFields) {
            if (await customValidateFields()) {
                receiver.emit(`crud:${crudName}:insert`, newFormData)
            } else {
                toast({
                    title: breadcrumbs[path]?.name,
                    description: 'Verifique os campos sinalizados',
                    position: 'top',
                    status: 'error',
                    isClosable: true,
                })
            }
        } else {
            receiver.emit(`crud:${crudName}:insert`, newFormData)
        }
    }

    useEffect(() => {
        receiver.on(`crud:${crudName}:insert:already_exists`, (response) => {
            toast({
                title: breadcrumbs[path]?.name,
                description: 'Já existe um documento com esse nome',
                position: 'top',
                status: 'error',
                isClosable: true,
            })
        })

        receiver.on(`crud:${crudName}:insert:ok`, (response) => {
            /* 
                customOnSubmit is a custom function whose can be modified 
                by childrens to make possible to insert different collections
                after the id of main collection is created
            */
            if (customOnSubmit) {
                customOnSubmit(response)
            }

            toast({
                title: breadcrumbs[path]?.name,
                description: `${breadcrumbs[path]?.name} inserido com sucesso`,
                position: 'top',
                status: 'success',
            })

            history.goBack()
        })

        return () => {
            receiver.off(`crud:${crudName}:insert:ok`)
            receiver.off(`crud:${crudName}:insert:already_exists`)
        }
    }, [onSubmit, customOnSubmit, customValidateFields])

    return (
        <Flex flex={1} p={4} gap={4} direction="column" overflow="hidden">
            <Breadcrumb page={path} />
            <Flex
                as="form"
                direction="column"
                backgroundColor="white"
                w="100%"
                maxW={['100%', '100%', '723px', '933px', '1128px']}
                marginX="auto"
                borderRadius="2xl"
                p={4}
                gap={4}
                onSubmit={handleSubmit(onSubmit)}
            >
                <VStack
                    spacing={4}
                    w="100%"
                    align="flex-start"
                    overflowY="scroll"
                    maxH={[
                        'calc(100vh - 270px)',
                        'calc(100vh - 270px)',
                        'calc(100vh - 206px)',
                    ]}
                >
                    <CrudInsertSwitch
                        crudName={crudName}
                        errors={errors}
                        register={register}
                        reset={reset}
                        setValue={setValue}
                        setCustomOnSubmit={setCustomOnSubmit}
                        setCustomValidateFields={setCustomValidateFields}
                    />
                </VStack>
                <Divider />
                <Footer isSubmitting={isSubmitting} />
            </Flex>
        </Flex>
    )
}
