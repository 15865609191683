import React from 'react'

import { Flex } from '@chakra-ui/react'

import { FieldLine } from '../../FieldLine'

export function InstallmentDue({ _id, date, timestamp }) {
    return (
        <>
            <FieldLine title="Id" value={`${_id}`} />
            <FieldLine title="Data" value={`${date}`} />
            <Flex
                border="2px"
                borderStyle="solid"
                borderColor={timestamp ? 'green.400' : 'red.400'}
                borderRadius="2xl"
                p={2}
            >
                <FieldLine
                    title={timestamp === null ? 'Pendente' : 'Quitado'}
                    value={`${
                        timestamp === null
                            ? ''
                            : new Intl.DateTimeFormat('pt-BR').format(timestamp)
                    }`}
                />
            </Flex>
        </>
    )
}
