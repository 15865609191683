import React, { forwardRef } from 'react'

import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Select as ChakraSelect,
} from '@chakra-ui/react'

import MultipleSelect from 'react-select'

function SelectBase({ error, label, name, options, multiple, ...rest }, ref) {
    return (
        <FormControl isInvalid={!!error} px={1}>
            {!!label && (
                <FormLabel fontWeight="bold" htmlFor={name}>
                    {label}
                </FormLabel>
            )}

            {multiple ? (
                <MultipleSelect
                    styles={{
                        control: () => ({
                            border: '1px solid #E2E8F0',
                            borderRadius: '6px',
                            display: 'flex',
                            flexDirection: 'row',
                        }),
                        indicatorSeparator: (provided) => ({}),
                        dropdownIndicator: (provided) => ({
                            ...provided,
                            color: 'black',
                        }),
                    }}
                    closeMenuOnSelect={false}
                    isMulti
                    menuPlacement="top"
                    menuPosition="fixed"
                    maxMenuHeight={160}
                    options={options?.map(
                        ({ name: optName, firstName, _id }) => ({
                            value: _id,
                            label: optName || firstName || _id,
                        })
                    )}
                    {...rest}
                />
            ) : (
                <ChakraSelect
                    id={name}
                    name={name}
                    ref={ref}
                    borderColor="blackAlpha.300"
                    {...rest}
                >
                    {options?.map(({ name: optName, firstName, _id }) => (
                        <option key={_id} value={_id}>
                            {optName || firstName || _id}
                        </option>
                    ))}
                </ChakraSelect>
            )}

            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
    )
}

export const Select = forwardRef(SelectBase)
