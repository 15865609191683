import { useDisclosure } from '@chakra-ui/react'
import React, {
    useCallback,
    useState,
    createContext,
    useContext,
    useMemo,
    useEffect,
} from 'react'

import { CartContext } from '../cart/CartContext'
import { SocketContext } from '../socket/SocketContext'

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
})

export const SaleContext = createContext({})

export function SaleContextProvider({ children }) {
    const { branches, plan } = useContext(SocketContext)
    const { cart, cartSet } = useContext(CartContext)

    const [branch, setBranch] = useState('')

    const [sale, saleSet] = useState({
        total: 0,
        totalFormatted: '',
        products: [],
    })

    const isSaleTest = useMemo(() => {
        return sale.products.some((product) => product.saleTest)
    }, [sale])

    const resetSale = useCallback(() => {
        saleSet({
            total: 0,
            totalFormatted: '',
            products: [],
        })
    }, [saleSet])

    const addGoodToSale = useCallback(
        (product) => {
            const newTotal = sale.total + product.price.retail

            const newProduct = JSON.parse(JSON.stringify(product))

            if (!product.saleTest) {
                newProduct.saleTest = plan !== 3
            }

            saleSet({
                total: newTotal,
                totalFormatted: currencyFormatter.format(newTotal),
                products: [...sale.products, newProduct],
            })
        },
        [sale, saleSet]
    )

    const removeGoodFromSale = useCallback(
        (product) => {
            if (sale.products.some((sl) => sl._saleId === product._saleId)) {
                const newTotal = sale.total - product.price.retail

                saleSet({
                    total: newTotal,
                    totalFormatted: currencyFormatter.format(newTotal),
                    products: [
                        ...sale.products.filter(
                            (prod) => prod._saleId !== product._saleId
                        ),
                    ],
                })
            }
        },
        [sale, saleSet]
    )

    const finishSale = useCallback(() => {
        cartSet([
            ...cart.filter(
                (cartProd) =>
                    !sale.products.includes(
                        (saleProd) => saleProd._saleId === cartProd._saleId
                    )
            ),
        ])
    }, [cart, sale, cartSet])

    const alterGoodPrice = (value, product) => {
        const newProducts = sale.products.map((prod) => {
            const newProd = { ...prod }
            if (newProd._saleId === product._saleId) {
                newProd.price.retail = value
                newProd.priceFormatted.retail = currencyFormatter.format(value)
            }
            return newProd
        })

        const newTotal = newProducts.reduce(
            (acc, cur) => acc + parseFloat(cur.price.retail),
            0
        )

        saleSet({
            products: newProducts,
            total: newTotal,
            totalFormatted: currencyFormatter.format(newTotal),
        })
    }

    const value = useMemo(
        () => ({
            sale,
            branches,
            branch,
            addGoodToSale,
            removeGoodFromSale,
            finishSale,
            resetSale,
            alterGoodPrice,
            setBranch,
            isSaleTest,
        }),
        [
            sale,
            branches,
            branch,
            addGoodToSale,
            removeGoodFromSale,
            finishSale,
            resetSale,
            alterGoodPrice,
            isSaleTest,
        ]
    )

    return <SaleContext.Provider value={value}>{children}</SaleContext.Provider>
}
