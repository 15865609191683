import React, { useContext, useEffect, useState } from 'react'

import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Input } from '../../../Form/Input'
import { Select } from '../../../Form/Select'

export function Payment({ errors, register }) {
    const { receiver } = useContext(SocketContext)

    const [sales, setSales] = useState([])

    useEffect(() => {
        receiver.on('crud:sale:find:ok', (response) => {
            setSales(response)
        })

        receiver.emit('crud:sale:find', { page: 0, size: Number.MAX_VALUE })

        return () => {
            receiver.off('crud:sale:find:ok')
        }
    }, [])

    return (
        <>
            <Input
                name="cash"
                error={errors.cash}
                label="Preço"
                type="number"
                step=".01"
                {...register('cash')}
            />
            <Input
                name="discount"
                error={errors.discount}
                label="Desconto"
                type="number"
                step="1"
                {...register('discount')}
            />
            <Input
                name="pt"
                error={errors.pt}
                label="pt"
                type="number"
                step="1"
                {...register('pt')}
            />
            <Input
                name="tax"
                error={errors.tax}
                label="Taxa"
                type="number"
                step="1"
                {...register('tax')}
            />
            <Input
                name="total"
                error={errors.total}
                label="Total"
                type="number"
                step="1"
                {...register('total')}
            />
            <Select
                name="_slId"
                error={errors._slId}
                label="Venda"
                options={sales}
                {...register('_slId')}
            />
        </>
    )
}
