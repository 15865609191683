import { createGlobalStyle } from 'styled-components'

import PoppinsRegular from '../assets/fonts/poppins/Poppins-Regular.ttf'
import PoppinsSemiBold from '../assets/fonts/poppins/Poppins-SemiBold.ttf'

export const Fonts = createGlobalStyle`
    @font-face {
        font-family: 'Poppins';
        src: local('Poppins-Regular'), local('Poppins-SemiBold'),
        url(${PoppinsRegular}) format('truetype'),
        url(${PoppinsSemiBold}) format('truetype');
        font-style: normal;
    }
`
