import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

export function Footer({ product }) {
    return (
        <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text fontSize={16} w="100%">
                Adicionado em: {product.addedIn}
            </Text>
            <Text fontSize={20} color="green.500" w="100%" textAlign="end">
                Preço: {product.priceFormatted.retail}
            </Text>
        </Flex>
    )
}
