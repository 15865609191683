import React from 'react'

import { Flex, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { Icon as IconSemantic } from 'semantic-ui-react'
import { SalesSintheticReportModal } from './Modal'

export function SalesSintheticReport({ branch }) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Flex
                as="button"
                onClick={onOpen}
                direction="column"
                justifyContent="space-between"
                background="linear-gradient(160deg, hsla(33, 100%, 53%, 1) 0%, hsla(339, 78%, 43%, 1) 100%)"
                height="150px"
                borderRadius="2xl"
                p={4}
                transition="0.5s filter"
                _hover={{
                    filter: 'brightness(0.85)',
                }}
            >
                <Text fontSize="2xl" color="white" align="start">
                    Relatório sintético
                </Text>
                <Icon
                    as={IconSemantic}
                    name="file"
                    size="big"
                    alignSelf="end"
                    color="white"
                />
            </Flex>
            <SalesSintheticReportModal
                isOpen={isOpen}
                onClose={onClose}
                branchId={branch}
            />
        </>
    )
}
