import React from 'react'

import { Input } from '../../../Form/Input'

export function Exchange({ errors, register }) {
    return (
        <Input
            name="value"
            error={errors.value}
            label="Valor"
            type="number"
            step="1"
            {...register('value')}
        />
    )
}
