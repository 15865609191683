import React from 'react'

import { Center, Text } from '@chakra-ui/react'

export function Empty() {
    return (
        <Center
            minH="200px"
            height="100%"
            flexDirection="column"
            backgroundColor="white"
            borderRadius="2xl"
            w="100%"
        >
            <Text fontSize="large">Nenhum produto encontrado</Text>
        </Center>
    )
}
