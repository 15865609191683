import React from 'react'

import { Link } from 'react-router-dom'

import { AddIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'

import { crudInsertPages } from '../../../../../pages/Crud/crudPages'

export function InsertButton({ crudName }) {
    if (crudInsertPages[crudName] !== undefined) {
        return (
            <Link
                to={{
                    pathname: 'insert',
                    state: {
                        crudName,
                        path: crudInsertPages[crudName],
                    },
                }}
            >
                <IconButton
                    icon={<AddIcon fontSize="2xl" />}
                    colorScheme="orange"
                />
            </Link>
        )
    }
    return (
        <IconButton
            icon={<AddIcon fontSize="2xl" />}
            colorScheme="orange"
            disabled
        />
    )
}
