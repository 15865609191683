import React, {
    useCallback,
    useEffect,
    useState,
    useMemo,
    useContext,
} from 'react'

import { Divider, Flex } from '@chakra-ui/react'

import { SocketContext } from '../../../contexts/socket/SocketContext'
import { Breadcrumb } from '../../../components/Breadcrumb'
import { Footer } from '../../../components/Crud/List/Footer'

import { Grid } from '../../../components/Crud/List/Grid'
import { SearchBarCrudList } from '../../../components/Crud/List/SearchBar'
import { searchByFields } from '../../../components/Crud/List/SearchBar/searchByFields'

export function CrudList({ crudName, path }) {
    const { receiver, user } = useContext(SocketContext)

    const [data, dataSet] = useState([])

    const [debounceInterval, debounceIntervalSet] = useState(null)

    const [searchValue, searchValueSet] = useState('')

    const [page, pageSet] = useState(1)
    const [size, sizeSet] = useState(6)
    const [pagesTotal, pagesTotalSet] = useState(1)

    const [checkboxes, checkboxesSet] = useState({})

    const [loading, loadingSet] = useState(true)

    const deleteActive = useMemo(
        () =>
            Object.entries(checkboxes).find(([k, cb]) => {
                return cb.checked
            }),
        [checkboxes]
    )

    const addSocketListeners = () => {
        receiver.on(`crud:${crudName}:find:ok`, (response) => {
            loadingSet(false)

            if (response._id) {
                pagesTotalSet(1)
                dataSet([response])

                return
            }

            if (user.role === '0') {
                const cbs =
                    response?.result.reduce((acc, cur) => {
                        acc[cur._id] = {
                            key: cur._id,
                            checked: false,
                        }
                        return acc
                    }, {}) || {}

                checkboxesSet(cbs)
            }

            pagesTotalSet(response?.pagesTotal || 1)

            dataSet(response?.result || [])
        })

        receiver.on(`crud:${crudName}:delete:ok`, (response) => {})
    }

    useEffect(() => {
        addSocketListeners()

        return () => {
            receiver.off(`crud:${crudName}:find:ok`)
            receiver.off(`crud:${crudName}:delete:ok`)
        }
    }, [])

    const buildQuery = () => {
        const query = []

        searchByFields[crudName].keys.forEach((key) => {
            if (key !== '_id')
                query.push({
                    [key]: {
                        $regex: `.*${searchValue}.*`,
                        $options: 'i',
                    },
                })
        })

        if (query.length > 0) {
            return {
                $or: query,
            }
        }

        return null
    }

    useEffect(() => {
        debounceIntervalSet((oldInterval) => {
            clearTimeout(oldInterval)
            loadingSet(true)

            return setTimeout(() => {
                if (
                    searchByFields[crudName].keys.some(
                        (key) => key === '_id'
                    ) &&
                    searchValue.length === 24
                ) {
                    receiver.emit(`crud:${crudName}:find`, {
                        _id: searchValue,
                    })
                } else {
                    receiver.emit(`crud:${crudName}:find`, {
                        page: 0,
                        size: parseInt(size, 10),
                        query: buildQuery(),
                    })
                }
            }, 500)
        })
    }, [size, searchValue])

    const nextPage = (newPage) => {
        if (
            searchByFields[crudName].keys.some((key) => key === '_id') &&
            searchValue.length === 24
        ) {
            receiver.emit(`crud:${crudName}:find`, {
                _id: searchValue,
            })
        } else {
            receiver.emit(`crud:${crudName}:find`, {
                page: newPage,
                size: parseInt(size, 10),
                query: buildQuery(),
            })
        }
    }

    return (
        <Flex height="max-content" p={4} gap={4} direction="column" w="100%">
            <Breadcrumb page={path} />
            <Flex
                backgroundColor="white"
                w="100%"
                maxW={['100%', '100%', '723px', '933px', '1128px']}
                marginX="auto"
                borderRadius="2xl"
                p={4}
                direction="column"
                gap={4}
            >
                <SearchBarCrudList
                    value={searchValue}
                    valueSet={searchValueSet}
                    size={size}
                    data={data}
                    crudName={crudName}
                />

                <Grid
                    data={data}
                    checkboxes={checkboxes}
                    checkboxesSet={checkboxesSet}
                    crudName={crudName}
                    loading={loading}
                    size={size}
                />

                <Divider />

                <Footer
                    crudName={crudName}
                    deleteActive={deleteActive}
                    checkboxes={checkboxes}
                    path={path}
                    data={data}
                    dataSet={dataSet}
                    page={page}
                    pageSet={pageSet}
                    pagesTotal={pagesTotal}
                    size={size}
                    sizeSet={sizeSet}
                    nextPage={nextPage}
                />
            </Flex>
        </Flex>
    )
}
