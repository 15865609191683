import React, { useContext } from 'react'
import { Box, Center, Divider, Flex, Text } from '@chakra-ui/react'
import { GoodVariationContext } from '../../../contexts/goodVariation/GoodVariationContext'

export function GoodVariationDescription() {
    const { good, loading } = useContext(GoodVariationContext)

    if (loading) {
        return (
            <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                direction="column"
                gap={4}
            />
        )
    }

    return (
        <Flex
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            w="100%"
        >
            <Flex
                background="white"
                direction="column"
                w="100%"
                borderRadius="2xl"
                p={4}
            >
                <Text
                    fontSize="3xl"
                    width="100%"
                    textAlign="start"
                    borderBottom="1px"
                    borderBottomColor="white"
                >
                    Descrição
                </Text>

                <Center h={4}>
                    <Divider />
                </Center>

                <Box bgColor="white" width="100%" borderRadius="2xl" p={4}>
                    <Text>{good.description}</Text>
                </Box>
            </Flex>
        </Flex>
    )
}
