import React, { useEffect, useContext, useState } from 'react'
import { SocketContext } from '../../../../contexts/socket/SocketContext'

import { FieldLine } from '../../FieldLine'

export function Installment({ _id, _pmtId }) {
    const { receiver } = useContext(SocketContext)

    const [payment, setPayment] = useState({
        total: 0,
    })

    const [customer, setCustomer] = useState({
        firstName: '',
        lastName: '',
    })

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
    })

    useEffect(() => {
        receiver.on('crud:customer:find:ok', (response) => {
            setCustomer({
                firstName: response?.firstName || '',
                lastName: response?.lastName || '',
            })
        })

        receiver.on('crud:user:find:ok', (response) => {
            setUser({
                firstName: response?.firstName || '',
                lastName: response?.lastName || '',
            })
        })

        receiver.on('crud:sale:find:ok', (response) => {
            receiver.emit('crud:customer:find', { _id: response?._custId })
            receiver.emit('crud:user:find', { _id: response?._usrId })
        })

        receiver.on('crud:payment:find:ok', (response) => {
            receiver.emit('crud:sale:find', { _id: response?._slId })
            setPayment({
                total: response?.total || 0,
            })
        })

        receiver.emit('crud:payment:find', { _id: _pmtId })

        return () => {
            receiver.off('crud:payment:find:ok')
            receiver.off('crud:sale:find:ok')
            receiver.off('crud:customer:find:ok')
        }
    }, [])

    return (
        <>
            <FieldLine title="Id" value={_id} />
            <FieldLine
                title="Cliente"
                value={`${customer.firstName} ${customer.lastName}`}
            />
            <FieldLine
                title="Vendedor"
                value={`${user.firstName} ${user.lastName}`}
            />
            <FieldLine title="Total" value={payment.total} />
        </>
    )
}
