import React from 'react'

import { Divider } from '@chakra-ui/react'
import { Input } from '../../../Form/Input'
import { CustomBehaviorAttribute } from '../CustomBehaviors/attribute'

export function Attribute({
    errors,
    register,
    data,
    setCustomOnSubmit,
    setCustomValidateFields,
}) {
    return (
        <>
            <Input
                name="name"
                error={errors.name}
                label="Nome"
                type="text"
                {...register('name')}
            />
            <Divider />
            <CustomBehaviorAttribute
                data={data}
                setCustomOnSubmit={setCustomOnSubmit}
                setCustomValidateFields={setCustomValidateFields}
            />
        </>
    )
}
