import React, { useEffect, useContext, useMemo } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { differenceInDays } from 'date-fns'
import { CustomerCard } from './CustomerCard'
import { SaleCard } from './SaleCard'
import { Select } from '../../Form/Select'

import { SaleContext } from '../../../contexts/sale/SaleContext'

export function SaleDetails({ customer }) {
    const { branch, setBranch, branches, isSaleTest } = useContext(SaleContext)

    const validBranches = useMemo(() => {
        const now = new Date()

        if (isSaleTest) return branches

        return branches.filter(
            (bran) =>
                differenceInDays(
                    new Date(bran.nfeio.certificateValidUntil).getTime(),
                    now.getTime()
                ) > 0
        )
    }, [branches])

    useEffect(() => {
        setBranch(branches[0]?._id)
    }, [])

    return (
        <Flex
            w="100%"
            maxW={['100%', '100%', '723px', '933px', '1128px']}
            marginX="auto"
            backgroundColor="whiteAlpha.900"
            p={4}
            borderRadius="2xl"
            direction="column"
            gap={4}
        >
            <Text fontSize="2xl" w="100%" color="orange.500">
                Finalizar Venda
            </Text>
            <Divider />
            <Text w="100%" fontSize="medium">
                Favor, confirme os dados e selecione uma filial
            </Text>

            <Flex
                width="100%"
                direction={['column', 'column', 'row']}
                borderRadius="2xl"
                justify="space-between"
                gap={4}
            >
                <CustomerCard customer={customer} />
                <Divider orientation="vertical" />
                <SaleCard />
            </Flex>

            <Select
                id="branch"
                name="branch"
                label="Filial"
                options={validBranches}
                value={branch}
                onChange={(e) => {
                    setBranch(e.target.value)
                }}
            />
        </Flex>
    )
}
