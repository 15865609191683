import React from 'react'

import { FieldLine } from '../../FieldLine'

export function Good({ name, description }) {
    return (
        <>
            <FieldLine title="Nome" value={`${name}`} />
            <FieldLine title="Descrição" value={`${description}`} />
        </>
    )
}
