import React, { useCallback, useContext, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import {
    Button,
    Flex,
    IconButton,
    Image,
    SimpleGrid,
    Text,
    useToast,
} from '@chakra-ui/react'

import { FaPlus } from 'react-icons/fa'

import { useDropzone } from 'react-dropzone'

import { apiRest } from '../../../../../services/api'

export function ImageUpload({ setCustomOnSubmit }) {
    const toast = useToast()
    const [files, setFiles] = useState([])

    const dispatchAlert = (title_, description_, status_) => {
        toast({
            title: title_,
            description: description_,
            position: 'top',
            status: status_,
            isClosable: true,
        })
    }

    const { open, getInputProps, getRootProps } = useDropzone({
        accept: '.webp,.jpeg,.png,.jpg',
        onDropRejected: (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                if (file.file.size > 10000000)
                    dispatchAlert(
                        `Erro ao inserir a imagem ${file.file.name}`,
                        `Tamanho limite de arquivo excedido`,
                        'warning'
                    )
                else
                    dispatchAlert(
                        `Erro ao inserir a imagem ${file.file.name}`,
                        `Selecione um arquivo válido`,
                        'warning'
                    )
            })
        },
        onDropAccepted: async (acceptedFiles) => {
            const newFiles = await acceptedFiles.reduce(async (acc, file) => {
                const newAcc = await acc

                newAcc.push({
                    file_: file,
                    preview: URL.createObjectURL(file),
                    id: uuidv4(),
                    originalName: `${uuidv4()}-${file.name}`,
                })
                return newAcc
            }, Promise.resolve([]))

            setFiles([...files, ...newFiles])
        },
        maxSize: 10000000,
    })

    const removeImage = (id) => {
        setFiles(files.filter((file) => file.id !== id))
        dispatchAlert(
            `Remoção de Imagem`,
            `Imagem removida com sucesso`,
            'success'
        )
    }

    const customSubmit = async (formData) => {
        const requests = files.map((currentFile) => {
            const idsObject = { _coId: formData._coId, _gdvId: formData._id }
            const data = new FormData()

            data.append('file', currentFile.file_, currentFile.originalName)
            Object.keys(idsObject).forEach((key) =>
                data.append(key, idsObject[key])
            )

            return apiRest.post('/upload', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        })

        try {
            await Promise.all(requests)
            return true
        } catch (error) {
            if (error.message === 'Network Error') {
                toast({
                    title: `Erro ao inserir a(s) imagem(ns)`,
                    description: `Servidor de upload de imagem fora do ar`,
                    position: 'top',
                    status: 'warning',
                    isClosable: true,
                })
            }
        }

        return false
    }
    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })
    }, [files])

    return (
        <>
            <Text fontSize="2xl">Fotos</Text>
            <Text fontSize="1xl">
                Formatos suportados: PNG, JPG, JPEG e WEBP
            </Text>
            <Flex
                w="100%"
                border="1px solid"
                borderStyle="dashed"
                borderColor="blackAlpha.400"
                my={4}
                borderRadius="2xl"
                minH="300px"
            >
                <input {...getInputProps()} />
                {files.length > 0 ? (
                    <SimpleGrid
                        minChildWidth="280px"
                        w="100%"
                        p={4}
                        direction="row"
                        gap={4}
                        justifyContent="start"
                        alignItems="center"
                        overflowY="scroll"
                    >
                        {files.map((file) => (
                            <Flex
                                flex={1}
                                h="100%"
                                direction="column"
                                gap={4}
                                key={file.id}
                            >
                                <Image
                                    flex={1}
                                    maxH="220px"
                                    width="auto"
                                    src={file.preview}
                                    borderRadius="2xl"
                                    objectFit="cover"
                                />
                                <Button
                                    colorScheme="blackAlpha"
                                    onClick={() => removeImage(file.id)}
                                >
                                    Remover
                                </Button>
                            </Flex>
                        ))}
                        <Flex
                            flex={1}
                            minH="220px"
                            h="100%"
                            justifyContent="center"
                            direction="column"
                            alignItems="center"
                            gap={4}
                        >
                            <IconButton
                                size="lg"
                                maxW="40px"
                                icon={<FaPlus />}
                                colorScheme="orange"
                                onClick={open}
                            />
                        </Flex>
                    </SimpleGrid>
                ) : (
                    <Flex
                        direction="column"
                        justify="center"
                        alignItems="center"
                        w="100%"
                        gap={2}
                        p={4}
                    >
                        <IconButton
                            size="lg"
                            icon={<FaPlus />}
                            colorScheme="orange"
                            onClick={open}
                        />
                        <Text textAlign="center">
                            Pressione para selecionar as fotos
                        </Text>
                    </Flex>
                )}
            </Flex>
        </>
    )
}
