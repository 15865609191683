import React, { useContext } from 'react'
import { Checkbox, Stack, Text } from '@chakra-ui/react'
import { FilterContext } from '../../../../../contexts/filter/FilterContext'

export function Categories() {
    const { filtersOptions, filters, filtersSet } = useContext(FilterContext)

    const onChange = (e, id, option) => {
        if (e.target.checked) {
            const newFilters = { ...filters }
            newFilters[id] = option
            filtersSet(newFilters)
        } else {
            const newFilters = { ...filters }
            delete newFilters[id]
            filtersSet(newFilters)
        }
    }

    return (
        <Stack>
            {Object.entries(filtersOptions).map(([id, category]) => (
                <Checkbox
                    key={id}
                    isChecked={!!filters[id]}
                    onChange={(e) => onChange(e, id, category)}
                    colorScheme="orange"
                >
                    <Text fontSize="medium" color="blackAlpha.700">
                        {category?.name}
                    </Text>
                </Checkbox>
            ))}
        </Stack>
    )
}
