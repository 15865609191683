import axios from 'axios'

const devMode = process.env.REACT_APP_DEV_MODE === 'true'

const address = devMode
    ? process.env.REACT_APP_DEV_SERVER_ADDRESS
    : process.env.REACT_APP_SERVER_ADDRESS

const port = devMode
    ? process.env.REACT_APP_DEV_SERVER_PORT
    : process.env.REACT_APP_SERVER_PORT

const reactPort = process.env.REACT_APP_REST_PORT

export const api = axios.create({
    baseURL: `https://${address}:${port}`,
})

export const apiRest = axios.create({
    baseURL: `https://${process.env.REACT_APP_SERVER_ADDRESS}:${process.env.REACT_APP_REST_PORT}`,
})

export const viacep = axios.create({
    baseURL: 'https://viacep.com.br/ws',
})
