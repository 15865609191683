import React, { useContext } from 'react'
import { IconButton, useToast } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import { SocketContext } from '../../../../../contexts/socket/SocketContext'
import { breadcrumbs } from '../../../../Breadcrumb/breadcrumbs'

export function DeleteButton({
    path,
    crudName,
    deleteActive,
    checkboxes,
    data,
    dataSet,
}) {
    const { receiver } = useContext(SocketContext)
    const toast = useToast()

    const handleDelete = () => {
        const toDelete = Object.entries(checkboxes).reduce((acc, [k, cur]) => {
            if (cur.checked) {
                return [...acc, { _id: cur.key }]
            }
            return acc
        }, [])

        dataSet(data.filter((d) => !toDelete.some((del) => del._id === d._id)))

        // massive delete is not implemented yet
        toDelete.forEach((del) => {
            receiver.emit(`crud:${crudName}:delete`, del)
        })

        toast({
            title: breadcrumbs[path]?.name,
            description: `${breadcrumbs[path]?.name} x${toDelete.length} removido com sucesso`,
            position: 'top',
            status: 'success',
            isClosable: true,
        })
    }

    return (
        <IconButton
            isActive={deleteActive}
            icon={<DeleteIcon fontSize="medium" />}
            colorScheme="blackAlpha"
            onClick={handleDelete}
        />
    )
}
