import { Divider, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { SocketContext } from '../../../../contexts/socket/SocketContext'
import { Select } from '../../../Form/Select'

export function CustomBehaviorGood({ setCustomOnSubmit }) {
    const { receiver } = useContext(SocketContext)

    const [selectedAttrKeys, setSelectedAttrKeys] = useState([])
    const [attributeKeys, setAttributeKeys] = useState([])

    useEffect(() => {
        receiver.on('crud:attribute_key:find:ok', (response) => {
            setAttributeKeys(response.result)
        })

        receiver.emit('crud:attribute_key:find', {
            page: 0,
            size: Number.MAX_VALUE,
        })

        return () => {
            receiver.off('crud:attribute_key:find:ok')
        }
    }, [])

    const updateGoodToInsertKeys = (formData) => {
        receiver.emit('crud:good:update', {
            ...formData,
            _akIds: selectedAttrKeys.map((attrKey) => attrKey.value),
        })
    }

    const customSubmit = (formData) => {
        updateGoodToInsertKeys(formData)

        return false
    }

    useEffect(() => {
        setCustomOnSubmit(() => {
            return customSubmit
        })
    }, [selectedAttrKeys])

    const handleChange = (selectedOption) => {
        setSelectedAttrKeys(selectedOption)
    }

    return (
        <Select
            multiple
            label="Atributos"
            options={attributeKeys}
            onChange={handleChange}
            value={selectedAttrKeys}
        />
    )
}
