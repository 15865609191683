import React, { useState, useContext } from 'react'

import {
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react'

import { HamburgerIcon } from '@chakra-ui/icons'
import { MenuCard } from '../MenuCard'

import { SocketContext } from '../../../contexts/socket/SocketContext'

const workerRoutes = [
    {
        name: 'Produtos',
        route: 'search',
        color: `linear-gradient(290deg, rgb(255, 140, 0), #d35434)`,
    },
]

const adminRoutes = [
    {
        name: 'Área de Trabalho',
        route: 'workspace',
        color: `linear-gradient(290deg, rgb(255, 140, 0), #f08930)`,
    },
    {
        name: 'Produtos',
        route: 'search',
        color: `linear-gradient(290deg, rgb(255, 140, 0), #d35434)`,
    },
]

export function PrincipalMenus() {
    const { bearer } = useContext(SocketContext)
    const [open, openSet] = useState(false)

    return (
        <Menu isOpen={open} onClose={() => openSet(false)}>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                colorScheme="orange"
                fontSize="2xl"
                color="white"
                onClick={() => openSet(!open)}
            />

            <MenuList
                display="flex"
                width="100%"
                flexDirection="column"
                gap={2}
                p={2}
                zIndex={1000}
            >
                {(bearer?.user.role === '3' ? workerRoutes : adminRoutes).map(
                    (item) => (
                        <MenuItem p={0} key={item.name}>
                            <MenuCard
                                name={item.name}
                                route={item.route}
                                color={item.color}
                            />
                        </MenuItem>
                    )
                )}
            </MenuList>
        </Menu>
    )
}
