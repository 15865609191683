export const crudViewPages = {
    user: 'workspaceViewUser',
    customer: 'workspaceViewCustomer',
    brand: 'workspaceViewBrand',
    category: 'workspaceViewCategory',
    attribute_key: 'workspaceViewAttribute',
    good: 'workspaceViewGood',
    good_variation: 'workspaceViewGoodVariation',
    payment: 'workspaceViewPayment',
    installment: 'workspaceViewInstallment',
    installment_due: 'workspaceViewInstallmentDue',
    sale: 'workspaceViewSale',
    expense_type: 'workspaceViewExpenseType',
    expense: 'workspaceViewExpense',
    exchange_reason: 'workspaceViewTradeReason',
    exchange: 'workspaceViewTrade',
    refund_reason: 'workspaceViewDevolutionReason',
    refund: 'workspaceViewDevolution',
    cancellation_reason: 'workspaceViewSalesCancelReason',
    cancellation: 'workspaceViewSalesCancel',
    supplier: 'workspaceViewSupplier',
}

export const crudEditPages = {
    user: 'workspaceEditUser',
    customer: 'workspaceEditCustomer',
    brand: 'workspaceEditBrand',
    category: 'workspaceEditCategory',
    attribute_key: 'workspaceEditAttribute',
    good: 'workspaceEditGood',
    good_variation: 'workspaceEditGoodVariation',
    expense_type: 'workspaceEditExpenseType',
    exchange_reason: 'workspaceEditTradeReason',
    refund_reason: 'workspaceEditDevolutionReason',
    cancellation_reason: 'workspaceEditSalesCancelReason',
    supplier: 'workspaceEditSupplier',
}

export const crudInsertPages = {
    user: 'workspaceEditUser',
    customer: 'workspaceEditCustomer',
    brand: 'workspaceEditBrand',
    category: 'workspaceEditCategory',
    attribute_key: 'workspaceEditAttribute',
    good: 'workspaceEditGood',
    good_variation: 'workspaceInsertGoodVariation',
    expense_type: 'workspaceEditExpenseType',
    exchange_reason: 'workspaceEditTradeReason',
    refund_reason: 'workspaceEditDevolutionReason',
    cancellation_reason: 'workspaceEditSalesCancelReason',
    supplier: 'workspaceEditSupplier',
}
