import React, { useEffect, useState, useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { BellIcon, ChevronDownIcon } from '@chakra-ui/icons'
import {
    Avatar,
    Button,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
} from '@chakra-ui/react'

import { SocketContext } from '../../../contexts/socket/SocketContext'

import noImage from '../../../assets/user.svg'

export function RightMenus() {
    const navigate = useHistory()

    const [open, openSet] = useState(false)

    const { receiver, user, logout } = useContext(SocketContext)

    const [userImage, userImageSet] = useState(undefined)

    const addSocketListeners = () => {
        receiver.on('crud:profile_picture:find:ok', (response) => {
            if (response) {
                receiver.emit('profile_picture:get:enabled_picture', response)
            } else {
                userImageSet(noImage)
            }
        })

        receiver.on('profile_picture:get:enabled_picture:ok', (response) => {
            userImageSet(
                `data:image/${response.extension};base64,${response.base64}`
            )
        })
    }

    const initialRequests = () => {
        try {
            receiver.emit('crud:profile_picture:find', {
                _usrId: user._id,
                enabled: true,
            })
        } catch (e) {
            //
        }
    }

    useEffect(() => {
        if (receiver && user) {
            addSocketListeners()
            initialRequests()
        }

        return () => {
            receiver?.off('crud:profile_picture:find:ok')
            receiver?.off('profile_picture:get:enabled_picture:ok')
        }
    }, [receiver, user])

    return (
        <HStack>
            <IconButton
                aria-label="notifications"
                icon={<BellIcon width="30px" height="30px" color="#fff" />}
                variant="basic"
                disabled
            />

            <Menu isOpen={open} onClose={() => openSet(false)}>
                <MenuButton
                    as={Button}
                    aria-label="Options"
                    leftIcon={
                        <Avatar
                            aria-hidden="false"
                            backgroundColor="transparent"
                            src={userImage}
                            name="userImage"
                            size="sm"
                        />
                    }
                    rightIcon={<ChevronDownIcon />}
                    variant="basic"
                    color="white"
                    onClick={() => openSet(!open)}
                >
                    <Text fontSize="small" colorScheme="white">
                        {`Olá, ${user.firstName}`}
                    </Text>
                </MenuButton>

                <MenuList
                    display="flex"
                    width="100%"
                    flexDirection="column"
                    gap={2}
                    p={2}
                    zIndex={1000}
                >
                    {[
                        {
                            name: 'Meu Perfil',
                            onClick: () => navigate.replace('/profile'),
                            disabled: true,
                        },
                        {
                            name: 'Configurações',
                            onClick: () => navigate.replace('/settings'),
                            disabled: true,
                        },
                        {
                            name: 'Sair',
                            onClick: () => {
                                logout()
                            },
                            disabled: false,
                        },
                    ].map((item) => (
                        <MenuItem
                            borderRadius="2xl"
                            key={item.name}
                            onClick={item.onClick}
                            isDisabled={item.disabled}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </HStack>
    )
}
