import React, { useMemo } from 'react'

import { collectionSwitch } from './Fields/collectionSwitch'

export function CrudInsertSwitch({
    crudName,
    errors,
    register,
    reset,
    setValue,
    setCustomOnSubmit,
    setCustomValidateFields,
}) {
    const Component = useMemo(() => collectionSwitch[crudName], [crudName])

    return (
        <Component
            errors={errors}
            register={register}
            setValue={setValue}
            setCustomOnSubmit={setCustomOnSubmit}
            setCustomValidateFields={setCustomValidateFields}
            reset={reset}
        />
    )
}
